import { Avatar, Menu, MenuIcon, Pane, Popover, Position } from "evergreen-ui";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../lib/button/Button";
import { White } from "../utils/Consts";

export default function ProfileButton(props) {
	return (
		<Popover
			position={Position.BOTTOM_RIGHT}
			content={({ close }) => (
				<Menu>
					<Menu.Group>
						<Menu.Item onSelect={close} is={Link} to="/profile">
							Profile
						</Menu.Item>
						<Menu.Item onSelect={close} is={Link} to="/billing">
							Billing
						</Menu.Item>
						<Menu.Item onSelect={close} is={Link} to="/settings">
							Settings
						</Menu.Item>

						<Menu.Divider />
						<Menu.Item onSelect={close} is={Link} to="/logout">
							Logout
						</Menu.Item>
					</Menu.Group>
				</Menu>
			)}
		>
			<Button
				style={{
					padding: "0px",
					height: "54px",
					border: "1px solid #000000",
					borderRadius: "5px",
					backgroundColor: { White },
					backgroundImage: "none",
				}}
			>
				<Pane
					paddingTop="10px"
					paddingRight="14px"
					paddingBottom="10px"
					paddingLeft="14px"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Avatar isSolid={false} color="purple" name={props.name} />
				</Pane>
				<Pane
					paddingRight="15px"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<MenuIcon />
				</Pane>
			</Button>
		</Popover>
	);
}
