import { defaultTheme } from "evergreen-ui";
import { merge } from "lodash";

const Theme = merge(defaultTheme, {
	typography: {
		fontFamilies: {
			display: '"Jost", sans-serif',
			ui: '"Jost", sans-serif',
			mono: '"Jost", sans-serif',
		},
	},
});

export default Theme;
