import React, { Component } from "react";
import Context from "../utils/Context";
import { PaneProps } from "../utils/Consts";
import { majorScale, Pane } from "evergreen-ui";
import PageTitleBar from "./PageTitleBar";
import Card from "../lib/card/Card";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";

export default class Profile extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) =>
					!!context.user && (
						<Pane {...PaneProps} flexWrap="wrap" width="100%">
							<Breadcrumbs crumbs={[{ text: "Profile", link: "/profile" }]} />
							<PageTitleBar title="Profile" />
							<Pane
								{...PaneProps}
								flexDirection="row"
								flexWrap="wrap"
								padding={0}
							>
								<Pane
									maxWidth={majorScale(40)}
									marginRight="20px"
									marginBottom="20px"
								>
									<Card
										title="Identity"
										body="View and edit your identify information, including name, address, and phone number"
										link="/profile/identity"
										primary={+true}
										button="VIEW"
										small={+true}
									/>
								</Pane>
								<Pane
									maxWidth={majorScale(40)}
									marginRight="20px"
									marginBottom="20px"
								>
									<Card
										title="Professional"
										body="View and edit your professional information, including position, industry, and years experience"
										link="/profile/professional"
										primary={+true}
										button="VIEW"
										small={+true}
									/>
								</Pane>
								<Pane
									maxWidth={majorScale(40)}
									marginRight="20px"
									marginBottom="20px"
								>
									<Card
										title="Interests"
										body="View and edit your interests, including training, coaching, and mentoring opportunities"
										link="/profile/interests"
										primary={+true}
										button="VIEW"
										small={+true}
									/>
								</Pane>
								<Pane
									maxWidth={majorScale(40)}
									marginRight="20px"
									marginBottom="20px"
								>
									<Card
										title="Career"
										body="View and edit career opportunity interests, including career and work preferences"
										link="/profile/career"
										primary={+true}
										button="VIEW"
										small={+true}
									/>
								</Pane>
							</Pane>
						</Pane>
					)
				}
			</Context.Consumer>
		);
	}
}
