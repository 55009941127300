import React, { Component } from "react";
import Context from "../utils/Context";
import { Pane, Heading, Button, Icon, majorScale } from "evergreen-ui";
import { NavLink } from "react-router-dom";

export default class PageTitleBar extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane
						display="flex"
						justifyContent="space-between"
						padding="10px"
						marginBottom="10px"
						paddingLeft={0}
					>
						<Heading size={600}>{this.props.title}</Heading>
						<Pane>
							{this.props.buttons?.map((buttonObj, i) => {
								switch (buttonObj.type) {
									case "nav":
										return (
											<NavLink
												key={`page-title-bar_button-${i}`}
												to={buttonObj.to}
											>
												<Button onClick={buttonObj.action}>
													<Icon size={majorScale(2)} icon={buttonObj.icon} />
												</Button>
											</NavLink>
										);
									// break;

									default:
										return (
											<Button
												key={`page-title-bar_button-${i}`}
												onClick={buttonObj.action}
											>
												<Icon size={majorScale(2)} icon={buttonObj.icon} />
											</Button>
										);
									// break;
								}
							})}
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
