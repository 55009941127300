import { Pane, Text } from "evergreen-ui";
import React from "react";
import { PaneProps, PrimaryContrast, White } from "../utils/Consts";
import Logo from "./Logo";

export default function Footer() {
	return (
		<Pane
			{...PaneProps}
			paddingLeft="5%"
			paddingRight="5%"
			background={PrimaryContrast}
			flexDirection="row"
			borderTopLeftRadius={0}
			borderTopRightRadius={0}
			borderBottomRightRadius={0}
			className="footer"
			height="93px"
			alignItems="center"
			justifyContent="space-between"
		>
			{/* <Pane flex={1} alignItems="center" display="flex"></Pane> */}
			<Pane>
				<Logo brand="white" />
			</Pane>
			<Pane>
				<Text color={White}>© 2021 LEADERSED</Text>
			</Pane>
			{/* <Pane>
				<Text color={White}>TERMS & CONDITIONS</Text>
			</Pane> */}
			{/* <Pane>
				<Text color={White}>FOOTER ITEM</Text>
			</Pane> */}
			{/* <Pane>
				<Text color={White}>FOOTER ITEM</Text>
			</Pane> */}
			{/* <Pane>
				<Text color={White}>CONTACT US</Text>
			</Pane> */}
		</Pane>
	);
}
