import {
	Card,
	Heading,
	majorScale,
	Pane,
	Paragraph,
	Table,
} from "evergreen-ui";
import React, { Component } from "react";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Divider from "../lib/divider/Divider";
import {
	CardProps,
	PaletteGreenOpaque,
	PaletteGoldOpaque,
	PaletteOrangeOpaque,
	PaneProps,
	PrimaryLight,
	PaletteGreen,
	PaletteGold,
	PaletteOrange,
} from "../utils/Consts";
import Context from "../utils/Context";
import PageTitleBar from "./PageTitleBar";

export default class Level extends Component {
	render() {
		const { level, url } = this.props;
		return (
			<Context.Consumer>
				{(context) => (
					<Pane {...PaneProps} display="flex" width="100%">
						<Pane>
							<Breadcrumbs
								crumbs={[
									{ text: "Premium", link: "/premium" },
									{ text: level, link: `/premium/${url}` },
								]}
							/>
							<PageTitleBar title={`${level} Level of Leadership`} />
							<Paragraph
								size={500}
								fontWeight={400}
								marginBottom={majorScale(2)}
							>
								Natural Talents Needed to Enhance Your Success If You Aspire For{" "}
								{level} Level Leadership
							</Paragraph>
						</Pane>
						<Pane>
							<Heading
								// letterSpacing="0.03em"
								fontFamily='"Jost",sans-serif'
								// textAlign="center"
								size={700}
								fontWeight="500"
								marginBottom={majorScale(3)}
								marginTop={majorScale(7)}
								textTransform="uppercase"
								textAlign="center"
							>
								Your Results
							</Heading>
							<Pane
								{...PaneProps}
								flexWrap="wrap"
								padding={0}
								width="100%"
								marginBottom={majorScale(7)}
								// height="100%"
								// maxHeight="600px"
								// maxWidth="1200px"
							>
								<Card
									{...CardProps}
									className="level-results-card"
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									alignItems="flex-end"
									maxWidth="none"
									width="100%"
								>
									<Table width="inherit">
										<Table.Head>
											<Table.TextCell>Natural Talent</Table.TextCell>
											<Table.TextCell
												flexBasis={125}
												flexShrink={0}
												flexGrow={0}
												textAlign="center"
											>
												<Paragraph
													size={500}
													color={PaletteGreen}
													textTransform="uppercase"
												>
													Excel
												</Paragraph>
											</Table.TextCell>
											<Table.TextCell
												flexBasis={125}
												flexShrink={0}
												flexGrow={0}
												textAlign="center"
											>
												<Paragraph
													size={500}
													color={PaletteGold}
													textTransform="uppercase"
												>
													Solid
												</Paragraph>
											</Table.TextCell>
											<Table.TextCell
												flexBasis={125}
												flexShrink={0}
												flexGrow={0}
												textAlign="center"
											>
												<Paragraph
													size={500}
													color={PaletteOrange}
													textTransform="uppercase"
												>
													Challenge
												</Paragraph>
											</Table.TextCell>
										</Table.Head>
										<Table.Body>
											{context?.report?.levelofleadership
												?.filter((item) => item?.level === level)
												.map((item, i) => (
													<Table.Row height={125} key={`${item?.name}-${i}`}>
														<Table.TextCell>
															<Heading size={600} paddingBottom={majorScale(1)}>
																{item?.name}
															</Heading>
															<Pane>
																<Paragraph whiteSpace="pre-line">
																	{item?.definition}
																</Paragraph>
															</Pane>
														</Table.TextCell>
														<Table.TextCell
															flexBasis={125}
															flexShrink={0}
															flexGrow={0}
															background={
																item.value >= 60
																	? PaletteGreenOpaque
																	: PrimaryLight
															}
														/>
														<Table.TextCell
															flexBasis={125}
															flexShrink={0}
															flexGrow={0}
															background={
																item.value >= 40 && item.value < 60
																	? PaletteGoldOpaque
																	: PrimaryLight
															}
														/>
														<Table.TextCell
															flexBasis={125}
															flexShrink={0}
															flexGrow={0}
															background={
																item.value < 40
																	? PaletteOrangeOpaque
																	: PrimaryLight
															}
														/>
													</Table.Row>
												))}
										</Table.Body>
									</Table>
								</Card>
							</Pane>

							{context?.report?.levelofleadership
								?.filter((item) => item.level === level)
								.filter((item) => item.value >= 60).length > 0 && (
								<React.Fragment>
									<Divider />
									<Pane marginTop={majorScale(5)} marginBottom={majorScale(5)}>
										<Pane
											marginTop={majorScale(5)}
											marginBottom={majorScale(5)}
										>
											<Heading
												// letterSpacing="0.03em"
												fontFamily='"Jost",sans-serif'
												// textAlign="center"
												size={700}
												fontWeight="500"
												marginBottom={majorScale(5)}
											>
												Your Leadership Assets at the {level} Level
											</Heading>
											<Paragraph
												size={500}
												fontWeight={400}
												marginBottom={majorScale(2)}
											>
												These leadership skills are well balanced and reflect a
												solid leadership capability at this level.
											</Paragraph>
										</Pane>
										<Pane>
											{context?.report?.levelofleadership
												?.filter((item) => item.level === level)
												.filter((item) => item.value >= 60)
												.map((item, i) => (
													<Pane key={`${item.name}-detail-${i}`}>
														<Heading
															// letterSpacing="0.03em"
															fontFamily='"Jost",sans-serif'
															textAlign="center"
															textTransform="uppercase"
															size={600}
															fontWeight="500"
															marginBottom={majorScale(5)}
															marginTop={majorScale(5)}
														>
															{item?.group}
														</Heading>
														<Card
															{...CardProps}
															display="flex"
															flexDirection="column"
															justifyContent="center"
															alignItems="center"
															maxWidth="none"
															width="100%"
														>
															<Heading marginBottom={majorScale(3)} size={500}>
																{item?.name}
															</Heading>
															<Paragraph>{item?.paragraph}</Paragraph>
														</Card>
													</Pane>
												))}
										</Pane>
									</Pane>
								</React.Fragment>
							)}

							{context?.report?.levelofleadership
								?.filter((item) => item.level === level)
								.filter((item) => item.value < 40).length > 0 && (
								<React.Fragment>
									<Divider />
									<Pane>
										<Pane
											marginTop={majorScale(5)}
											marginBottom={majorScale(5)}
										>
											<Heading
												// letterSpacing="0.03em"
												fontFamily='"Jost",sans-serif'
												// textAlign="center"
												size={700}
												fontWeight="500"
												marginBottom={majorScale(5)}
											>
												Your Leadership Challenges at the {level} Level
											</Heading>
											<Paragraph
												size={500}
												fontWeight={400}
												marginBottom={majorScale(2)}
											>
												These leadership skills will reflect a challenge at this
												level.
											</Paragraph>
										</Pane>
										<Pane>
											{context?.report?.levelofleadership
												?.filter((item) => item.level === level)
												.filter((item) => item.value < 40)
												.map((item, i) => (
													<Pane key={`${item.name}-detail-${i}`}>
														<Heading
															// letterSpacing="0.03em"
															fontFamily='"Jost",sans-serif'
															textAlign="center"
															textTransform="uppercase"
															size={600}
															fontWeight="500"
															marginBottom={majorScale(5)}
															marginTop={majorScale(5)}
														>
															{item?.group}
														</Heading>
														<Card
															{...CardProps}
															display="flex"
															flexDirection="column"
															justifyContent="center"
															alignItems="center"
															maxWidth="none"
															width="100%"
														>
															<Heading marginBottom={majorScale(3)} size={500}>
																{item?.name}
															</Heading>
															<Paragraph>{item?.paragraph}</Paragraph>
														</Card>
													</Pane>
												))}
										</Pane>
									</Pane>
								</React.Fragment>
							)}
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
