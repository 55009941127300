import React, { Component } from "react";
import Context from "../utils/Context";
import ProfileIdentity from "./ProfileIdentity";

export default class ProfileIdentityContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => <ProfileIdentity context={context} {...this.props} />}
			</Context.Consumer>
		);
	}
}
