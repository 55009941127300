import React, { Component } from "react";
import Context from "../utils/Context";
import { Pane, Spinner } from "evergreen-ui";
import Leadership from "./Leadership";
import UpgradeCardContainer from "./UpgradeCardContainer";
import BeginAssessmentCard from "./BeginAssessmentCard";

export default class LeadershipContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane display="flex" width="100%">
						{!!context?.reportLoading && (
							<Pane
								display="flex"
								width="100%"
								justifyContent="center"
								alignItems="center"
								height="100%"
							>
								<Spinner />
							</Pane>
						)}

						{!context?.report && <BeginAssessmentCard />}

						{context?.report?.level === 0 && (
							<Pane
								display="flex"
								justifyContent="center"
								alignItems="center"
								width="100%"
							>
								<UpgradeCardContainer />
							</Pane>
						)}

						{context?.report?.level > 0 && (
							<Leadership context={context} {...this.props} />
						)}
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
