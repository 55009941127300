import React, { Component } from "react";
import { Label, Pane, SelectMenu, Text } from "evergreen-ui";
import { PrimaryContrast, SecondaryDark } from "../../utils/Consts";
import Button from "../button/Button";
import "./singleSelect.css";

const initState = { value: "", edit: false };

export default class SingleSelect extends Component {
	state = { ...initState };
	componentDidMount() {
		this.setState({ edit: this.props?.edit });
	}

	clickHandler = async (e) => {
		try {
			await this.props?.submitHandler(e.target.name, this.state.value);
			this.setState({ ...initState });
		} catch (error) {
			return error;
		}
	};

	render() {
		return (
			<Pane maxWidth="544px" display="flex" width="100%" flexDirection="column">
				<Pane
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Label marginBottom="8px" color={SecondaryDark}>
						{this.props?.label}
					</Label>
					{!this.props?.uneditable && (
						<Text
							cursor="pointer"
							color={PrimaryContrast}
							onClick={() => this.setState({ edit: !this.state.edit })}
							fontWeight="600"
						>
							{this.state.edit ? "Cancel" : "Edit"}
						</Text>
					)}
				</Pane>
				<Pane>
					<Text>
						{this.state.edit ? this.props?.editMessage : this.props?.value}
					</Text>
				</Pane>
				{this.state.edit && (
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<SelectMenu
							className="single-select"
							title={this.props?.title}
							options={this.props?.options?.map((label) => ({
								label,
								value: label,
							}))}
							selected={this.state?.value}
							onSelect={(selected) => this.setState({ value: selected?.value })}
							padding="11px"
							border="none"
							outline="transparent"
							boxShadow="none"
							fontSize="14px"
							hasTitle={this.props?.hasTitle}
						>
							<Button>{this.state?.value || this.props?.title}</Button>
						</SelectMenu>
					</Pane>
				)}
				{this.state.edit && (
					<Pane>
						<Button
							isLoading={this.props.loading}
							onClick={this.clickHandler}
							primary={+true}
							name={this.props?.fieldName}
						>
							Save
						</Button>
					</Pane>
				)}
			</Pane>
		);
	}
}
