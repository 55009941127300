import React, { Component } from "react";
import Context from "../utils/Context";
import { withRouter } from "react-router-dom";
import Roles from "./Roles";
import { Pane, Spinner } from "evergreen-ui";
import BeginAssessmentCard from "./BeginAssessmentCard";

class RolesContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => {
					return (
						<Pane>
							{!!context?.reportLoading && (
								<Pane
									display="flex"
									width="100%"
									justifyContent="center"
									alignItems="center"
									height="100%"
								>
									<Spinner />
								</Pane>
							)}

							{!context?.report && <BeginAssessmentCard />}

							{/* {context?.report?.level === 0 && <UpgradeCardContainer />} */}

							{context?.report?.level >= 0 && (
								<Roles context={context} {...this.props} />
							)}
						</Pane>
					);
				}}
			</Context.Consumer>
		);
	}
}
export default withRouter(RolesContainer);
