import React from "react";
import { Component } from "react";
import Context from "../utils/Context";
import { majorScale, Pane } from "evergreen-ui";
import { PaneProps } from "../utils/Consts";
import PageTitleBar from "./PageTitleBar";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Card from "../lib/card/Card";
import WelcomeUpgradeNudge from "./nudges/WelcomeUpgradeNudge";

export default class Leadership extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane {...PaneProps} display="flex" width="100%">
						<Pane>
							{context?.user?.nudges?.welcomeUpgrade && (
								<Pane marginBottom={majorScale(5)}>
									<WelcomeUpgradeNudge />
								</Pane>
							)}
							<Breadcrumbs crumbs={[{ text: "Premium", link: "/premium" }]} />
							<PageTitleBar title="Premium" />
							<Pane
								{...PaneProps}
								flexDirection="row"
								flexWrap="wrap"
								padding={0}
							>
								<Pane marginRight="20px" marginBottom="20px">
									<Card
										title="Leadership"
										body="View your Leadership Report"
										link="/premium/leadership"
										primary={+true}
										button="VIEW"
										small={true}
										width="320px"
									/>
								</Pane>
								<Pane marginRight="20px" marginBottom="20px">
									<Card
										title="Manager/Director"
										body="View your Manager/Director Report"
										link="/premium/managerdirector"
										primary={+true}
										button="VIEW"
										small={true}
										width="320px"
									/>
								</Pane>
								<Pane marginRight="20px" marginBottom="20px">
									<Card
										title="Executive/VP"
										body="View your Executive/VP Report"
										link="/premium/executivevp"
										primary={+true}
										button="VIEW"
										small={true}
										width="320px"
									/>
								</Pane>
								<Pane marginRight="20px" marginBottom="20px">
									<Card
										title="C-Suite"
										body="View your C-Suite Report"
										link="/premium/csuite"
										primary={+true}
										button="VIEW"
										small={true}
										width="320px"
									/>
								</Pane>
								<Pane marginRight="20px" marginBottom="20px">
									<Card
										title="Roles"
										body="View your Roles Report"
										link="/premium/roles"
										primary={+true}
										button="VIEW"
										small={true}
										width="320px"
									/>
								</Pane>
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
