import React from "react";
import {
	Card as EGCard,
	Heading,
	Paragraph,
	majorScale,
	Pane,
} from "evergreen-ui";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import { CardProps } from "../../utils/Consts";
import "./card.css";

export default function Card(
	props = {
		small: Boolean,
		title: String,
		link: String,
		primary: Boolean,
		button: String,
		buttonClick: String,
		isLoading: Boolean,
	}
) {
	return (
		<EGCard
			{...CardProps}
			flexDirection={props.flexDirection || "column"}
			position={props.position || null}
			alignItems={props.small === true ? "flex-start" : "center"}
			paddingx={props.small === true ? "24px" : "72px"}
			paddingY={props.small === true ? "24px" : "60px"}
			minHeight={props.small === true ? "190px" : "326px"}
			// {...props}
			// maxWidth={props.maxWidth ? props.maxWidth : null}
			// height={props.height}
			// padding={props.padding}
		>
			<Heading
				textAlign="center"
				size={600}
				fontWeight={500}
				marginBottom={majorScale(2)}
				textTransform="capitalize"
			>
				{props.title}
			</Heading>
			<Paragraph
				textAlign="center"
				size={400}
				fontWeight={300}
				// letterSpacing="0.05em"
				marginBottom={majorScale(7)}
			>
				{props.body}
			</Paragraph>
			{props?.children}
			<Pane
				width="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				{props.link ? (
					<Link to={props.link}>
						<Button primary={props.primary}>{props.button}</Button>
					</Link>
				) : (
					props.button && (
						<Button
							onClick={props.buttonClick}
							isLoading={props.isLoading}
							primary={props.primary}
						>
							{props.button}
						</Button>
					)
				)}
			</Pane>
		</EGCard>
	);
}
