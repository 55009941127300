import React, { Component } from "react";
import { Pane, majorScale, Heading, Paragraph, Spinner } from "evergreen-ui";
import Context from "../utils/Context";
import PageTitleBar from "./PageTitleBar";
import { Palette, PaneProps, PrimaryContrast } from "../utils/Consts";
import Divider from "../lib/divider/Divider";
import ResultMiniCard from "./ResultMiniCard";
import ResultNaturalTalentCard from "./ResultNaturalTalentCard";
import UpgradeCardContainer from "./UpgradeCardContainer";
import RolesContainer from "./RolesContainer";
import RadarChartCard from "./RadarChartCard";
import TableContainer from "./TableContainer";
import UpgradeNudge from "./nudges/UpgradeNudge";
// import FullStory from "../utils/fullstory";
// import firebase from "../utils/firebase";
// import Button from "../lib/button/Button";
// import ReactDOMServer from "react-dom/server";
// import PDF from "../utils/pdf";

// import jsPDF from "jspdf";

export default class Result extends Component {
	// download = () => {
	// 	const doc = new jsPDF();
	// 	doc.html(
	// 		ReactDOMServer.renderToStaticMarkup(<Result hideDownload={true} />, {
	// 			callback: () => {
	// 				doc.save("myDocument.pdf");
	// 			},
	// 		})
	// 	);
	// };

	// test = async () => {
	// 	try {
	// 		const pdfRef = firebase.functions().httpsCallable("testCreatePDF");
	// 		const roomData = await pdfRef();
	// 	} catch (error) {
	// 		FullStory.log("error",error)
	// 	}
	// };
	render() {
		const naturalTalentGroupHeadingNames = [];
		return (
			<Context.Consumer>
				{(context) => {
					return (
						<Pane
							{...PaneProps}
							display="flex"
							width="100%"
							// paddingLeft={0}
						>
							{/* {!this.props?.hideDownload && (
								<Button onClick={this.download}>DOWNLOAD</Button>
							)} */}
							{/* <PDF /> */}
							{!!context?.report ? (
								<React.Fragment>
									{context?.user?.nudges?.upgrade &&
										context?.user?.report?.level === 0 && (
											<Pane
												marginBottom={majorScale(5)}
												maxWidth="100%"
												width="100%"
											>
												<UpgradeNudge />
											</Pane>
										)}
									{/* <Button onClick={this.test}>TEST</Button> */}
									<PageTitleBar title="Personality DNA Report" />
									<Paragraph size={400} fontWeight={300}>
										Discovering Your True Personality. The following report is a
										summary of the content in your Personality Type chapter(s)
									</Paragraph>
									<Divider
										style={{
											marginTop: majorScale(7),
											marginBottom: majorScale(7),
										}}
									/>

									<Pane className="result-natural-talents">
										<Heading
											// letterSpacing="0.03em"
											fontFamily='"Jost",sans-serif'
											textAlign="center"
											size={700}
											fontWeight="500"
											marginBottom={majorScale(1)}
										>
											{context?.user?.name
												? `${context?.user?.name}'s`
												: "Your"}{" "}
											Personality DNA Type:{" "}
											<span style={{ color: PrimaryContrast }}>
												{context?.report?.name}
											</span>
										</Heading>
									</Pane>
									<Divider
										style={{
											marginTop: majorScale(7),
											marginBottom: majorScale(7),
										}}
									/>
									<Pane className="result-natural-talents">
										<Heading
											// letterSpacing="0.03em"
											fontFamily='"Jost",sans-serif'
											textAlign="center"
											size={700}
											fontWeight="500"
											marginBottom={majorScale(3)}
										>
											Summary
										</Heading>
										<Pane
											// id="result-pdf"
											className="result-natural-talents-cards"
											display="flex"
											flexWrap="wrap"
											flexDirection="column"
										>
											<RadarChartCard />
											<Paragraph
												size={400}
												fontWeight={300}
												marginBottom={majorScale(2)}
											>
												The following are your scores for each of the 12
												Personality DNA Types. While knowing your own
												Personality DNA Type is important you will want to know
												your score in another Personality DNA Types when reading
												the Book. Harnessed with this information you will begin
												to understand the impact on your life and career when
												you try to access natural talents that are challenging
												for you.
											</Paragraph>
											<TableContainer />
										</Pane>
									</Pane>
									<Divider
										style={{
											marginTop: majorScale(7),
											marginBottom: majorScale(7),
										}}
									/>

									<Pane className="result-summary">
										<Heading
											// letterSpacing="0.03em"
											fontFamily='"Jost",sans-serif'
											textAlign="center"
											size={600}
											fontWeight="500"
											marginBottom={majorScale(5)}
										>
											{`Top Natural Talents`}
										</Heading>
										<Pane
											className="result-summary-cards"
											display="flex"
											flexWrap="wrap"
											flexDirection="row"
										>
											{context?.report?.competencies?.map(
												(naturalTalent, i) => (
													<ResultMiniCard
														key={`result-mini-card-${i}`}
														style={{
															marginRight: majorScale(2),
															marginTop: majorScale(2),
															marginBottom: majorScale(2),
															justifyContent: "space-between",
														}}
														title={naturalTalent?.name}
														color={Palette[i]}
														rating={naturalTalent?.value}
													/>
												)
											)}
										</Pane>
									</Pane>

									<Divider
										style={{
											marginTop: majorScale(7),
											marginBottom: majorScale(7),
										}}
									/>

									<Pane className="result-natural-talents">
										<Heading
											// letterSpacing="0.03em"
											fontFamily='"Jost",sans-serif'
											textAlign="center"
											size={700}
											fontWeight="500"
											marginBottom={majorScale(3)}
										>
											Summary Interpretation of Your Unique Natural Talents
										</Heading>
										<Pane
											className="result-natural-talents-cards"
											display="flex"
											flexWrap="wrap"
											flexDirection="row"
											// justifyContent="center"
										>
											{context.report?.competencies
												?.map((naturalTalent) => {
													return {
														title: naturalTalent.name,
														heading: naturalTalent.group,
														text: naturalTalent.paragraph,
													};
												})
												?.filter((obj) => {
													if (
														naturalTalentGroupHeadingNames.indexOf(
															obj.heading
														) < 0
													) {
														naturalTalentGroupHeadingNames.push(obj.heading);
														return obj;
													} else return;
												})
												?.map((obj) => {
													return context.report?.competencies
														?.filter(
															(naturalTalent) =>
																naturalTalent.group === obj.heading
														)
														?.map((naturalTalent) => {
															return {
																title: naturalTalent.name,
																heading: naturalTalent.group,
																text: naturalTalent.paragraph,
															};
														})
														?.map((obj, i) => {
															return (
																<Pane key={`natural-talent-${i}`}>
																	{i === 0 && (
																		<Heading
																			// letterSpacing="0.03em"
																			fontFamily='"Jost",sans-serif'
																			textAlign="center"
																			textTransform="uppercase"
																			size={600}
																			fontWeight="500"
																			marginBottom={majorScale(5)}
																			marginTop={majorScale(8)}
																		>
																			{obj.heading}
																		</Heading>
																	)}
																	<ResultNaturalTalentCard
																		key={`result-natural-talent-card-${i}`}
																		style={{
																			marginRight: majorScale(2),
																			marginTop: majorScale(1),
																			marginBottom: majorScale(5),
																		}}
																		title={obj.title}
																		color={Palette[i]}
																		text={obj.text}
																	/>
																</Pane>
															);
														});
												})}
										</Pane>
									</Pane>

									<RolesContainer />

									{context?.report?.level === 0 && (
										<Pane
											display="flex"
											justifyContent="center"
											alignItems="center"
											width="100%"
										>
											<UpgradeCardContainer />
										</Pane>
									)}
								</React.Fragment>
							) : context?.results?.length > 0 ? (
								<Pane
									display="flex"
									justifyContent="center"
									alignItems="center"
									width="100%"
								>
									<Spinner />
								</Pane>
							) : (
								<React.Fragment>
									{/* <PageTitleBar title={"Result Missing"} />
									<Card>
										<Text>Assessment Not Found</Text>
									</Card> */}
									<Pane
										display="flex"
										justifyContent="center"
										alignItems="center"
										width="100%"
									>
										<Spinner />
									</Pane>
								</React.Fragment>
							)}
						</Pane>
					);
				}}
			</Context.Consumer>
		);
	}
}
