import React from "react";
import { Pane } from "evergreen-ui";
import Context from "../utils/Context";
import BeginAssessmentCard from "./BeginAssessmentCard";
import ResultContainer from "./ResultContainer";

export default function Assessment() {
	return (
		<Context.Consumer>
			{(context) => {
				return (
					<Pane>
						<Pane display="flex" flexWrap="wrap">
							{context?.report?.level >= 0 ? (
								<ResultContainer />
							) : (
								<BeginAssessmentCard />
							)}
						</Pane>
					</Pane>
				);
			}}
		</Context.Consumer>
	);
}
