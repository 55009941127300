import React from "react";
import { Component } from "react";
import Context from "../utils/Context";
import { Pane, majorScale, Paragraph, Heading } from "evergreen-ui";
import { PaneProps } from "../utils/Consts";
import Divider from "../lib/divider/Divider";
import BarChartCard from "./BarChartCard";
import LegendTable from "../lib/legendTable/LegendTable";
import PageTitleBar from "./PageTitleBar";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";

export default class Leadership extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane {...PaneProps} display="flex" width="100%">
						<Pane>
							<Breadcrumbs
								crumbs={[
									{ text: "Premium", link: "/premium" },
									{ text: "Leadership", link: "/premium/leadership" },
								]}
							/>
							<PageTitleBar title="Your Leadership Result" />
							<Paragraph
								size={500}
								fontWeight={400}
								marginBottom={majorScale(2)}
							>
								What got you where you are will not, on their own, get you where
								you want to go next.
							</Paragraph>
							<Paragraph
								size={500}
								fontWeight={400}
								marginBottom={majorScale(2)}
							>
								It reports at what level of leadership you are presently
								predicted to succeed, and then identifies the specific
								leadership natural talents that your personality type needs to
								leverage to fully succeed in each of the 3 levels of leadership.
								Likewise, it identifies the specific leadership natural talents
								that will require professional coaching to maximize your future
								leadership success.
							</Paragraph>
						</Pane>
						<Divider />
						<Pane marginTop={majorScale(5)}>
							<Heading
								// letterSpacing="0.03em"
								fontFamily='"Jost",sans-serif'
								// textAlign="center"
								size={700}
								fontWeight="500"
								marginBottom={majorScale(3)}
							>
								Definitions:
							</Heading>
							<Heading
								// letterSpacing="0.03em"
								fontFamily='"Jost",sans-serif'
								// textAlign="center"
								size={500}
								fontWeight="500"
								marginBottom={majorScale(2)}
							>
								C-Suite Leader:
							</Heading>
							<Paragraph
								size={500}
								fontWeight={400}
								marginBottom={majorScale(2)}
							>
								Invariably the highest-level corporate leadership. Serving the
								corporation's most important senior executives. C-suite gets its
								name from the titles of top senior executives, which tend to
								start with the letter C, for "Chief," as in Chief Executive
								Officer (CEO), Chief Financial Officer (CFO), Chief Operating
								Officer (COO), or Chief Information Officer (CIO).
							</Paragraph>
							<Heading
								// letterSpacing="0.03em"
								fontFamily='"Jost",sans-serif'
								// textAlign="center"
								size={500}
								fontWeight="500"
								marginBottom={majorScale(2)}
							>
								Executive Leader:
							</Heading>
							<Paragraph
								size={500}
								fontWeight={400}
								marginBottom={majorScale(2)}
							>
								In large organizations, they go by the titles Vice-Presidents,
								COOs, GMs. Serving as the second or third leader in charge of a
								significant function, region, division of the business,
								organization, agency, institution, union, university,
								government, or branch of government.
							</Paragraph>
							<Heading
								// letterSpacing="0.03em"
								fontFamily='"Jost",sans-serif'
								// textAlign="center"
								size={500}
								fontWeight="500"
								marginBottom={majorScale(2)}
							>
								Manager/Director:
							</Heading>
							<Paragraph
								size={500}
								fontWeight={400}
								marginBottom={majorScale(2)}
							>
								This level of leadership is instrumental in driving
								productivity, monitoring progress and performance and generally
								where all of the action happens, and this managerial or director
								level leaders are mostly evaluated on their ability to drive
								performance to the maximum level.
							</Paragraph>
						</Pane>
						{/* <Divider /> */}
						<Pane>
							<Heading
								// letterSpacing="0.03em"
								fontFamily='"Jost",sans-serif'
								// textAlign="center"
								size={700}
								fontWeight="500"
								marginBottom={majorScale(3)}
								marginTop={majorScale(7)}
								textTransform="uppercase"
								textAlign="center"
							>
								Your Results
							</Heading>
							<Pane
								{...PaneProps}
								flexWrap="wrap"
								padding={0}
								width="100%"
								height="100%"
								maxHeight="600px"
								// maxWidth="1200px"
							>
								<BarChartCard />
							</Pane>
							<Pane>
								<Heading
									// letterSpacing="0.03em"
									fontFamily='"Jost",sans-serif'
									// textAlign="center"
									size={700}
									fontWeight="500"
									marginBottom={majorScale(3)}
									marginTop={majorScale(7)}
									textTransform="uppercase"
									textAlign="center"
								>
									Your results compared to the leadership levels reported
								</Heading>
								<LegendTable
									details="Consider the following guidelines for interpreting your 4
								Cs level of career success based on your score for this
								personality type. If your score was:"
									legend={[
										{
											title: "100 - 70",
											subtitle: "Considerable Talent",
											body: "Prediction: Performance Consistently Exceeds Expectations: (80-90% probability of “Exceeding” or “Meeting” expectations)",
										},
										{
											title: "69 - 60",
											subtitle: "Competent",
											body: "Prediction: Performance Typically Exceeds Expectations: (75-80% probability of “Meeting” or “Exceeding” expectations)",
										},
										{
											title: "59 - 40",
											subtitle: "Capable",
											body: "Prediction: Performance Typically Meets Expectations: (65-70% probability of “Meeting” or “Exceeding” expectations)",
										},
										{
											title: "39 - 0",
											subtitle: "Cautious",
											body: "Prediction: Performance Typically Falls short of Expectations: (40-45% probability of “Meeting” or “Exceeding” expectations)",
										},
										{ title: "", subtitle: "", body: "" },
									]}
								/>
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
