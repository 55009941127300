import { Pane } from "evergreen-ui";
import React, { Component } from "react";
import FieldInput from "../lib/fieldInput/FieldInput";
import Context from "../utils/Context";
import {
	CurrentFunctions,
	CurrentIndustry,
	CurrentLevel,
	PaneProps,
	UpdateDB,
} from "../utils/Consts";
import PageTitleBar from "./PageTitleBar";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Divider from "../lib/divider/Divider";
import SingleSelect from "../lib/select/SingleSelect";

export default class ProfileProfessional extends Component {
	state = {};

	updateField = async (fieldName, value) => {
		try {
			this.setState({ [`${fieldName}Loading`]: true });
			await UpdateDB(`users/${this.props.context.auth.uid}`, {
				[`${fieldName}`]: value,
			});
			this.setState({ [`${fieldName}Loading`]: false });
		} catch (error) {
			this.props.context.toaster.danger(
				`There was an error saving the ${fieldName}. Please try again later.`
			);
			this.setState({ [`${fieldName}Loading`]: false });
		}
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane width="100%" {...PaneProps} display="flex">
						<Breadcrumbs
							crumbs={[
								{ text: "Profile", link: "/profile" },
								{ text: "Professional", link: "/profile/professional" },
							]}
						/>
						<PageTitleBar title="Professional" />
						<Pane width="100%">
							<FieldInput
								loading={this.state.currentTitleLoading}
								editMessage="Update current title..."
								label="Current Title"
								value={context.user?.currentTitle}
								fieldName="currentTitle"
								submitHandler={this.updateField}
								placeholder={context.user?.currentTitle || "Current title..."}
								edit={!context.user?.currentTitle}
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<SingleSelect
								loading={this.state.currentFunctionLoading}
								editMessage="Update current function..."
								label="Current Function"
								value={context.user?.currentFunction}
								fieldName="currentFunction"
								submitHandler={this.updateField}
								options={CurrentFunctions}
								hasTitle={false}
								edit={!context.user?.currentFunction}
								title="Select Current Function"
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<SingleSelect
								loading={this.state.currentIndustryLoading}
								editMessage="Update current industry..."
								label="Current Industry"
								value={context.user?.currentIndustry}
								fieldName="currentIndustry"
								submitHandler={this.updateField}
								options={CurrentIndustry}
								hasTitle={false}
								edit={!context.user?.currentFunction}
								title="Select Current Industry"
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<SingleSelect
								loading={this.state.currentLevelLoading}
								editMessage="Update current level..."
								label="Current Level"
								value={context.user?.currentLevel}
								fieldName="currentLevel"
								submitHandler={this.updateField}
								options={CurrentLevel}
								hasTitle={false}
								edit={!context.user?.currentLevel}
								title="Select Current Level"
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<FieldInput
								loading={this.state.yearsExperienceLoading}
								editMessage="Update years experience..."
								label="Years Experience"
								value={context.user?.yearsExperience}
								fieldName="yearsExperience"
								submitHandler={this.updateField}
								placeholder={
									context.user?.yearsExperience || "Years Experience..."
								}
								edit={!context.user?.yearsExperience}
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
