import React, { Component } from "react";
import Context from "../utils/Context";
import Alert from "../lib/alert/Alert";
import {
	Heading,
	// InlineAlert,
	majorScale,
	Pane,
} from "evergreen-ui";
import { PaneProps, PrimaryDark } from "../utils/Consts";
import Button from "../lib/button/Button";
import Question from "../lib/question/Question";
import Divider from "../lib/divider/Divider";

class Section extends Component {
	state = { selected: {}, most: {}, least: {}, questions: {} };

	componentDidMount() {
		let { questions } = this.props;
		const now = new Date();
		const selected = Object.keys(questions)
			.map((qid) => questions[qid])
			.filter(
				(question) => question?.answer === "A" || question?.answer === "B"
			)
			.map((question) => ({
				date: now,
				section: question?.section,
				question: question?.question,
				answer: question?.answer,
			}))
			.reduce((acc, cur) => ({ ...acc, [cur?.question]: cur }), {});
		this.setState({
			selected,
			questions,
		});
	}

	vote = (e) => {
		const { id, value } = e.target;
		const { questions } = this.props;
		const { selected } = this.state;
		const answer = value === "A" ? "A" : value === "B" ? "B" : null;
		const alternate = answer === "A" ? "B" : "A";
		const now = new Date();
		if (questions[id]?.answer === answer) {
			delete questions[id].answer;
			delete selected[id];
		} else if (questions[id]?.answer === alternate) {
			questions[id].answer = answer;
			delete selected[id];
			selected[id] = {
				date: now,
				section: this.props?.section?.section,
				question: id,
				answer,
			};
		} else {
			questions[id].answer = answer;
			selected[id] = {
				date: now,
				section: this.props?.section?.section,
				question: id,
				answer,
			};
		}
		if (
			Object.keys(selected)
				.map((qid) => selected[qid])
				.filter((selection) => selection?.answer === answer)
				.filter(
					(selection) => selection?.section === this.props?.section?.section
				).length > this.props?.section[answer === "A" ? "most" : "least"]
		) {
			const removeOldSelectedArray = Object.keys(selected)
				.map((qid) => selected[qid])
				.filter((selection) => selection?.answer === answer)
				.filter(
					(question) => question?.section === this.props?.section?.section
				)
				.sort((a, b) => a?.date - b?.date);
			const removeOldSelected = removeOldSelectedArray[0];

			delete questions[removeOldSelected?.question].answer;
			delete selected[removeOldSelected?.question];
		}
		this.setState({ selected, questions });
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane
						{...PaneProps}
						flexDirection="column"
						flexWrap="wrap"
						padding={0}
					>
						<Pane>
							<Alert
								intent={this?.props?.alertIntent}
								title={this?.props?.section?.title}
								body={this?.props?.alertBody}
								marginBottom={majorScale(2)}
							/>
						</Pane>
						<Pane marginBottom={majorScale(5)}>
							<Pane
								// key={`question-${question?.question}`}
								className="assessment-header"
								marginBottom={majorScale(2)}
							>
								<Pane
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									marginTop={majorScale(1)}
									// marginBottom={majorScale(1)}
								>
									<Pane />
									<Pane
										width="146px"
										display="flex"
										flexDirection="row"
										justifyContent="space-around"
									>
										<Heading
											size={200}
											style={{
												color: PrimaryDark,
												minWidth: "60px",
												width: "65.91px",
												padding: "0",
												textAlign: "center",
											}}
										>
											Select {this.props?.section?.most}
										</Heading>
										<Heading
											size={200}
											style={{
												color: PrimaryDark,
												minWidth: "60px",
												width: "64.75px",
												padding: "0 ",
												textAlign: "center",
											}}
											// mini={+true}
										>
											Select {this.props?.section?.least}
										</Heading>
									</Pane>
								</Pane>
							</Pane>
							{Object.keys(this?.state?.questions)
								.map((qid) => this.state?.questions[qid])
								.filter(
									(question) =>
										question?.section === this.props?.section?.section
								)
								.map((question, i) => {
									return (
										<Pane
											key={`question-${question?.question}`}
											className="assessment-question"
											marginBottom={majorScale(2)}
										>
											<Question
												vote={this.vote}
												id={question?.question}
												question={question}
											/>
											<Divider />
										</Pane>
									);
								})}
						</Pane>
						<Pane
							display="flex"
							flexDirection="row-reverse"
							marginBottom={majorScale(10)}
						>
							<Pane flexDirection="row" display="flex">
								<Button
									disabled={this.props?.loading}
									onClick={this?.props?.regress}
								>
									{this?.props?.section === 1 ? "Cancel" : "Back"}
								</Button>
								<Button
									marginLeft={majorScale(2)}
									isLoading={this.props?.loading}
									onClick={this.props?.progress}
									primary={
										Object.keys(this.state?.selected)
											.map((qid) => this.state?.selected[qid])
											.filter((selection) => selection?.answer === "A")
											.filter(
												(selection) =>
													selection?.section === this.props?.section?.section
											).length !== this.props?.section?.most ||
										Object.keys(this.state?.selected)
											.map((qid) => this.state?.selected[qid])
											.filter((selection) => selection?.answer === "B")
											.filter(
												(selection) =>
													selection?.section === this.props?.section?.section
											).length !== this.props?.section?.least
											? +false
											: +true
									}
									disabled={
										Object.keys(this.state?.selected)
											.map((qid) => this.state?.selected[qid])
											.filter((selection) => selection?.answer === "A")
											.filter(
												(selection) =>
													selection?.section === this.props?.section?.section
											).length !== this.props?.section?.most ||
										Object.keys(this.state?.selected)
											.map((qid) => this.state?.selected[qid])
											.filter((selection) => selection?.answer === "B")
											.filter(
												(selection) =>
													selection?.section === this.props?.section?.section
											).length !== this.props?.section?.least
									}
								>
									{this?.props?.section?.section ===
									Object.keys(this?.props?.sections).length
										? this.props?.loading
											? "Saving..."
											: "Save"
										: "Continue"}
								</Button>
							</Pane>
							{/* <InlineAlert intent="danger">
								We weren’t able to save your changes
							</InlineAlert> */}
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}

Section.defaultProps = {
	alert: false,
	alertIntent: "success" || "warning" || "danger" || "none",
	hasIcon: true,
	alertBody: String,
	reduceSection: Function,
	advanceSection: Function,
};

export default Section;
