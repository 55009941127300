import React, { useContext } from "react";
import DismissableCard from "../../lib/dismissableCard/DismissableCard";
import { majorScale, Pane, Paragraph, Spinner } from "evergreen-ui";
import { UpdateDB, White } from "../../utils/Consts";
import Button from "../../lib/button/Button";
import Context from "../../utils/Context";
import { Link } from "react-router-dom";
import firebase from "../../utils/firebase";
import FullStory from "../../utils/fullstory";

export default function UpgradeNudge() {
	const context = useContext(Context);
	const products = context?.products || [];
	const productsLoading = context?.productsLoading;
	const book =
		products.filter((product) => product?.name === "Personality DNA Book")[0] ||
		{};
	const bookPrices = book?.prices || [];
	const bookPrice =
		bookPrices.filter((price) => price?.active === true)[0] || {};
	const bookImages = book?.images || [];
	const bookImage = bookImages[0] || "";

	const membership =
		products.filter((product) => product?.name === "Premium 1 Year")[0] || {};
	const membershipPrices = membership?.prices || [];
	const membershipPrice =
		membershipPrices.filter((price) => price?.active === true)[0] || {};
	const membershipImages = membership?.images || [];
	const membershipImage = membershipImages[0] || "";
	const closeNudge = async () => {
		try {
			await UpdateDB(`users/${context?.auth?.uid}`, {
				nudges: { upgrade: firebase.firestore.FieldValue.delete() },
			});
		} catch (error) {
			FullStory.log("error", error);
			context.toaster.danger(
				"There was an error closing the dialog. Please try again later."
			);
		}
	};
	return (
		<DismissableCard
			closeClickHandler={closeNudge}
			style={{ alignItems: "flex-start" }}
		>
			{productsLoading ? (
				<Spinner />
			) : (
				<Pane>
					<Pane marginBottom={majorScale(5)} width="100%" maxWidth="100%">
						<Paragraph color={White} width="-webkit-fill-available">
							Thank you for taking the free Personality DNA Assessment. Your
							personal results are now ready and can be found below. To get more
							indepth insights about your personality, purchase the Personality
							DNA book or upgrade to a Premium membership today.
						</Paragraph>
					</Pane>
					<Pane
						display="flex"
						flexDirection="row"
						width="100%"
						maxWidth="100%"
						flexWrap="wrap"
					>
						<Pane
							display="flex"
							flexDirection="row"
							marginRight={majorScale(8)}
							marginBottom={majorScale(5)}
						>
							<Pane
								backgroundColor={White}
								height={majorScale(20)}
								width={majorScale(15)}
								backgroundImage={`url(${bookImage})`}
								backgroundSize="contain"
								backgroundRepeat="no-repeat"
								backgroundPosition="center"
							></Pane>
							<Pane
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
								marginLeft={majorScale(3)}
							>
								<Pane display="flex" flexDirection="column">
									<Paragraph color={White}>{book.name}</Paragraph>
									<Paragraph color={White}>{`$${
										bookPrice?.unit_amount / 100
									}`}</Paragraph>
								</Pane>
								<Pane>
									<Link to="/upgrade">
										<Button>Buy Now</Button>
									</Link>
								</Pane>
							</Pane>
						</Pane>
						<Pane
							display="flex"
							flexDirection="row"
							marginRight={majorScale(8)}
							marginBottom={majorScale(5)}
						>
							<Pane
								backgroundColor={White}
								height={majorScale(20)}
								width={majorScale(15)}
								backgroundImage={`url(${membershipImage})`}
								backgroundSize="contain"
								backgroundRepeat="no-repeat"
								backgroundPosition="center"
							></Pane>
							<Pane
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
								marginLeft={majorScale(3)}
							>
								<Pane display="flex" flexDirection="column">
									<Paragraph color={White}>{membership.name}</Paragraph>
									<Paragraph color={White}>{`$${
										membershipPrice?.unit_amount / 100
									}`}</Paragraph>
								</Pane>
								<Pane>
									<Link to="/upgrade">
										<Button>Buy Now</Button>
									</Link>
								</Pane>
							</Pane>
						</Pane>
					</Pane>
				</Pane>
			)}
		</DismissableCard>
	);
}
