import { Card, Heading, majorScale, Text } from "evergreen-ui";
import React from "react";
import { NaturalTalentCardProps } from "../utils/Consts";

export default function ResultNaturalTalentCard(props) {
	return (
		<Card {...NaturalTalentCardProps} style={{ ...props.style }}>
			<Heading
				fontFamily='"Jost",sans-serif'
				textAlign="center"
				size={600}
				fontWeight="400"
				color={props.color}
				marginBottom={majorScale(3)}
			>
				{props?.title}
			</Heading>
			<Text>{props.text}</Text>
		</Card>
	);
}
