import React, { Component } from "react";
import Context from "../utils/Context";
// import { withRouter } from "react-router-dom";
import Assessment from "./Assessment";

export default class AssessmentContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => <Assessment context={context} {...this.props} />}
			</Context.Consumer>
		);
	}
}
// export default withRouter(AssessmentContainer);
