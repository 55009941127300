import React, { Component } from "react";
import { Pane, Text } from "evergreen-ui";
import Button from "../button/Button";

import Divider from "../divider/Divider";
import { PrimaryContrast, PrimaryGray, White } from "../../utils/Consts";

const initState = { value: "", edit: false };

export default class ButtonSwitch extends Component {
	state = { ...initState };

	render() {
		return (
			<Pane
				maxWidth={this.props?.maxWidth || "544px"}
				display="flex"
				width="100%"
				flexDirection="column"
			>
				<Pane
					display="flex"
					width="100%"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="flex-start"
				>
					<Pane
						display="flex"
						flexDirection="column"
						// justifyContent="space-between"
						alignItems="flex-start"
					>
						<Text fontWeight={600} marginBottom="8px">
							{this.props.title}
						</Text>
						<Text fontWeight={400} marginBottom="8px">
							{this.props.details}
						</Text>
					</Pane>
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
						// width="100px"
					>
						<Button
							name={this.props.name}
							onClick={this.props.click}
							value={false}
							style={{
								border: "none",
								boxShadow: "none",
								color: White,
								marginLeft: "8px",
								backgroundImage: "none",
								borderRadius: "2px",
								backgroundColor:
									this.props.value === false || this.props.value === "false"
										? PrimaryContrast
										: PrimaryGray,
							}}
							mini={+true}
						>
							{this.props?.left || "NO"}
						</Button>
						<Button
							name={this.props.name}
							onClick={this.props.click}
							value={true}
							style={{
								border: "none",
								boxShadow: "none",
								color: White,
								marginLeft: "8px",
								backgroundImage: "none",
								borderRadius: "2px",
								backgroundColor:
									this.props?.value === null ||
									this.props?.value === false ||
									this.props?.value === "false"
										? PrimaryGray
										: PrimaryContrast,
							}}
							mini={+true}
						>
							{this.props?.right || "YES"}
						</Button>
					</Pane>
				</Pane>
				{this.props?.children}
				{!this.props?.hideDivider && (
					<Pane marginY="20px">
						<Divider />
					</Pane>
				)}
			</Pane>
		);
	}
}
