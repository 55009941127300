import React from "react";
import { MultiSelectIcon, SelectMenu, majorScale } from "evergreen-ui";
import Button from "../button/Button";
import "./multiSelect.css";

export default function SingleSelect(props) {
	const options = props?.options;
	const [selectedItemsState, setSelectedItems] = React.useState(
		props?.tags || []
	);
	const [selectedItemNamesState, setSelectedItemNames] = React.useState(null);
	return (
		<SelectMenu
			isMultiSelect
			onFilterChange={(selectedItemsState) =>
				props?.onFilterChange(selectedItemsState)
			}
			title="Filter"
			options={options}
			selected={selectedItemsState}
			onSelect={(item) => {
				const selected = [...selectedItemsState, item.value];
				const selectedItems = selected;
				const selectedItemsLength = selectedItems.length;
				let selectedNames = "";
				if (selectedItemsLength === 0) {
					selectedNames = "";
				} else if (selectedItemsLength === 1) {
					selectedNames = selectedItems.toString();
				} else if (selectedItemsLength > 1) {
					selectedNames = selectedItemsLength.toString() + " selected...";
				}
				setSelectedItems(selectedItems);
				setSelectedItemNames(selectedNames);
				return props?.select(selectedItems);
			}}
			onDeselect={(item) => {
				const deselectedItemIndex = selectedItemsState.indexOf(item.value);
				const selectedItems = selectedItemsState.filter(
					(_item, i) => i !== deselectedItemIndex
				);
				const selectedItemsLength = selectedItems.length;
				let selectedNames = "";
				if (selectedItemsLength === 0) {
					selectedNames = "";
				} else if (selectedItemsLength === 1) {
					selectedNames = selectedItems.toString();
				} else if (selectedItemsLength > 1) {
					selectedNames = selectedItemsLength.toString() + " selected...";
				}

				setSelectedItems(selectedItems);
				setSelectedItemNames(selectedNames);
				return props?.select(selectedItems);
			}}
		>
			<Button>
				{!props?.hideIcon && <MultiSelectIcon marginRight={majorScale(1)} />}
				{selectedItemNamesState || props?.buttonTitle || "Select multiple..."}
			</Button>
		</SelectMenu>
	);
}
