import React from "react";
// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";

// export default function ScrollToTop(props) {
// 	const { pathname } = useLocation();

// 	useEffect(() => {
// 		window.scrollTo(0, 0);
// 	}, [pathname]);

// 	return <>{props.children}</>;
// }

import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return <>{props.children}</>;
};

export default ScrollToTop;

// import { withRouter } from "react-router-dom";

// class ScrollToTop extends React.Component {
// 	componentDidUpdate(prevProps) {
// 		if (this.props.location.pathname !== prevProps.location.pathname) {
// 			window.scrollTo(0, 0);
// 		}
// 	}

// 	render() {
// 		return null;
// 	}
// }

// export default withRouter(ScrollToTop);
