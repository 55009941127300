import React, { Component } from "react";
import Context from "../utils/Context";
import { Login, Logout, Register, PrimaryLight } from "../utils/Consts";
import Button from "../lib/button/Button";
import {
	Pane,
	Dialog,
	TextInputField,
	Avatar,
	Popover,
	Menu,
	Text,
	Link,
	Heading,
	CrossIcon,
	majorScale,
} from "evergreen-ui";
import { NavLink } from "react-router-dom";
import firebase from "../utils/firebase";

const authInit = {
	email: "",
	password: "",
};
export default class Auth extends Component {
	state = {
		...authInit,
	};

	login = async (e) => {
		try {
			this.props.context.update("authLoading", true);
			const { email, password } = this.state;
			await Login(email, password);
			await firebase.analytics().logEvent("login");
			this.setState({ ...authInit });
			this.setState({ isShown: false });
		} catch (error) {
			this.props.context.toaster.danger(error.message);
			this.props.context.update("authLoading", false);
		}
	};

	register = async (e) => {
		try {
			this.props.context.update("authLoading", true);
			const { email, password } = this.state;
			await Register(email, password);
			// CREATE WELCOME ONBOARDING
			// this.props.history.push("/welcome");
			await firebase.analytics().logEvent("registration");
			this.setState({ isShown: false, ...authInit });
		} catch (error) {
			this.props.context.toaster.danger(error.message);
			this.props.context.update("authLoading", false);
		}
	};

	logout = async () => {
		try {
			await Logout();
			await firebase.analytics().logEvent("logout");
			this.props.history.push("/");
		} catch (error) {
			this.props.context.update("authLoading", false);
		}
	};

	openDialogLogin = () =>
		this.setState({ isShown: true, ...authInit, register: false });

	openDialogRegister = () =>
		this.setState({ isShown: true, ...authInit, register: true });

	clear = () => this.setState({ email: "", pass: "", isShown: false });

	inputHandler = (e) => this.setState({ [`${e.target.name}`]: e.target.value });

	switchToLogin = () => this.setState({ register: false });

	switchToRegister = () => this.setState({ register: true });

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane className="auth" display="flex">
						<Dialog
							className="auth-dialog"
							containerProps={{
								borderRadius: 0,
								backgroundColor: PrimaryLight,
							}}
							isShown={this.state.isShown}
							// title={this.state.register ? "SIGN UP" : "LOG IN"}
							header={
								<Pane
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									width="100%"
								>
									<Pane></Pane>
									<Pane>
										<Heading size={500}>
											{this.state.register ? "SIGN UP" : "LOG IN"}
										</Heading>
									</Pane>
									<Pane>
										<CrossIcon
											cursor="pointer"
											onClick={() => this.setState({ isShown: false })}
										/>
									</Pane>
								</Pane>
							}
							onCloseComplete={() => this.setState({ isShown: false })}
							isConfirmLoading={context.authLoading}
							isConfirmDisabled={!!context.auth}
							onCancel={this.clear}
							// hasFooter={true}
							footer={
								<Pane width="100%" display="flex" justifyContent="flex-end">
									<Pane marginLeft="5px" marginRight="5px">
										<Button
											// marginLeft={majorScale(1)}
											onClick={this.clear}
											isLoading={context.authLoading}
										>
											Cancel
										</Button>
									</Pane>
									<Pane marginLeft="5px" marginRight="5px">
										<Button
											onClick={this.state.register ? this.register : this.login}
											appearance="primary"
											isLoading={context.authLoading}
											primary={+true}
										>
											{this.state.register ? "Register" : "Login"}
										</Button>
									</Pane>
								</Pane>
							}
							confirmLabel={this.state.register ? "Register" : "Login"}
							onConfirm={this.state.register ? this.register : this.login}
						>
							<Pane is="form">
								<TextInputField
									inputHeight={51}
									label="Email"
									name="email"
									onChange={this.inputHandler}
									placeholder="Email..."
									value={this.state.email}
								/>
								<TextInputField
									inputHeight={51}
									label="Password"
									name="password"
									onChange={this.inputHandler}
									placeholder="Password..."
									value={this.state.password}
									type="password"
								/>
							</Pane>
							<Pane textAlign="left">
								<Pane>
									{!this.state.register && (
										<Text>
											Forgot your password?
											<NavLink
												onClick={() =>
													this.setState({
														isShown: false,
													})
												}
												to="/forgot"
											>
												Reset your password
											</NavLink>
										</Text>
									)}
								</Pane>
								<Pane>
									<Text>
										{this.state.register
											? `Already registered? `
											: `Don't have an account? `}
										<Link
											cursor="pointer"
											onClick={
												this.state.register
													? this.switchToLogin
													: this.switchToRegister
											}
											textDecoration="none"
										>
											{this.state.register ? `Login` : `Register`}
										</Link>
									</Text>
								</Pane>
							</Pane>
						</Dialog>
						{!context.authLoading && !context.auth && (
							<Button
								onClick={this.openDialogRegister}
								isLoading={context.authLoading}
								marginRight={majorScale(2)}
							>
								Register
							</Button>
						)}
						{!context.authLoading && !!context.auth ? (
							<Popover
								content={({ close }) => (
									<Menu>
										<Menu.Group title="Profile">
											<NavLink to="/profile">
												<Menu.Item onSelect={close} icon="person">
													Profile
												</Menu.Item>
											</NavLink>
											<NavLink to="/billing">
												<Menu.Item onSelect={close} icon="creditcard">
													Billing
												</Menu.Item>
											</NavLink>
											<NavLink to="/settings">
												<Menu.Item onSelect={close} icon="settings">
													Settings
												</Menu.Item>
											</NavLink>
										</Menu.Group>
										<Menu.Divider />
										<Menu.Group>
											<Menu.Item icon="log-out" onSelect={this.logout}>
												Logout
											</Menu.Item>
										</Menu.Group>
									</Menu>
								)}
							>
								<Avatar
									name={context?.auth?.email}
									src={context?.auth?.photoURL || null}
									size={28}
									// isSolid
									// marginRight={majorScale(2)}
								/>
							</Popover>
						) : (
							<Button
								onClick={context.auth ? this.logout : this.openDialogLogin}
								isLoading={context.authLoading}
								primary={+true}
								marginleft="30px"
							>
								{context.authLoading ? "" : context.auth ? "Logout" : "Login"}
							</Button>
						)}
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
