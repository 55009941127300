import Card from "../lib/card/Card";
import React, { Component } from "react";
import { Redirect, Route } from "react-router";
import Context from "../utils/Context";
import AssessmentContainer from "./AssessmentContainer";
import BillingContainer from "./BillingContainer";
import CheckoutContainer from "./CheckoutContainer";
import BeginAssessmentCard from "./BeginAssessmentCard";
import LeadershipContainer from "./LeadershipContainer";
import LevelContainer from "./LevelContainer";
import LogoutContainer from "./LogoutContainer";
import Profile from "./Profile";
import ProfileIdentityContainer from "./ProfileIdentityContainer";
import ProfileProfessionalContainer from "./ProfileProfessionalContainer";
import ProfileInterestsContainer from "./ProfileInterestsContainer";
import ResultContainer from "./ResultContainer";
import RolesContainer from "./RolesContainer";
import SettingsContainer from "./SettingsContainer";
import VerifyEmailContainer from "./VerifyEmailContainer";
import VideoContainer from "./VideoContainer";
import CreateRoomContainer from "./CreateRoomContainer";
import RoomsContainer from "./RoomsContainer";
import PersonalityDefinitionContainer from "./PersonalityDefinitionContainer";
import PersonalityDefinitionsContainer from "./PersonalityDefinitionsContainer";
import PremiumContainer from "./PremiumContainer";
import EventsContainer from "./EventsContainer";
import UpgradeContainer from "./UpgradeContainer";
import UpgradeUser from "./UpgradeUser";
// import PDF from "../utils/pdf";
import AdminUsers from "./AdminUsers";
import ProfileCareerContainer from "./ProfileCareerContainer";
import AdminEvents from "./AdminEvents";
import AdminAddEvent from "./AdminAddEvent";
import AdminUser from "./AdminUser";

export default class Router extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => {
					return (
						<React.Fragment>
							{!!context.auth?.emailVerified ? (
								<>
									<Route exact path="/">
										{context?.report?.level >= 0 ? (
											<Card
												title={`${context?.report?.name} Personality Report`}
												body="Your Personality DNA results are now ready and available. Your Personality DNA results are personalized to you, so please ensure that you set aside some time to review these results in depth."
												link={`/assessment`}
												button="See Your Results"
												primary={+true}
											/>
										) : (
											<BeginAssessmentCard />
										)}
									</Route>
									<Route exact path="/dna">
										{context?.report?.level >= 0 ? (
											<Card
												title={`${context?.report?.name} Personality Report`}
												body="Your Personality DNA results are now ready and available. Your Personality DNA results are personalized to you, so please ensure that you set aside some time to review these results in depth."
												link={`/assessment`}
												button="See Your Results"
												primary={+true}
											/>
										) : (
											<BeginAssessmentCard />
										)}
									</Route>
									<Route exact path="/assessment">
										<AssessmentContainer />
									</Route>
									{/* <Route exact path="/pdf">
										<PDF />
									</Route> */}
									<Route exact path="/result/:id">
										<ResultContainer />
									</Route>
									{/* <Route exact path="/result/:id/roles"><RolesContainer /></Route> */}
									<Route exact path="/premium/roles">
										<RolesContainer />
									</Route>
									<Route exact path="/events">
										<EventsContainer premium={false} />
									</Route>
									<Route exact path="/premium">
										<PremiumContainer />
									</Route>
									<Route exact path="/premium/leadership">
										<LeadershipContainer />
									</Route>
									<Route exact path="/premium/executivevp">
										<LevelContainer level="Executive/VP" url="executivevp" />
									</Route>
									<Route exact path="/premium/csuite">
										<LevelContainer level="C-Suite" url="csuite" />
									</Route>
									<Route exact path="/premium/managerdirector">
										<LevelContainer
											level="Manager/Director"
											url="managerdirector"
										/>
									</Route>
									<Route exact path="/premium/events">
										<EventsContainer premium={true} />
									</Route>
									<Route exact path="/checkout">
										<CheckoutContainer />
									</Route>
									<Route exact path="/upgrade">
										<UpgradeContainer />
									</Route>
									<Route exact path="/settings">
										<SettingsContainer />
									</Route>
									<Route exact path="/profile">
										<Profile />
									</Route>
									<Route exact path="/billing">
										<BillingContainer />
									</Route>
									<Route exact path="/profile/identity">
										<ProfileIdentityContainer />
									</Route>
									<Route exact path="/profile/professional">
										<ProfileProfessionalContainer />
									</Route>
									<Route exact path="/profile/interests">
										<ProfileInterestsContainer />
									</Route>
									<Route exact path="/profile/career">
										<ProfileCareerContainer />
									</Route>
									<Route exact path="/video/:roomname">
										<VideoContainer />
									</Route>
									<Route exact path="/personalities">
										<PersonalityDefinitionsContainer />
									</Route>
									<Route exact path="/personality/:id">
										<PersonalityDefinitionContainer />
									</Route>
									{!!context.customClaims?.admin && (
										<>
											<Route exact path="/admin/createroom">
												<CreateRoomContainer />
											</Route>
											<Route exact path="/admin/rooms">
												<RoomsContainer />
											</Route>
											<Route exact path="/admin/upgradeuser">
												<UpgradeUser />
											</Route>
											<Route exact path="/admin/users">
												<AdminUsers />
											</Route>
											<Route exact path="/admin/events">
												<AdminEvents />
											</Route>
											<Route exact path="/admin/addevent">
												<AdminAddEvent />
											</Route>
											<Route exact path="/admin/user/:uid">
												<AdminUser />
											</Route>
										</>
									)}

									{/* <Route path="/forgot">
										<ForgotContainer />
									</Route> */}
								</>
							) : (
								<VerifyEmailContainer />
							)}

							<Route path="/logout">
								<LogoutContainer />
								<Redirect to="/" />
							</Route>
						</React.Fragment>
					);
				}}
			</Context.Consumer>
		);
	}
}
