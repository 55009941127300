import {
	majorScale,
	Menu,
	Pane,
	Paragraph,
	Popover,
	Position,
} from "evergreen-ui";
import React, { useContext, useEffect, useState } from "react";
import firebase from "../utils/firebase";
import Button from "../lib/button/Button";
import { CreateCalEvent, PrimaryDarkAlt } from "../utils/Consts";
import Context from "../utils/Context";
import Moment from "react-moment";
import FullStory from "../utils/fullstory";

export default function EventRegistration(props) {
	const context = useContext(Context);
	const [loading, setLoading] = useState(false);
	const [registered, setRegistered] = useState(false);

	useEffect(
		() => {
			getRegistrationStatus();
			return () => unsubsribe();
		},
		// eslint-disable-next-line
		[]
	);

	const unsubsribe = () => {
		var unsubscribe = firebase
			.firestore()
			.collection("users")
			.doc(context.user?.uid)
			.collection("userEvents")
			.doc(event?.id)
			.onSnapshot();
		unsubscribe();
	};

	const getRegistrationStatus = async () => {
		try {
			setLoading(true);

			firebase
				.firestore()
				.collection("users")
				.doc(context.user?.uid)
				.collection("userEvents")
				.doc(event?.id)
				.onSnapshot((doc) => {
					if (doc.exists) {
						const status = doc.data();
						if (!status?.registered) {
							setRegistered(false);
						} else {
							setRegistered(true);
						}
					} else {
						setRegistered(false);
					}
					setLoading(false);
				});
		} catch (error) {
			FullStory.log("error", error);
			setLoading(false);
			return context?.toaster?.danger(
				"There was an error retrieving registration status. Please try again later."
			);
		}
	};

	const { event } = props;
	const addToCalendar = async (event, type) => {
		const { title, description, location, start, end } = event;
		const formattedDescription = description.replace(new RegExp("/n", "g"), "");
		await CreateCalEvent({
			type,
			title,
			description: formattedDescription,
			location,
			start: start.toDate(),
			end: end.toDate(),
		});
	};

	const register = async () => {
		try {
			setLoading(true);
			await firebase
				.firestore()
				.collection("users")
				.doc(context?.auth?.uid)
				.collection("userEvents")
				.doc(event?.id)
				.set(
					{
						registered: !registered,
						id: event?.id,
						uid: context?.auth?.uid,
					},
					{ merge: true }
				);
			setLoading(false);
		} catch (error) {
			FullStory.log("error", error);
			return context?.toaster?.danger(
				"There was an error registering. Please try again later."
			);
		}
	};

	return (
		<Pane
			padding={majorScale(3)}
			borderWidth="1px"
			borderColor={PrimaryDarkAlt}
			borderStyle="solid"
			borderRadius="5px"
			minHeight="100px"
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			marginBottom={majorScale(5)}
		>
			<Pane display="flex" flexDirection="column">
				<Paragraph>
					<Moment format="ddd MMM D | HH:mm A">
						{event?.start?.toMillis()}
					</Moment>
					{" - "}
					<Moment format="HH:mm A">{props?.event?.end?.toMillis()}</Moment>
				</Paragraph>

				<Paragraph marginBottom={majorScale(2)}>
					{!!event?.location && (
						<a target="_blank" href={event?.location} rel="noopener noreferrer">
							Meeting Link
						</a>
					)}
				</Paragraph>
			</Pane>
			<Pane display="flex" flexDirection="column">
				<Button
					isLoading={loading}
					onClick={register}
					marginBottom={majorScale(1)}
					primary={+registered}
				>
					{registered ? "Registered" : "Register  "}
				</Button>
				{registered && (
					<Popover
						position={Position.BOTTOM_LEFT}
						content={
							<Menu>
								<Menu.Item onSelect={() => addToCalendar(event, "ical")}>
									iCal
								</Menu.Item>
								<Menu.Item onSelect={() => addToCalendar(event, "google")}>
									Google
								</Menu.Item>
								<Menu.Item
									onSelect={() => addToCalendar(event, "outlookoffice")}
								>
									MS Office
								</Menu.Item>
								<Menu.Item onSelect={() => addToCalendar(event, "outlooklive")}>
									MS Live
								</Menu.Item>
							</Menu>
						}
					>
						<Button onClick={() => addToCalendar(event)}>
							Add to Calendar
						</Button>
					</Popover>
				)}
			</Pane>
		</Pane>
	);
}
