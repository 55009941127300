import React, { useContext, useState } from "react";
import { Pane } from "evergreen-ui";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import { PaneProps, UpdateDB } from "../utils/Consts";
import Context from "../utils/Context";
import PageTitleBar from "./PageTitleBar";
import Card from "../lib/card/Card";

import Section from "./Section";
import Sections from "../utils/sections.json";
import Questions from "../utils/questions.json";
import firebase from "../utils/firebase";
import { Redirect } from "react-router-dom";
import FullStory from "../utils/fullstory";

export default function Ipsative() {
	const [section, setSection] = useState(0);
	const [questions] = useState(Questions);
	const context = useContext(Context);
	const [loading, setLoading] = useState(false);
	const [redirect, setRedirect] = useState(null);

	const regress = () => setSection(section - 1);

	const progress = async () => {
		try {
			if (section === Object.keys(Sections).length) {
				context.update("overlay", true);
				setLoading(true);
				const answers = Object.keys(questions)
					.map((qid) => questions[qid])
					.filter((question) => !!question?.answer)
					.map((question) => `${question.question}${question.answer}`)
					.join();
				const uid = context?.user?.uid;
				const created = new Date();
				await UpdateDB(`answers/${uid}`, { uid, answers, created });

				const data = await sendAnswers(answers);
				const { sid } = data;
				setLoading(false);
				window.scrollTo({ top: 0, behavior: "auto" });
				context.update("overlay", false);
				return setRedirect(`/result/${sid}`);
			} else {
				setSection(section + 1);
				window.scrollTo({ top: 0, behavior: "smooth" });
			}
		} catch (error) {
			FullStory.log("error", error);
			context.update("overlay", false);
			return context?.toaster?.danger(
				"There was an error saving assessment data. Please try again later."
			);
		}
	};

	const sendAnswers = async (answers) => {
		try {
			const submitAssessmentAnswers = firebase
				.functions()
				.httpsCallable("submitAssessmentAnswers");
			const hashData = await submitAssessmentAnswers({ answers });

			const data = hashData?.data;

			// setData(data);
			// const { success_url } = data;
			return data;
		} catch (error) {
			FullStory.log("error", error);
			return context?.toaster?.danger(
				"There was an error saving your assessment data. Please try again later."
			);
		}
	};

	return (
		<Context.Consumer>
			{(context) => (
				<Pane {...PaneProps} display="flex" width="100%">
					<Pane className="test2">
						<Breadcrumbs
							crumbs={[{ text: "Assessment", link: "/assessment" }]}
						/>
						<PageTitleBar title="Personality DNA Questions" />

						{section >= 0 && (
							<Pane
								{...PaneProps}
								flexDirection={section === 0 ? "row" : "column"}
								flexWrap="wrap"
								padding={0}
								className="test1"
							>
								{section === 0 ? (
									<Card
										title="Personality DNA"
										body="Begin the Personality DNA Assessment. The Assessment will take 15-20 minutes, so please ensure that you have set aside some time to focus on the questions and your answers."
										primary={+true}
										button="LAUNCH"
										buttonClick={progress}
									/>
								) : (
									<Section
										alertBody={`Please select "MOST" beside the ${Sections[section]?.most} descriptive statements that best describe you and "LEAST" beside the ${Sections[section]?.least} statements that least describe you.`}
										alertIntent={"none"}
										sections={Sections}
										section={Sections[section]}
										regress={regress}
										progress={progress}
										questions={questions}
										loading={loading}
									/>
								)}
							</Pane>
						)}
						{!!redirect && <Redirect to={redirect} />}
					</Pane>
				</Pane>
			)}
		</Context.Consumer>
	);
}
