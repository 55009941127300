import React, { Component } from "react";
import { Pane, majorScale, Spinner } from "evergreen-ui";
import { PaneBorderRadius, PaneProps, PrimaryLight } from "../utils/Consts";
import Context from "../utils/Context";
import Splash from "./Splash";
import Router from "./Router";
import { Route } from "react-router-dom";
import ForgotContainer from "./ForgotContainer";
import ResetContainer from "./ResetContainer";
import AuthToContinue from "./AuthToContinue";
import { Switch } from "react-router-dom";

export default class Main extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane
						className="main-body-container"
						{...PaneProps}
						background={PrimaryLight}
						borderRadius={PaneBorderRadius}
						flexGrow={2}
						paddingX={majorScale(7)}
						boxShadow="none"
						maxWidth="1350px"
						width="100%"
					>
						<ResetContainer />
						{(!!context.userLoading ||
							!!context.authLoading ||
							(!!context.auth && !context.user) ||
							(!context.auth && !!context.user)) && (
							<Pane
								display="flex"
								width="100%"
								justifyContent="center"
								alignItems="center"
								height="100%riy"
							>
								<Spinner />
							</Pane>
						)}

						{!context.userLoading &&
							!context.authLoading &&
							!context.auth &&
							!context.user && (
								<>
									<Switch>
										<Route exact path="/dna">
											<Splash book={true} />
										</Route>
										<Route exact path="/">
											<Splash />
										</Route>
										<Route path="/forgot">
											<ForgotContainer />
										</Route>
										<Route component={AuthToContinue} />
									</Switch>
								</>
							)}

						{!(
							!!context.userLoading ||
							!!context.authLoading ||
							(!context.userLoading && !context.user) ||
							(!context.authLoading && !context.auth)
						) &&
							!!context.user && (
								<main
									style={{
										display: "flex",
										flexWrap: "wrap",
										marginTop: majorScale(2),
									}}
									width="100%"
								>
									<Router />
								</main>
							)}
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
