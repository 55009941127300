import React, { Component } from "react";
import Context from "../utils/Context";
import {
	PaneBackground,
	PaneProps,
	PrimaryContrast,
	White,
} from "../utils/Consts";
import { majorScale, MenuIcon, Pane, TabNavigation } from "evergreen-ui";
import { Link, NavLink } from "react-router-dom";
import Brandmark from "./Brandmark";
import ProfileButton from "./ProfileButton";
import Button from "../lib/button/Button";

export default class Header extends Component {
	state = { menuOpen: false };

	menuOpenClick = () => {
		this.setState({ menuOpen: !this.state.menuOpen });
		const el = window.document.getElementById("sidebar-container");
		this.state.menuOpen
			? el.classList.remove("sidebar-container--open")
			: el.classList.add("sidebar-container--open");
	};
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane
						{...PaneProps}
						paddingLeft="5%"
						paddingRight="5%"
						background={PaneBackground}
						flexDirection="row"
						borderTopLeftRadius={0}
						borderTopRightRadius={0}
						borderBottomRightRadius={0}
						className="app-header"
						height="70px"
						alignItems="center"
					>
						<Pane flex={1} alignItems="center" display="flex">
							{!context.auth ? (
								<NavLink exact activeClassName="nav-item__active" to="/">
									<TabNavigation>
										<Brandmark width="170px" />
									</TabNavigation>
								</NavLink>
							) : (
								<MenuIcon
									onClick={this.menuOpenClick}
									className={
										this.state.menuOpen
											? "header_menu-open-icon header_menu-open-icon--open"
											: "header_menu-open-icon"
									}
									display="none"
								/>
							)}
						</Pane>
						<Pane display="flex" flexDirection="row">
							{context?.report?.level === 0 && (
								<Link to="/upgrade">
									<Button
										style={{
											padding: "0px",
											height: "54px",
											border: "1px solid #000000",
											borderRadius: "5px",
											backgroundColor: PrimaryContrast,
											color: White,
											backgroundImage: "none",
											borderWidth: "0px",
											paddingLeft: majorScale(3),
											paddingRight: majorScale(3),
											marginRight: majorScale(3),
										}}
									>
										Upgrade to Premium
									</Button>
								</Link>
							)}
							{!context.authLoading ? (
								!!context.auth ? (
									<ProfileButton
										name={
											context?.user?.name?.charAt(0) ||
											context?.auth?.email?.charAt(0)
										}
									/>
								) : null
							) : null}
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
