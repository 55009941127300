import React, { Component } from "react";
import Context from "../utils/Context";
import Forgot from "./Forgot";

export default class ForgotContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => <Forgot context={context} {...this.props} />}
			</Context.Consumer>
		);
	}
}
