import { majorScale, Pane, Spinner, Paragraph, Heading } from "evergreen-ui";
import Card from "../lib/card/Card";
import React, { Component } from "react";
import {
	CardProps,
	PaletteTeal,
	PaneProps,
	PrimaryContrast,
	PrimaryContrastLight,
	PrimaryDark,
	White,
} from "../utils/Consts";
import Context from "../utils/Context";
import firebase from "../utils/firebase";
import Button from "../lib/button/Button";

export default class Upgrade extends Component {
	state = {};

	subscribe = async (price) => {
		this.props.context.update("overlay", true);
		this.setState({ [`${price?.id}Loading`]: true });
		const mode = price?.type === "one_time" ? "payment" : "subscription";
		const docRef = await firebase
			.firestore()
			.collection("users")
			.doc(this.props?.context?.auth?.uid)
			.collection("checkout_sessions")
			.add({
				// collect_shipping_address: true,
				mode,
				allow_promotion_codes: true,
				price: price?.id,
				// mode: "payment",
				shipping_address_collection: {
					allowed_countries: ["US", "CA"],
				},
				shipping_options: [
					{
						shipping_rate_data: {
							type: "fixed_amount",
							fixed_amount: {
								amount: 0,
								currency: "cad",
							},
							display_name: "Free shipping",
							delivery_estimate: {
								minimum: {
									unit: "business_day",
									value: 1,
								},
								maximum: {
									unit: "business_day",
									value: 5,
								},
							},
						},
					},
				],
				// quantity: 1,
				// tax_rates: ["txr_1KgJ8oGL1A2200QpnhUXBI2D"],
				automatic_tax: {
					enabled: true,
				},
				success_url: `${window.location.origin}/premium`,
				cancel_url: `${window.location.origin}/upgrade`,
				[`automatic_tax[enabled]`]: true,
			});
		// Wait for the CheckoutSession to get attached by the extension
		docRef.onSnapshot((snap) => {
			const { error, url } = snap.data();
			if (error) {
				this.props.context.update("overlay", false);
				this.setState({ [`${price?.id}Loading`]: false });
			}
			if (url) {
				window.location.assign(url);
			}
		});
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => {
					return (
						<Pane {...PaneProps} flexWrap="wrap" padding={0} width="100%">
							{this.props?.context?.productsLoading ? (
								<Spinner />
							) : (
								<>
									<Pane
										backgroundImage={`linear-gradient(to right, ${PrimaryContrastLight},${PrimaryContrast})!important`}
										width="100%"
										display="flex"
										justifyContent="center"
										alignItems="center"
										height={majorScale(40)}
										flexDirection="column"
										marginBottom={majorScale(10)}
									>
										<Heading
											size={700}
											color={White}
											marginBottom={majorScale(3)}
											padding={majorScale(2)}
											textAlign="center"
										>
											LeadersEd is Where Your Career Journey Begins
										</Heading>
										<Heading size={500} color={White}>
											Leaders Start Here
										</Heading>
									</Pane>
									<Pane
										color={PrimaryDark}
										display="flex"
										justifyContent="center"
										width="100%"
										marginBottom={majorScale(12)}
									>
										<Heading size={700}>
											Choose the Path That's Right For You
										</Heading>
									</Pane>
									<Pane
										{...PaneProps}
										flexDirection="row"
										flexWrap="wrap"
										padding={0}
									>
										{this.props?.context?.products?.map((product, i) => {
											const prices = product?.prices || [];
											const active_prices = prices.filter(
												(price) => price.active === true
											);
											const price = active_prices[0] || {};
											const unit_amount = price?.unit_amount;
											return (
												<Pane
													marginRight="20px"
													marginBottom="20px"
													key={`product-card-${i}`}
													maxWidth={majorScale(40)}
												>
													<Card
														{...CardProps}
														title={product.name}
														// body={product.description}
														primary={+true}
														button="Buy Now"
														isLoading={this.state[`${price?.id}Loading`]}
														buttonClick={(e) => this.subscribe(price, e)}
														small={+true}
														position="relative"
													>
														<Pane
															maxWidth="100%"
															display="flex"
															justifyContent="center"
															alignItems="center"
															width="100%"
															marginBottom={majorScale(3)}
														>
															{/* <img
																width={majorScale(15)}
																alt={product.name}
																src={product.images && product.images[0]}
															/> */}
															<Heading size={800}>{`$${
																unit_amount / 100
															}`}</Heading>
														</Pane>
														<Paragraph marginBottom={majorScale(3)}>
															{product.description}
														</Paragraph>
														{product?.name === "Premium 1 Year" && (
															<Button
																disabled={true}
																position="absolute"
																top="-22px"
																width="100%"
																display="flex"
																justifyContent="center"
																backgroundColor={PaletteTeal}
																borderColor={PaletteTeal}
																color={White}
															>
																Best Value
															</Button>
														)}
														{/* {product?.prices?.map((price, i) => (
															<Button
																isLoading={this.state[`${price?.id}Loading`]}
																value={price}
																key={`price-item-${i}`}
																onClick={(e) => this.subscribe(price, e)}
																{...ButtonProps}
															>
																{price.unit_amount / 100}
															</Button>
														))} */}
													</Card>
												</Pane>
											);
										})}
									</Pane>
								</>
							)}
						</Pane>
					);
				}}
			</Context.Consumer>
		);
	}
}
