import React, { Component } from "react";
import Context from "../utils/Context";
import { Button, Pane, Paragraph, Table } from "evergreen-ui";
import PageTitleBar from "./PageTitleBar";
import UpgradeCardContainer from "./UpgradeCardContainer";
import firebase from "../utils/firebase";
import FullStory from "../utils/fullstory";

export default class Billing extends Component {
	state = { portalLoading: false };
	viewPortal = async () => {
		try {
			this.setState({ portalLoading: true });
			const functionRef = firebase
				.app()
				.functions("us-central1")
				.httpsCallable("ext-firestore-stripe-payments-createPortalLink");
			const { data } = await functionRef({
				returnUrl: `${window.location.origin}/billing`,
				locale: "auto",
				// configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
			});

			window.location.assign(data.url);
		} catch (error) {
			this.setState({ portalLoading: false });
			return FullStory.log("error", error);
		}
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane width="100%" display="flex" flexDirection="column">
						<PageTitleBar title="Billing" />
						<Pane width="100%" display="flex">
							{!context?.subscriptions ? (
								<Pane>
									<Pane>
										<Paragraph>
											You have not subscribed to a membership
										</Paragraph>
									</Pane>
									<Pane>
										<UpgradeCardContainer />
									</Pane>
								</Pane>
							) : (
								<Table width="100%">
									<Table.Head>
										<Table.TextHeaderCell>Status</Table.TextHeaderCell>
										<Table.TextHeaderCell>
											Current Period Start
										</Table.TextHeaderCell>
										<Table.TextHeaderCell>
											Current Period End
										</Table.TextHeaderCell>
										<Table.TextHeaderCell>Ended At</Table.TextHeaderCell>
										<Table.TextHeaderCell>Portal</Table.TextHeaderCell>
									</Table.Head>
									<Table.Body height={240}>
										{context?.subscriptions.map((subscription, i) => (
											<Table.Row key={`subscription-${i}`}>
												<Table.TextCell>{subscription.status}</Table.TextCell>
												<Table.TextCell isNumber>
													{subscription.current_period_start?.seconds || "-"}
												</Table.TextCell>
												<Table.TextCell isNumber>
													{subscription.current_period_end?.seconds || "-"}
												</Table.TextCell>
												<Table.TextCell isNumber>
													{subscription.ended_at?.seconds || "-"}
												</Table.TextCell>
												<Table.TextCell>
													<Button
														isLoading={this.state.portalLoading}
														onClick={this.viewPortal}
													>
														View
													</Button>
												</Table.TextCell>
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							)}
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
