import React, { Component } from "react";
import { Pane, Text } from "evergreen-ui";
import { PrimaryDark, PrimaryLight } from "../utils/Consts";
import Wordmark from "./Wordmark";
// import Logo from "./Logo";
import WhiteNotbook from "./WhiteNotebook";
import Context from "../utils/Context";
import AuthContainer from "./AuthContainer";

export default class Splash extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane
						className="splash"
						display="flex"
						width="100%"
						flexDirection="row"
						flexGrow="1"
					>
						<Pane
							className="splash-box--left"
							width="50%"
							padding="10px"
							flexGrow="1"
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							backgroundColor={PrimaryLight}
						>
							<WhiteNotbook book={this?.props?.book} />
						</Pane>
						<Pane
							className="splash-box--right"
							width="50%"
							padding="10px"
							flexGrow="1"
							display="flex"
							flexDirection="column"
							justifyContent="center"
							alignItems="flex-start"
							backgroundColor={PrimaryLight}
						>
							{!!this?.props?.book && (
								<Pane marginBottom="10px">
									<Text
										textTransform="uppercase"
										fontWeight="600"
										fontSize="18px"
										lineHeight="144%"
										letterSpacing="0.14em"
										color={PrimaryDark}
									>
										Personality DNA Readers,
									</Text>
								</Pane>
							)}
							<Pane marginBottom="10px">
								<Text
									textTransform="uppercase"
									fontWeight="600"
									fontSize="18px"
									lineHeight="144%"
									letterSpacing="0.14em"
									color={PrimaryDark}
								>
									welcome to
								</Text>
							</Pane>
							<Wordmark brand={"black"} />
							{!!this?.props?.book && (
								<Pane marginBottom="10px">
									<Text
										textTransform="uppercase"
										fontWeight="600"
										fontSize="18px"
										lineHeight="144%"
										letterSpacing="0.14em"
										color={PrimaryDark}
									>
										Register to Begin the Personality DNA Assessment
									</Text>
								</Pane>
							)}
							<Pane marginTop="20px">
								<AuthContainer context={context} />
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
