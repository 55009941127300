import { Card } from "evergreen-ui";
import React, { Component } from "react";

import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
} from "recharts";
import {
	CardProps,
	PrimaryContrast,
	PrimaryContrastLight,
} from "../utils/Consts";
import Context from "../utils/Context";

export default class BarChartCard extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => {
					const leadershipReport = context?.report?.ladderofleadership || {};
					return (
						<Card {...CardProps} maxWidth={"100%"} width="auto" height="100%">
							<ResponsiveContainer width="100%">
								<BarChart
									width="95%"
									height={500}
									data={Object.keys(leadershipReport)
										.sort()
										.map((name) => ({
											name,
											You: leadershipReport[name],
											Average: 50,
										}))}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<Legend
										layout="vertical"
										verticalAlign="bottom"
										align="left"
									/>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip />

									<Bar dataKey="You" fill={PrimaryContrast}>
										<Label
											dataKey="You"
											position="bottom"
											// format="white"
										/>
									</Bar>
									<Bar dataKey="Average" fill={PrimaryContrastLight}>
										<Label
											dataKey="Average"
											position="bottom"
											// format="white"
										/>
									</Bar>
								</BarChart>
							</ResponsiveContainer>
						</Card>
					);
				}}
			</Context.Consumer>
		);
	}
}
