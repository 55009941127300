import React, { Component } from "react";
import Context from "../utils/Context";
import { withRouter } from "react-router-dom";
import Events from "./Events";
import { Pane, Spinner } from "evergreen-ui";
import BeginAssessmentCard from "./BeginAssessmentCard";
import UpgradeCardContainer from "./UpgradeCardContainer";

class EventsContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => {
					return (
						<Pane display="flex" width="100%">
							{!!context?.reportLoading && (
								<Pane
									display="flex"
									width="100%"
									justifyContent="center"
									alignItems="center"
									height="100%"
								>
									<Spinner />
								</Pane>
							)}

							{!context?.report ? (
								<BeginAssessmentCard />
							) : context?.report?.level === 0 ? (
								this.props.premium ? (
									<Pane
										display="flex"
										justifyContent="center"
										alignItems="center"
										width="100%"
									>
										<UpgradeCardContainer />
									</Pane>
								) : (
									<Events
										premium={this.props.premium}
										context={context}
										{...this.props}
									/>
								)
							) : (
								<Events
									premium={this.props.premium}
									context={context}
									{...this.props}
								/>
							)}
						</Pane>
					);
				}}
			</Context.Consumer>
		);
	}
}
export default withRouter(EventsContainer);
