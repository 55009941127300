import React, { Component } from "react";
import Context from "../utils/Context";
import { withRouter } from "react-router-dom";
import Reset from "./Reset";

class ResetContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => <Reset context={context} {...this.props} />}
			</Context.Consumer>
		);
	}
}

export default withRouter(ResetContainer);
