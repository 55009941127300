import React, { Component } from "react";
import { Pane, Card, Text, majorScale, Heading, Paragraph } from "evergreen-ui";
import Context from "../utils/Context";
import PageTitleBar from "./PageTitleBar";
import { PaneProps } from "../utils/Consts";
import Divider from "../lib/divider/Divider";
import LegendTable from "../lib/legendTable/LegendTable";

export default class Result extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => {
					const personalityId = context?.report?.results?.filter(
						(result) => result?.trait === "Primary Personality"
					)[0]?.score;
					let score;
					if (personalityId?.length === 2) {
						score = context?.report?.results?.filter(
							(result) => result?.trait === personalityId
						)[0]?.score;
					} else if (personalityId?.length === 4) {
						const partOne = personalityId?.substring(0, 2) || null;
						const one = context?.report?.results?.filter(
							(result) => result?.trait === partOne
						)[0]?.score;
						const partTwo = personalityId?.substring(2, 4) || null;
						const two = context?.report?.results?.filter(
							(result) => result?.trait === partTwo
						)[0]?.score;
						score = (+one + +two) / 2;
					}
					return (
						<Pane {...PaneProps} display="flex" width="100%">
							{!!context?.report?.level >= 0 ? (
								<React.Fragment>
									<Pane className="result-natural-talents">
										<Heading
											// letterSpacing="0.03em"
											fontFamily='"Jost",sans-serif'
											textAlign="left"
											size={800}
											fontWeight="500"
											marginBottom={majorScale(5)}
											marginTop={majorScale(2)}
										>
											Top Career Roles Your Personality Type Will Have the
											Highest Potential for Success
										</Heading>
										<Paragraph>
											Personality DNA’s benchmark profile represents the level
											of similarity between your personality profile of
											behavioral preferences and the high performer profile, on
											roles selected specifically below. Remember, the
											Personality DNA Benchmarks do not factor in your
											knowledge, skills or experience in this predictive model.
										</Paragraph>
										<Paragraph marginTop={majorScale(2)}>
											Note: see all the career roles in your personality type’s
											chapter of the book
										</Paragraph>
									</Pane>

									<Divider
										style={{
											marginTop: majorScale(7),
											marginBottom: majorScale(7),
										}}
									/>

									<Pane
										className="result-personality-type"
										display="flex"
										width="100%"
										flexDirection="column"
										marginBottom="57px"
									>
										<Pane marginBottom="50px">
											<Pane marginBottom="17px">
												<Heading
													// letterSpacing="0.03em"
													fontFamily='"Jost",sans-serif'
													textAlign="left"
													size={700}
													fontWeight="500"
													marginBottom={majorScale(8)}
												>
													Your personality type score is: {score}
												</Heading>
											</Pane>
											<Paragraph>
												Refer to the chart below to identify your probability
												for success in the occupational roles listed.
											</Paragraph>
										</Pane>

										<LegendTable
											details="Consider the following guidelines for interpreting your 4 Cs level of
										career success based on your score for this personality type. If your
										score was:"
											legend={[
												{
													title: "100 - 70",
													subtitle: "Considerable Talent",
													body: "We predict your performance will consistently Exceed performance expectations and your results can seldom be matched by your peers in these careers",
												},
												{
													title: "69 - 60",
													subtitle: "Competent",
													body: "We predict you will Consistently Meet or Exceed performance expectations if you pursue the careers below",
												},
												{
													title: "59 - 40",
													subtitle: "Capable",
													body: "We predict your performance will Typically Meet Expectations and you will deliver solid results pursuing the careers below",
												},
												{
													title: "39 - 0",
													subtitle: "Cautious",
													body: "We urge you to be cautious pursuing the careers below, for we predict you will have little in common with others successful in these careers",
												},
											]}
										/>
									</Pane>
									<Pane>
										<Heading
											// letterSpacing="0.03em"
											fontFamily='"Jost",sans-serif'
											textAlign="left"
											size={700}
											fontWeight="500"
											marginBottom={majorScale(8)}
										>
											Top Predictors for Career Success
										</Heading>
										<Pane>
											<ul>
												{context?.report?.roles?.map((role, i) => (
													<li key={`${i}-role-name`}>
														<Paragraph marginTop={majorScale(1)} size={500}>
															{role}
														</Paragraph>
													</li>
												))}
											</ul>
										</Pane>
									</Pane>
								</React.Fragment>
							) : (
								<React.Fragment>
									<PageTitleBar title={"Result Missing"} />
									<Card>
										<Text>Assessment Not Found</Text>
									</Card>
								</React.Fragment>
							)}
						</Pane>
					);
				}}
			</Context.Consumer>
		);
	}
}
