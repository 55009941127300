import {
	Badge,
	Heading,
	majorScale,
	Pane,
	Paragraph,
	SideSheet,
	Spinner,
} from "evergreen-ui";
import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import { PaneProps } from "../utils/Consts";
import Context from "../utils/Context";
import PageTitleBar from "./PageTitleBar";
import firebase from "../utils/firebase";
// import MultiSelect from "../lib/multiselect/MultiSelect";
// import Button from "../lib/button/Button";
import EventCard from "../lib/eventCard/EventCard";
import Logo from "./Logo";
// import { Personalities } from "../utils/Consts";
import EventRegistration from "./EventRegistration";
import Alert from "../lib/alert/Alert";
import FullStory from "../utils/fullstory";

export default function Events(props) {
	const [events, setEvents] = useState([]);
	const [event, setEvent] = useState();
	const [eventsLoading, setEventsLoading] = useState(false);
	const [
		tags,
		// setTags
	] = useState([]);
	const context = useContext(Context);
	const [sideSheetIsShown, setSideSheetIsShown] = useState(false);

	useEffect(
		() => {
			getEvents();
		}, // eslint-disable-next-line
		[]
	);

	const filter = async (events) => {
		try {
			const premium = props.premium;
			const filteredEvents = !!premium
				? events
				: events.filter((event) => event.level === 0);
			return filteredEvents;
		} catch (error) {}
	};

	const getEvents = async () => {
		try {
			setEventsLoading(true);
			let now = new Date();
			const eventsSnapshot = await firebase
				.firestore()
				.collection("events")
				.where("start", ">", now)
				.where("published", "==", true)
				.limit(10)
				.get();
			const eventsDocs = eventsSnapshot.docs;
			let events = eventsDocs.map((doc) => ({ ...doc.data(), id: doc.id }));
			events = await filter(events);
			setEvents(events);
			setEventsLoading(false);
		} catch (error) {
			console.log(error);
			FullStory.log("error", error);
			return context?.toaster?.danger(
				"There was an error retrieving events. Please try again later."
			);
		}
	};

	// const search = async () => {
	// 	try {
	// 		setEvents([]);
	// 		setEventsLoading(true);
	// 		let now = new Date();
	// 		let filteredEventsSnapshot;
	// 		if (tags.length > 0) {
	// 			filteredEventsSnapshot = await firebase
	// 				.firestore()
	// 				.collection("events")
	// 				.where("tags", "array-contains-any", tags)
	// 				.where("start", ">", now)
	// 				.where("published", "==", true)
	// 				.get();
	// 		} else {
	// 			filteredEventsSnapshot = await firebase
	// 				.firestore()
	// 				.collection("events")
	// 				.where("start", ">", now)
	// 				.where("published", "==", true)
	// 				.get();
	// 		}
	// 		const filteredEventsDocs = filteredEventsSnapshot?.docs;
	// 		let filteredEvents = filteredEventsDocs.map((doc) => ({
	// 			...doc.data(),
	// 			id: doc.id,
	// 		}));
	// 		filteredEvents = await filter(filteredEvents);

	// 		setEvents(filteredEvents);
	// 		setEventsLoading(false);
	// 	} catch (error) {
	// 		setEventsLoading(false);
	// 		FullStory.log("error", error);
	// 		return context?.toaster?.danger(
	// 			"There was an error filtering events. Please try again later."
	// 		);
	// 	}
	// };

	const viewEvent = (selectedEvent) => {
		setSideSheetIsShown(true);
		setEvent(selectedEvent);
	};

	// const setTagsHandler = (tag) => {
	// 	if (tag?.length > 10) {
	// 		return context?.toaster?.notify(
	// 			"Maximum number (10) of event types selected. Please de-select an event type to continue."
	// 		);
	// 	}
	// 	setTags(tag);
	// };

	return (
		// <Context.Consumer>
		// {(context) => (
		<Pane {...PaneProps} display="flex" width="100%">
			<SideSheet
				isShown={sideSheetIsShown}
				onCloseComplete={() => {
					setSideSheetIsShown(false);
					setEvent(null);
				}}
				preventBodyScrolling
			>
				<Pane padding={majorScale(8)}>
					<Pane marginBottom={majorScale(5)}>
						<Logo width="60px" />
					</Pane>
					<Pane marginBottom={majorScale(5)} position="relative">
						{event?.level > 0 && (
							<Badge top="-20px" left="0px" position="absolute" color="green">
								Premium
							</Badge>
						)}
						<Heading size={800}>{event?.title}</Heading>
					</Pane>
					<EventRegistration event={event} />
					<Pane>
						<Paragraph
							// whiteSpace="pre-line"
							dangerouslySetInnerHTML={{
								__html: event?.description?.replace(
									/\/[a-zA-Z]/g,
									"<br/><br/>"
								),
							}}
						/>
					</Pane>
				</Pane>
			</SideSheet>
			<Pane>
				<Breadcrumbs crumbs={[{ text: "Events", link: "/events" }]} />
				<PageTitleBar title="Events & Workshops" />
			</Pane>
			{/* <Pane display="flex" flexDirection="row">
						<MultiSelect
							buttonTitle="Event Types"
							options={Object.keys(Personalities)
								.map((key) => Personalities[key]?.name)
								.map((label) => ({
									label: label[0].toUpperCase() + label?.slice(1).toLowerCase(),
									value: label.toLowerCase(),
								}))}
							select={(selectedItemsState) =>
								setTagsHandler(selectedItemsState)
							}
						/>

						<Button
							marginLeft={majorScale(3)}
							primary={tags?.length > 0 ? +true : +false}
							onClick={search}
							disabled={tags.length > 10}
						>
							Search
						</Button>
					</Pane> */}
			{tags.length > 10 && (
				<Pane marginTop={majorScale(1)}>
					<Alert intent="danger" title="Too many event types selected.">
						Please select a maximum of 10 event types. A maximum of 10 event
						types can be selected for searching.
					</Alert>
				</Pane>
			)}
			<Pane marginTop={majorScale(5)}>
				{eventsLoading && <Spinner />}
				{!eventsLoading && events?.length === 0 ? (
					<Pane>
						<Paragraph>
							Looks like there are no upcoming events at the moment. Please
							check back soon.
						</Paragraph>
					</Pane>
				) : (
					<Pane>
						{events?.map((eventDetails, i) => (
							<EventCard
								key={`event-card-${i}`}
								event={eventDetails}
								viewEvent={viewEvent}
								name={event}
							/>
						))}
					</Pane>
				)}
			</Pane>
		</Pane>
		// )}
		// </Context.Consumer>
	);
	// }
}
