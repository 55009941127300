import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";

const firebaseConfig = {
	apiKey: "AIzaSyAXfrHU4P7E1UxbaAsyPZvc-xNjgEUj544",
	authDomain: "leadersed.firebaseapp.com",
	databaseURL: "https://leadersed.firebaseio.com",
	projectId: "leadersed",
	storageBucket: "leadersed.appspot.com",
	messagingSenderId: "207102322754",
	appId: "1:207102322754:web:b95efd62d921e3ec2625db",
	measurementId: "G-FZ354TCJ5F",
};

firebase.initializeApp(firebaseConfig);

// firebase.functions().useEmulator("localhost", 5001);

firebase.performance();
firebase.analytics();
export default firebase;
