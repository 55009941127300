import firebase from "./firebase";
import { majorScale } from "evergreen-ui";
import { GoogleCalendar, ICalendar, OutlookCalendar } from "datebook";
// import * as FullStory from "@fullstory/browser";
import FullStory from "./fullstory";

// import sections from "../utils/sections.json";
// import questions from "../utils/questions.json";

export const Title = "LeadersEd";

export const Personalities = {
	"01": {
		name: "Capitalist",
		chapter: "3",
		link: "",
		definition: `“Our enterprise leaders” -They are the one who focus their priorities and talents toward maximizing their capacity and desire to develop, organize and manage business affairs, along with all its challenges and risks. They above all else seek financial opportunities to forever grow their enterprises.`,
	},
	"02": {
		name: "Altruist",
		chapter: "4",
		link: "",
		definition: `“Unselfish concern for the welfare of others” – They are people who have a gentle nature and are more concerned for the welfare of others than even themselves. They are focusing both on the single people as well as the whole community and society in which they live and work.`,
	},
	"03": {
		name: "Imaginative",
		chapter: "7",
		link: "",
		definition: `“Seek innovative solutions” - They possess a creative mind and with their sensitivity are drawn toward imagining and expressing tangible artistic expressions. Creativity is a central source of meaning in their nature and they are remarkable for their capacity for a unique perspective.`,
	},
	"04": {
		name: "Inventive",
		chapter: "12",
		link: "",
		definition: `“Produce priorities of relevance” – They are the realist with a well-grounded sense of reality and above all else want to omit useless and unimportant details for the sake of achieving the more important first things up-front, through the most obvious path that reaches their goals.`,
	},
	"05": {
		name: "Process-Driven",
		chapter: "14",
		link: "",
		definition: `“Seek to organize us” – They encourage us to “play well together” believing that when we have clearly prescribed protocols our life together is easier. They are the ones who establish the game plan, rules, procedures, and systems, believing if we know what is expected we end up co-operating in good faith.`,
	},
	"06": {
		name: "Results-Driven",
		chapter: "10",
		link: "",
		definition: ` “Our expedient leaders”- They  create positive momentum, and keep moving forward, believing that results are the only thing that matters. A multi-tasker, they want to know what they need to do now to get tasks done efficiently, on time and efficiently. To them there is no other way. `,
	},
	"07": {
		name: "Insightful",
		chapter: "5",
		link: "",
		definition: `“Seeks big idea solutions.” - They focus “within the self” — so, quintessentially introspective as they seek a deeper understanding of the world around them. They are naturally deep thinkers and are drawn to big problems requiring thoughtful, thorough reflection. They want us all to have a better world to live in.`,
	},
	"08": {
		name: "Social",
		chapter: "8",
		link: "",
		definition: `“Seek to make us all feel important”: They thrive in social interactions, are inclusive of others and believe the more the merrier. They are gifted at drawing attention to themself while exuding genuine social enthusiasm, a sense of excitement and encouraging lively discussions.`,
	},
	"09": {
		name: "Analytic",
		chapter: "6",
		link: "",
		definition: `“Seek scientific solutions” – They are usually the ones we refer to as the logical and systematic problem solvers who mathematically analyze data faster than most of us. Their personality lend itself to be seen by others as having the analytic skills to solve the impossible problems.`,
	},
	10: {
		name: "Holistic",
		chapter: "9",
		link: "",
		definition: `“Our natural Leader personality” - They remain directed both inwards and somewhere beyond into the future – always questioning, wondering, and making connections. They are an inherently wise, innovative, and insightful in the way they view their job, duties, and world around them. `,
	},
	11: {
		name: "Responsible",
		chapter: "13",
		link: "",
		definition: `“Produce quality and excellence” - They accept that life presents challenges and responsibility as they carefully carry out their priorities. They strive for excellence in all they undertake while paying close attention to every detail that really matters, delight in hard work, and above all else are dependable.`,
	},
	12: {
		name: "Individualist",
		chapter: "11",
		link: "",
		definition: `“Free-spirit and social rebels” - pave their own path and walk to the beat of their own drum, resisting any unnecessary attempt to be controlled or restricted.  While valuing emotional freedom above everything else they show independence in their opinions, actions and are the advocates of individualism. `,
	},
};

export const Elevation = 0;

// export const BackgroundDark = "rgba(234,238,254,1)";
// export const CardBackground = "rgba(255,255,255,1)";

// export const BackgroundLight = "rgba(247,250,255,1)";
// export const PrimaryColor = "rgba(108,77,246,1)";
// export const PrimaryLightColor = "rgba(131,132,204,1)";
// export const SecondaryColor = "rgba(252,104,120,1)";
// export const TertiaryColor = "rgba(253,278,116,1)";

// export const BackgroundLight = "#EEEEEE";
// export const BackgroundLight = "#E5E5E5";
// export const PrimaryColor = "#111";
// export const PrimaryLightColor = "#6768CC";
// export const SecondaryColor = "rgba(252,104,120,1)";
// export const TertiaryColor = "rgba(253,278,116,1)";

//LIBRARY
export const PrimaryDark = "#000000";
export const PrimaryDarkAlt = "#4E4E4E"; //used for hover states in buttons
export const SecondaryDark = "#111111";
export const PrimaryContrast = "#6768CC"; //main purple
export const PrimaryContrastLight = "#A1A2F8"; //main purple, lightened
export const PrimaryGray = "#BCBCBC";
export const PrimaryLight = "#EEEEEE";
export const SecondaryLight = "#F2F2F2";
export const White = "#FFFFFF";

export const PaletteBlue = "#5C97BF";
export const PaletteBlueOpaque = "rgba(92, 151, 191, 0.19)";
export const PaletteTeal = "#00A4A6";
export const PaletteRed = "#F64747";
export const PaletteGreen = "#00AA55";
export const PaletteGreenOpaque = "rgba(0, 170, 85, 0.12)";
export const PaletteGold = "#FFC370";
export const PaletteGoldOpaque = "rgba(255, 195, 112, 0.16)";
export const PaletteOrange = "#E65722";
export const PaletteOrangeOpaque = "rgba(230, 87, 34, 0.15)";

export const Palette = [
	PaletteBlue,
	PaletteTeal,
	PaletteRed,
	PaletteGreen,
	PaletteGold,
	PaletteOrange,
];

export const Background = "rgba(239,242,253,1)";
export const PaneBackground = "rgba(255,255,255,1)";
export const CardBackground = "rgba(255,255,255,1)";
export const PaneBorderRadius = 12;
export const CardBorderRadius = 5;
export const CardElevation = 0;
export const PaneElevation = 0;
export const PaneProps = {
	display: "flex",
	flexDirection: "column",
	paddingTop: majorScale(2),
	paddingRight: majorScale(2),
	paddingBottom: majorScale(2),
	paddingLeft: majorScale(2),
	// borderRadius: PaneBorderRadius,
	boxShadow: "none",
	elevation: PaneElevation,
};

export const MiniCardProps = {
	minHeight: "326px",
	background: White,
	boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.1)",
	borderRadius: CardBorderRadius,

	width: "168px",

	display: "flex",
	flexDirection: "column",
	paddingTop: majorScale(4),
	paddingRight: majorScale(3),
	paddingBottom: majorScale(4),
	paddingLeft: majorScale(3),
};

export const NaturalTalentCardProps = {
	minHeight: "207px",
	background: White,
	boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.1)",
	borderRadius: CardBorderRadius,

	width: "100%",

	display: "flex",
	flexDirection: "column",
	paddingTop: majorScale(5),
	paddingRight: majorScale(4),
	paddingBottom: majorScale(5),
	paddingLeft: majorScale(4),
};

export const CardProps = {
	// minHeight: "326px",
	height: "fit-content",
	background: White,
	boxShadow: "0px 0px 15px 1px rgba(0, 0, 0, 0.1)",
	borderRadius: CardBorderRadius,

	minWidth: "300px",
	// width: "100%",
	maxWidth: "449px",
	width: "fit-content",
	// overflow: "auto",

	display: "flex",
	flexDirection: "column",
	paddingTop: majorScale(7),
	paddingRight: majorScale(7),
	paddingBottom: majorScale(9),
	paddingLeft: majorScale(7),
	// flexWrap: "wrap",
};

export const ButtonProps = {
	// backgroundImage: `linear-gradient(to bottom, ${PrimaryLightColor},${PrimaryColor})!important`,
	backgroundImage: `none!important`,
	// backgroundColor: `${PrimaryLight}!important`,
	// color: "white!important",
	// borderRadius: "2px!important",
	maxWidth: "fit-content",
};

export const TextProps = {
	size: 300,
};

export const Login = async (email, password) =>
	firebase.auth().signInWithEmailAndPassword(email, password);

export const Logout = async () => firebase.auth().signOut();

export const Register = async (email, password) =>
	firebase.auth().createUserWithEmailAndPassword(email, password);

export const StripePublishableKey =
	"pk_test_51Hv60oGL1A2200QpG1dYX7D9rvCzv4A30EDCFtyWL4kL6QFJAFTdOsAVaG65IuaCco6T27fFMbBh9w3fWriUspI500xt6WR19D";

export const UpdateDB = async (path, document) =>
	firebase.firestore().doc(path).set(document, { merge: true });

// export const UpdateAuthEmail = (user, email) => user.updateEmail(email);

// export const Decrypt = async (hash) => {
// 	try {
// 		const decrypt = firebase.functions().httpsCallable("decrypt");
// 		const decrypted = await decrypt({
// 			cipher: hash.cipher,
// 			iv: hash.iv,
// 		});
// 		const message = decrypted?.data?.str;
// 		return message;
// 	} catch (error) {
// 		return error;
// 	}
// };

export const VerifyEmailHandler = async (params = {}) => {
	try {
		const { mode, oobCode, continueUrl } = params;
		if (mode !== "verifyEmail") {
			throw Error("Incorrect mode. Please try again");
		} // resetPassword, recoverEmail, or verifyEmail
		await firebase.auth().applyActionCode(oobCode);
		return continueUrl;
	} catch (error) {
		throw error;
	}
};

export const ResetPasswordHandler = async (params = {}) => {
	try {
		const { mode, oobCode, continueUrl, newPass } = params;
		if (mode !== "resetPassword") {
			throw Error("Incorrect mode. Please try again");
		} // resetPassword, recoverEmail, or verifyEmail
		await firebase.auth().confirmPasswordReset(oobCode, newPass);
		return continueUrl;
	} catch (error) {
		throw error;
	}
};

//util function to retrieve products for purchase
// export const GetProducts = async () => {
// 	try {
// 		let products = [];
// 		const productsSnapshot = await firebase
// 			.firestore()
// 			.collection("products")
// 			.where("active", "==", true)
// 			.get();
// 		if (productsSnapshot.empty) return products;
// 		const docs = productsSnapshot.docs?.map(
// 			(doc) =>
// 				await doc.ref
// 					.collection("prices")
// 					.get()
// 					.then((price) => ({ ...doc.data(), price: price.data() }))
// 		);
// 		const documents = docs.map((doc) => {
// 			const priceSnap = doc.ref.collection("prices").get();
// 			return { ...doc.data(), prices: priceSnap };
// 		});
// 		return documents;
// 		productsSnapshot.forEach(async (doc) => {
// 			try {
// 				const priceSnap = await doc.ref.collection("prices").get();
// 				products.push({
// 					...doc.data(),
// 					prices: priceSnap.docs.map((doc) => {
// 						return { ...doc.data(), id: doc.id };
// 					}),
// 				});
// 				return products;
// 			} catch (error) {
// 				FullStory.log("error",error)
// 			}
// 		});
// 		return products;
// 	} catch (error) {
// 		throw error;
// 	}
// };

// export const CreatePersonalityDNAReportPDF = async(data = {}) => {
// try {
// 	const {report,email} = data;

// } catch (error) {
// 	throw error;
// }
// }

export const CurrentFunctions = [
	"Accounting",
	"Administrative",
	"Consultant",
	"Customer Service",
	"Educator",
	"Engineer",
	"Entrepreneur",
	"Finance",
	"Graphic Design",
	"Healthcare Services",
	"Human Resources",
	"Information Technology",
	"Legal",
	"Marketing",
	"Media & Communications",
	"Military & Protective Services",
	"Operations",
	"Production",
	"Product Management",
	"Program Management",
	"Public Relations",
	"Purchasing",
	"Quality Assurance/Control",
	"Research & Development",
	"Sales",
	"Service",
	"Social Worker",
	"Software Developer",
	"Strategy",
	"Talent Aquistion",
];

export const CurrentIndustry = [
	"Airlines/Aviation",
	"Apparel & Fashion",
	"Architecture & Planning",
	"Automotive",
	"Aviation & Aerospace Manufacturing",
	"Banking",
	"Biotechnology",
	"Broadcast Media",
	"Building Materials",
	"Business Supplies and Equipment",
	"Capital Markets",
	"Chemicals",
	"Commercial Real Estate",
	"Computer & Network Security",
	"Computer Games",
	"Computer Hardware",
	"Computer Networking",
	"Computer Software",
	"Construction",
	"Consumer Goods",
	"Consumer Services",
	"Cosmetics",
	"Defense & Space",
	"Education",
	"Engineering",
	"Entertainment",
	"Environmental Services",
	"Events Services",
	"Executive Search",
	"Facilities Services",
	"Farming",
	"Financial Services",
	"Food & Beverages",
	"Food Production",
	"Gambling & Casinos",
	"Government Administration",
	"Government Relations",
	"Health, Wellness and Fitness",
	"Higher Education",
	"Hospital & Health Care",
	"Hospitality",
	"Individual & Family Services",
	"Industrial Automation",
	"Information Technology and Services",
	"Insurance",
	"International Affairs",
	"International Trade and Development",
	"Investment Banking",
	"Law Enforcement",
	"Law Practice",
	"Legal Services",
	"Leisure, Travel & Tourism",
	"Libraries",
	"Logistics and Supply Chain",
	"Luxury Goods & Jewelry",
	"Machinery",
	"Management Consulting",
	"Market Research",
	"Marketing and Advertising",
	"Media Production",
	"Medical Devices",
	"Medical Practice",
	"Mental Health Care",
	"Military",
	"Mining & Metals",
	"Motion Pictures and Film",
	"Museums and Institutions",
	"Music",
	"Non-Profit Organization Management",
	"Oil & Energy",
	"Online Media",
	"Paper & Forest Products",
	"Performing Arts",
	"Pharmaceuticals",
	"Photography",
	"Plastics",
	"Printing",
	"Professional Training & Coaching",
	"Program Development",
	"Public Relations and Communications",
	"Public Safety",
	"Publishing",
	"Railroad Manufacture",
	"Real Estate",
	"Recreational Facilities and Services",
	"Religious Institutions",
	"Renewables & Environment",
	"Research",
	"Restaurants",
	"Retail",
	"Security and Investigations",
	"Social Work",
	"Sporting Goods",
	"Sports",
	"Staffing and Recruiting",
	"Telecommunications",
	"Textiles",
	"Tobacco",
	"Translation and Localization",
	"Transportation/Trucking/Railroad",
	"Utilities",
	"Venture Capital & Private Equity",
	"Veterinary",
	"Warehousing",
	"Wine and Spirits",
	"Wireless",
];

export const CurrentLevel = [
	"Entry Level",
	"Individual Contributor",
	"Team Leader",
	"Supervisor",
	"Manager",
	"Senior Manager",
	"Director",
	"Senior Director",
	"Associate Vice President",
	"Vice President",
	"Senior Vice President",
	"C-Suite",
];

export const ProfessionalOpportunities = [
	"Permanent Full-Time",
	"Interim Full-Time",
	"Interim Part-Time",
	"Contract",
	"Consulting",
];

// export const Questions = Object.keys(questions).reduce(
// 	(attrs, key) => ({ ...attrs, [key]: null }),
// 	{}
// );
// export const Sections = Object.keys(sections).reduce(
// 	(attrs, key) => ({ ...attrs, [key]: null }),
// 	{}
// );

export const CreateCalEvent = async (
	config = {
		type: "",
		title: "",
		location: "",
		description: "",
		start: new Date(),
		end: new Date(),
		attendees: [],
		recurrence: {
			frequency: "", //DAILY, WEEKLY, MONTHLY, or YEARLY
			interval: null, //any positive integer
		},
	}
) => {
	switch (config.type) {
		case "ical":
			return new ICalendar(config).download();

		case "google":
			// window.location.href = new GoogleCalendar(config).render();
			window.open(new GoogleCalendar(config).render());
			break;
		case "outlooklive":
			window.open(new OutlookCalendar(config).setHost("live").render());
			// window.location.href = new OutlookCalendar(config)
			// 	.setHost("live")
			// 	.render();
			break;
		case "outlookoffice":
			// window.location.href = new OutlookCalendar(config)
			// 	.setHost("office")
			// 	.render();
			window.open(new OutlookCalendar(config).setHost("office").render());
			break;
		default:
			return new ICalendar(config).download();
	}
};

export const SetFullStoryUserVars = async (data = {}) => {
	// return await FullStory.event(event, data);
	return await FullStory.setUserVars(data);
};
