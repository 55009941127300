import { Badge, majorScale, Pane, Paragraph } from "evergreen-ui";
import { Card } from "evergreen-ui";
import React from "react";
import Moment from "react-moment";
import Logo from "../../components/Logo";
import { CardProps, PrimaryLight } from "../../utils/Consts";
import Button from "../button/Button";

export default function EventCard(props) {
	const clickHandler = () => props?.viewEvent(props?.event);

	return (
		<Card
			{...CardProps}
			maxWidth="780px"
			width="100%"
			display="flex"
			flexDirection="row"
			padding="0px"
			height="180px"
			marginBottom={majorScale(5)}
		>
			<Pane
				width="180px"
				height="180px"
				display="flex"
				justifyContent="center"
				alignItems="center"
				background={props?.event?.logo || PrimaryLight}
				borderTopLeftRadius="5px"
				borderBottomLeftRadius="5px"
				position="relative"
			>
				{props?.event?.level > 0 && (
					<Badge top="7px" left="7px" position="absolute" color="green">
						Premium
					</Badge>
				)}
				<Logo width="75px" />
			</Pane>

			<Pane
				display="flex"
				flexDirection="column"
				padding={majorScale(3)}
				justifyContent="space-between"
			>
				<Pane>
					<Paragraph>
						<Moment format="dddd MMM D, YYYY | HH:mm A">
							{props?.event?.start?.toMillis()}
						</Moment>
						{" - "}
						<Moment format="HH:mm A">{props?.event?.end?.toMillis()}</Moment>
					</Paragraph>
				</Pane>
				<Pane>{props?.event?.title || "Title Missing"}</Pane>
				<Pane>
					<Button onClick={clickHandler}>View Event</Button>
				</Pane>
			</Pane>
		</Card>
	);
}
