import React, { Component } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Card, Heading, Pane, Paragraph } from "evergreen-ui";
import firebase from "../utils/firebase";
import { CardProps, StripePublishableKey } from "../utils/Consts";
import Context from "../utils/Context";
import BeginAssessmentCard from "./BeginAssessmentCard";

export default class Checkout extends Component {
	state = {};

	purchase = async () => {
		try {
			this.setState({ loading: true });

			const stripe = await loadStripe(StripePublishableKey);
			const sessionData = await this.getSessionData(stripe);
			const session = sessionData?.session;
			const hash = sessionData.hash || {};

			this.setState({
				hash,
				session,
			});

			await this.redirectToCheckout(stripe, session);
		} catch (error) {
			this.props.context.toaster.danger("There was an error with the purchase");
			this.setState({ loading: false });
		}
	};

	redirectToCheckout = async (stripe, session) => {
		try {
			// When the customer clicks on the button, redirect them to Checkout.
			const result = await stripe.redirectToCheckout({
				sessionId: session.id,
				customerEmail: this?.props?.context?.auth?.email,
			});

			if (result.error) {
				this.props.context.toaster.danger(result?.error?.message);
			}
		} catch (error) {
			this.props.context.toaster.danger("There was an error with the purchase");
		}
	};

	getSessionData = async (stripe) => {
		try {
			// Call your backend to create the Checkout Session
			// const response = await fetch('/create-checkout-session', { method: 'POST' });
			const createCheckoutSession = firebase
				.functions()
				.httpsCallable("createCheckoutSession");

			const sessionObj = await createCheckoutSession({
				email: this.props?.context?.auth.email,
				uid: this.props?.context?.auth?.uid,
			});

			const data = sessionObj?.data;
			return data;
		} catch (error) {
			this.props.context.toaster.danger("There was an error with the purchase");
		}
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane>
						{context?.report?.level > 0 ? (
							<BeginAssessmentCard />
						) : (
							<Card {...CardProps}>
								<Heading>Unlock Full Report</Heading>
								<Paragraph>Upgrade today to unlock the Full Report</Paragraph>
								<Button
									role="link"
									onClick={this.purchase}
									appearance="primary"
									isLoading={this.state.loading}
									disabled={this.state.loading || context?.report?.level >= 1}
								>
									{this.state.loading ? "Purchasing..." : "Purchase $39.99"}
								</Button>
							</Card>
						)}
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
