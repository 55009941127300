import React, { Component } from "react";
import Context from "./utils/Context";

import firebase from "./utils/firebase";
import { Overlay, Spinner, ThemeProvider } from "evergreen-ui";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import "./App.css";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Main from "./components/Main";

import { Pane, toaster, majorScale } from "evergreen-ui";

import {
	PaneProps,
	PrimaryContrast,
	PrimaryLight,
	SetFullStoryUserVars,
} from "./utils/Consts";
import Theme from "./utils/Theme";
import Footer from "./components/Footer";
import ScrollToTop from "./utils/ScrollToTop";
import ReactJoyride from "react-joyride";
import FullStory from "./utils/fullstory";

const initState = {
	auth: null,
	uid: null,
	user: null,
	user_email: null,
	customClaims: null,
	listeners: [],
	subscriptions: [],
	products: [],
	overlay: false,
};

export default class App extends Component {
	update = (key, val) => this.setState({ [key]: val });

	state = {
		update: this.update,
		toaster,
		...initState,
		authLoading: true,
		userLoading: true,
		reportLoading: true,
		productsLoading: true,
		steps: [],
	};

	registerListener = async (listener) => {
		const { listeners } = this.state;
		listeners.push(listener);
		this.setState({ listeners });
	};

	componentDidMount() {
		this.auth();
		window.$crisp = [];
		window.CRISP_WEBSITE_ID = "63ac5ea3-bb8e-42d9-8d7b-ee5990700331";
		(function () {
			const d = document;
			const s = d.createElement("script");
			s.src = "https://client.crisp.chat/l.js";
			s.async = 1;
			d.getElementsByTagName("head")[0].appendChild(s);
		})();
	}

	componentWillUnmount() {
		this.unregisterListeners();
	}

	unregisterListeners = () => {
		const { listeners } = this.state;
		listeners.forEach((unsubListener) => unsubListener());
	};

	auth = () => {
		firebase.auth().onAuthStateChanged(async (authUser) => {
			try {
				if (authUser) {
					this.getUser(authUser.uid);
					this.getReports(authUser.uid);
					this.setAdmin();
					this.getProducts();
					this.setState({
						auth: authUser,
						uid: authUser.uid,
						user_email: authUser.email,
						authLoading: false,
					});
					await SetFullStoryUserVars({
						uid: authUser.uid,
						email: authUser.email,
						emailVerified: authUser.emailVerified,
					});
				} else {
					this.unregisterListeners();
					this.setState({
						...initState,
						authLoading: false,
						userLoading: false,
						reportLoading: false,
						report: {},
					});
				}
			} catch (error) {
				this.state.toaster.danger(
					"There was an error authenticating. Please try connecting again later."
				);
			}
		});
	};

	getProducts = async () => {
		try {
			const productsSnapshot = await firebase
				.firestore()
				.collection("products")
				.where("active", "==", true)
				.get();
			if (productsSnapshot.empty) return; //TODO make empty state of no products
			let products = [];

			productsSnapshot.forEach(async (doc) => {
				try {
					const priceSnap = await doc.ref.collection("prices").get();
					products.push({
						...doc.data(),
						prices: priceSnap.docs.map((doc) => {
							return { ...doc.data(), id: doc.id };
						}),
					});
					this.setState({ products, productsLoading: false });
				} catch (error) {}
			});
		} catch (error) {
			FullStory.log("error", error);
			this.setState({ products: [], productsLoading: false });
		}
	};

	setAdmin = async () =>
		firebase
			.auth()
			.currentUser.getIdTokenResult()
			.then((idTokenResult) => {
				this.setState({ customClaims: idTokenResult.claims });
			});

	getUser = async (uid) => {
		try {
			const listener = await firebase
				.firestore()
				.collection("users")
				.doc(uid)
				.onSnapshot((doc) => {
					const user = doc.data();
					if (
						!!user &&
						!!user.stripeId &&
						!!user?.stripeId?.length > 0 &&
						user?.uid?.length > 0
					) {
						this.getSubscriptions(user?.uid);
					}
					this.setState({ user, userLoading: false });
				});
			await this.registerListener(listener);
		} catch (error) {
			this.setState({ userLoading: false, user: null });
			this.state.toaster.danger(
				"There was an error getting user details. Please try connecting again later."
			);
		}
	};

	getSubscriptions = async (uid) => {
		try {
			const subscriptionQuery = await firebase
				.firestore()
				.collection("users")
				.doc(uid)
				.collection("subscriptions")
				.get();
			const subscriptionsDocs = subscriptionQuery.docs;
			const subscriptions =
				subscriptionsDocs.length === 0
					? []
					: subscriptionsDocs.map((doc) => doc.data());
			this.setState({ subscriptions });
		} catch (error) {
			FullStory.log("error", error);
			this.setState({ subscriptions: [] });
			this.state.toaster.danger(
				"There was an error getting subscriptions. Please try connecting again later."
			);
		}
	};

	getReports = async (uid) => {
		try {
			const listener = await firebase
				.firestore()
				.collection("reports")
				.doc(uid)
				.onSnapshot((doc) => {
					this.setState({ report: doc.data(), reportLoading: false });
				});
			await this.registerListener(listener);
		} catch (error) {
			this.setState({ reportLoading: false, report: null });
			this.state.toaster.danger(
				"There was an error getting user details. Please try connecting again later."
			);
		}
	};

	render() {
		return (
			<ThemeProvider value={Theme}>
				<Context.Provider value={this.state}>
					<Router>
						<ReactJoyride
							steps={this.state.steps}
							// beaconComponent={<Pulsar />}
							// tooltipComponent={<Tooltip />}
						/>
						<ScrollToTop>
							<Switch>
								<React.Fragment>
									<Overlay
										isShown={this.state.overlay}
										shouldCloseOnClick={false}
										shouldCloseOnEscapePress={false}
										preventBodyScrolling={true}
									>
										<Spinner />
									</Overlay>
									<Pane
										className="app"
										background={PrimaryLight}
										display="flex"
										flexDirection="row"
										justifyContent="space-between"
									>
										{!!this.state.auth && !this.state.authLoading && (
											<Pane
												{...PaneProps}
												display="flex"
												paddingRight={0}
												paddingLeft={0}
												paddingTop={0}
												paddingBottom={0}
												height="100vh"
												background={PrimaryContrast}
												overflow="scroll"
												width="170px"
												className="sidebar-container"
												id="sidebar-container"
												// position="absolute"
											>
												<Sidebar />
											</Pane>
										)}

										<Pane
											{...PaneProps}
											paddingLeft={majorScale(0)}
											paddingTop={0}
											paddingRight={0}
											paddingBottom={0}
											className="main-container"
											flexGrow={2}
											minHeight="100vh"
											width="70%"
											// overflow="scroll"
											borderRadius={0}
										>
											<Header />
											<Main />
											{!this.state.auth && !this.state.authLoading && (
												<Footer />
											)}
										</Pane>
									</Pane>
								</React.Fragment>
							</Switch>
						</ScrollToTop>
					</Router>
				</Context.Provider>
			</ThemeProvider>
		);
	}
}
