import React, { Component } from "react";
import { Pane, Button } from "evergreen-ui";
import { Logout as logout } from "../utils/Consts";

export default class Logout extends Component {
	componentDidMount() {
		logout();
	}

	render() {
		return (
			<Pane>
				<Button>Logged Out</Button>
			</Pane>
		);
	}
}
