import { Heading, majorScale, Pane, Text } from "evergreen-ui";
import React, { Component } from "react";
import { Card } from "evergreen-ui";
import { PaletteTeal, White } from "../utils/Consts";
import { Link } from "react-router-dom";
import Button from "../lib/button/Button";

export default class UpgradeCard extends Component {
	render() {
		return (
			<Card
				backgroundColor={PaletteTeal}
				width="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
				textAlign="center"
				flexDirection="column"
				padding="30px"
				color={White}
			>
				<Pane width="100%" maxWidth={majorScale(100)}>
					<Heading
						fontFamily='"Jost",sans-serif'
						textAlign="center"
						size={600}
						fontWeight="500"
						marginBottom={majorScale(2)}
						// fontWeight={700} size={500}
						color={White}
						marginTop={majorScale(2)}
					>
						Go Premium
					</Heading>
					<Pane>
						<Text color={White} textAlign="center" marginTop="15px">
							Upgrade to Premium to get your personalized, in-depth Premium
							reports including a Leadership Report, Manager/Director Report,
							Executive/VP Report, and C-Suite Report. You will also receive a
							copy of the Personality DNA book, containing in-depth insights
							about each Personality, including yours. You will also have access
							to Personality DNA webinars led by Dr. Carlos Davidovich and other
							career webinars and content delivered by experts in the field.
						</Text>
					</Pane>
					<Pane marginTop="15px">
						<Text color={White} textAlign="center">
							Additional Premium benefits are being added all the time so join
							us while our Introductory Pricing is available!
						</Text>
					</Pane>
					<Pane marginTop="15px">
						<Text color={White} textAlign="center">
							Not ready to upgrade? Explore your Personality DNA by getting your
							copy of the book.
						</Text>
					</Pane>
					{/* <Button onClick={this.upgrade} marginTop={majorScale(4)} border="none">
					Upgrade
				</Button>
				<Button marginTop={majorScale(4)} border="none">
					<Link to="/checkout">Check Out</Link>
				</Button> */}
					<Pane display="flex" justifyContent="center" alignItems="center">
						<Button marginTop={majorScale(4)} border="none">
							<Link to="/upgrade">Learn More</Link>
						</Button>
					</Pane>
				</Pane>
			</Card>
		);
	}
}
