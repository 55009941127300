import React from "react";
import { majorScale, Pane, Paragraph } from "evergreen-ui";
import Button from "../button/Button";
import { PrimaryContrast, PrimaryGray, White } from "../../utils/Consts";

function Question(props) {
	return (
		<Pane
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
			marginTop={props?.marginTop || majorScale(1)}
			marginBottom={props?.marginBottom || majorScale(1)}
		>
			<Pane>
				<Paragraph>{props?.question?.body}</Paragraph>
			</Pane>
			<Pane display="flex" flexDirection="row">
				<Button
					// onClick={props?.clickMost}
					onClick={props?.vote}
					value={"A"}
					id={props?.id}
					style={{
						border: "none",
						boxShadow: "none",
						color: White,
						marginLeft: "8px",
						backgroundImage: "none",
						borderRadius: "2px",
						backgroundColor:
							props?.question?.answer === "A" ? PrimaryContrast : PrimaryGray,
					}}
					mini={+true}
				>
					MOST
				</Button>
				<Button
					// onClick={props?.clickLeast}
					onClick={props?.vote}
					value={"B"}
					id={props?.id}
					style={{
						border: "none",
						boxShadow: "none",
						color: White,
						marginLeft: "8px",
						backgroundImage: "none",
						borderRadius: "2px",
						backgroundColor:
							props?.question?.answer === "B" ? PrimaryContrast : PrimaryGray,
					}}
					mini={+true}
				>
					LEAST
				</Button>
			</Pane>
		</Pane>
	);
}

export default Question;
