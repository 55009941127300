import { Label, Pane, TextInput } from "evergreen-ui";
import React, { useState } from "react";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Button from "../lib/button/Button";
import { PaneProps, SecondaryDark } from "../utils/Consts";
import PageTitleBar from "./PageTitleBar";
import firebase from "../utils/firebase";
import FullStory from "../utils/fullstory";

export default function UpgradeUser() {
	const [uid, setUid] = useState("");
	const [loading, setLoading] = useState(false);
	const upgradeUser = async () => {
		try {
			setLoading(true);
			const adminGenerateFullReport = firebase
				.functions()
				.httpsCallable("adminGenerateFullReport");
			await adminGenerateFullReport({ uid });
			setLoading(false);
		} catch (error) {
			setLoading(false);
			FullStory.log("error", error);
		}
	};
	return (
		<Pane {...PaneProps} display="flex" width="100%">
			<Breadcrumbs
				crumbs={[
					{ text: "Admin", link: "/admin" },
					{ text: "Upgrade User", link: "/admin/upgradeuser" },
				]}
			/>
			<PageTitleBar title={`Upgrade User`} />
			<Pane maxWidth="544px" display="flex" width="100%" flexDirection="column">
				<Pane className="text-field-input-and-label">
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Label marginBottom="8px" color={SecondaryDark}>
							UID
						</Label>
					</Pane>
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<TextInput
							className="field-input"
							onChange={(e) => setUid(e.target.value)}
							value={uid}
							height="100%"
							padding="11px"
							minWidth="70%"
							border="none"
							outline="transparent"
							boxShadow="none"
							fontSize="14px"
							placeholder={"UID..."}
						/>
					</Pane>
				</Pane>
				<Pane>
					<Button isLoading={loading} primary={+true} onClick={upgradeUser}>
						Upgrade
					</Button>
				</Pane>
			</Pane>
		</Pane>
	);
}
