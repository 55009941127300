import React, { Component } from "react";
import Context from "../utils/Context";
import Auth from "./Auth";
import { withRouter } from "react-router-dom";

class AuthContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => <Auth context={context} {...this.props} />}
			</Context.Consumer>
		);
	}
}

export default withRouter(AuthContainer);
