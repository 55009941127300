import React, { Component } from "react";
import { Title } from "../utils/Consts";
import logo from "../logo.svg";
import logow from "../logow.svg";
import logob from "../logob.svg";

const brand = {
	white: logow,
	black: logob,
};

export default class Logo extends Component {
	render() {
		return (
			<img
				alt={`${Title}-logo`}
				width={this.props.width || "30px"}
				src={brand[this.props.brand] || logo}
			/>
		);
	}
}
