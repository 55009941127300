import React, { Component } from "react";
import Context from "../utils/Context";
import ProfileProfessional from "./ProfileProfessional";

export default class ProfileProfessionalContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => <ProfileProfessional context={context} {...this.props} />}
			</Context.Consumer>
		);
	}
}
