import React, { Component } from "react";
import { Pane } from "evergreen-ui";
import firebase from "../utils/firebase";
import Button from "../lib/button/Button";
import { ButtonProps } from "../utils/Consts";
import { connect } from "twilio-video";
import Card from "../lib/card/Card";
import PageTitleBar from "./PageTitleBar";
import FullStory from "../utils/fullstory";

export default class Video extends Component {
	state = { participants: [] };

	//server creates a Room SID
	//server writes Room SID to users' DB
	//client listens to a db location showing if video room exists with a Room SID in the users' DB location

	//client requests an access token to join the room to server
	//server generates access token (depending on who's asking)
	//access token returned to client

	//client connects to the room using the Twilio Video SDK connect interface, authenticating using the Access Token

	getToken = async () => {
		try {
			const uniquename = this?.props?.match?.params?.roomname;
			const getTokenRef = firebase
				.functions()
				.httpsCallable("getVideoRoomAccessToken");
			const getTokenData = await getTokenRef({
				uniquename,
			});

			const tokenData = getTokenData?.data;
			const token = tokenData?.token;
			const name = tokenData?.name;
			if (!token) {
				this.props.context.toaster.danger(
					"There was an error joining the room. Please try again later."
				);
			}

			return { token, uniquename, name };
		} catch (error) {
			FullStory.log("error", error);
			return this.props.context.toaster.danger(
				"There was an error getting a token to join a room."
			);
		}
	};

	joinRoom = async () => {
		try {
			this.setState({ loading: true });
			const { token, uniqueName, name } = await this.getToken();
			const room = await connect(token, {
				name: uniqueName,
				video: true,
				audio: true,
			});
			this.setState({ room });
			this.setState({ connected: true });
			this.participantConnected(room.localParticipant);
			room.participants.forEach(this.participantConnected);

			room.on("participantConnected", this.participantConnected);

			room.on("participantDisconnected", this.participantDisconnected);
			window.addEventListener("beforeunload", this.tidyUp(room));
			window.addEventListener("pagehide", this.tidyUp(room));

			this.setState({ loading: false, name, uniqueName });
		} catch (error) {
			FullStory.log("error", error);
			this.props.context.toaster.danger(
				"There was an error joining the room. Please try again later or contact support."
			);
		}
	};

	participantConnected = (participant) => {
		const el = document.createElement("div");
		el.setAttribute("id", participant.identity);
		const participants = document.getElementById("participants");
		participants.appendChild(el);

		participant.tracks.forEach((trackPublication) => {
			this.trackPublished(trackPublication, participant);
		});

		participant.on("trackPublished", this.trackPublished);
	};

	trackPublished = (trackPublication, participant) => {
		const el = document.getElementById(participant.identity);

		const trackSubscribed = (track) => el.appendChild(track.attach());

		if (trackPublication.track) {
			trackSubscribed(trackPublication.track);
		}
		trackPublication.on("subscribed", trackSubscribed);
	};
	participantDisconnected = (participant) => {
		participant.removeAllListeners();
		const el = document.getElementById(participant.identity);
		el.remove();
	};

	trackUnpublished(trackPublication) {
		trackPublication.track
			.detach()
			.forEach((mediaElement) => mediaElement.remove());
	}

	tidyUp = (room) => {
		return function (event) {
			if (event.persisted) {
				return;
			}
			if (room) {
				room.disconnect();
				room = null;
			}
		};
	};

	disconnect = () => {
		const { room } = this.state;
		room.disconnect();
		const participants = document.getElementById("participants");
		this.removeAllChildNodes(participants);
		this.setState({ room: null, name: null, connected: false });
	};

	removeAllChildNodes = (parent) => {
		while (parent.firstChild) {
			parent.removeChild(parent.firstChild);
		}
	};

	render() {
		return (
			<Pane>
				<PageTitleBar
					title={`${this.state.name ? this.state.name : "Video Call"}`}
				/>
				<Pane display="flex" flexWrap="wrap" flexDirection="column">
					<Pane id="participants"></Pane>
					{!this.state.connected ? (
						<Card
							title="Join Video Call"
							body="LeadersEd will initate a secure video call. When you're ready to begin, join the call."
							buttonClick={this.joinRoom}
							isLoading={this.state.loading}
							primary={+true}
							button={this.state.loading ? "Joining..." : "Join Room"}
						/>
					) : (
						<Pane>
							<Button
								isLoading={this.state.loading}
								onClick={this.disconnect}
								{...ButtonProps}
							>
								{this.state.loading ? "Disconnecting..." : "Disconnect"}
							</Button>
						</Pane>
					)}
				</Pane>
			</Pane>
		);
	}
}
