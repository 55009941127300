import { Pane } from "evergreen-ui";
import React, { Component } from "react";
import FieldInput from "../lib/fieldInput/FieldInput";
import Context from "../utils/Context";
import { PaneProps, UpdateDB } from "../utils/Consts";
import PageTitleBar from "./PageTitleBar";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Divider from "../lib/divider/Divider";
// import firebase from "../utils/firebase";

export default class ProfileIdentity extends Component {
	state = {};

	updateField = async (fieldName, value) => {
		try {
			this.setState({ [`${fieldName}Loading`]: true });
			await UpdateDB(`users/${this.props?.context?.auth?.uid}`, {
				[`${fieldName}`]: value,
			});
			this.setState({ [`${fieldName}Loading`]: false });
		} catch (error) {
			this.props.context.toaster.danger(
				`There was an error saving the ${fieldName}. Please try again later.`
			);
			this.setState({ [`${fieldName}Loading`]: false });
		}
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane width="100%" {...PaneProps} display="flex">
						<Breadcrumbs
							crumbs={[
								{ text: "Profile", link: "/profile" },
								{ text: "Identity", link: "/profile/identity" },
							]}
						/>
						<PageTitleBar title="Identity" />
						<Pane width="100%">
							<FieldInput
								loading={this.state.nameLoading}
								editMessage="Update name..."
								label="Name"
								value={context.user?.name}
								fieldName="name"
								submitHandler={this.updateField}
								placeholder={context.user?.name || "Name..."}
								edit={!context.user?.name}
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<FieldInput
								loading={this.state.usernameLoading}
								editMessage="Update username..."
								label="Username"
								value={context.user?.username}
								fieldName="username"
								submitHandler={this.updateField}
								placeholder={context.user?.username || "Username..."}
								edit={!context.user?.username}
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<FieldInput
								loading={this.state.cityLoading}
								editMessage="Update city..."
								label="City"
								value={context.user?.city}
								fieldName="city"
								submitHandler={this.updateField}
								placeholder={context.user?.city || "City..."}
								edit={!context.user?.city}
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<FieldInput
								loading={this.state.stateLoading}
								editMessage="Update state..."
								label="State"
								value={context.user?.state}
								fieldName="state"
								submitHandler={this.updateField}
								placeholder={context.user?.state || "State..."}
								edit={!context.user?.state}
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<FieldInput
								loading={this.state.countryLoading}
								editMessage="Update country..."
								label="Country"
								value={context.user?.country}
								fieldName="country"
								submitHandler={this.updateField}
								placeholder={context.user?.country || "Country..."}
								edit={!context.user?.country}
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
							<FieldInput
								loading={this.state.phoneNumberLoading}
								editMessage="Update phone number..."
								label="Phone number"
								value={context.user?.phoneNumber}
								fieldName="phoneNumber"
								submitHandler={this.updateField}
								placeholder={context.user?.phoneNumber || "Phone Number..."}
								edit={!context.user?.phoneNumber}
							/>
							<Pane marginTop="20px">
								<Divider />
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
