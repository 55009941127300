import { Pane, Paragraph } from "evergreen-ui";
import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Card from "../lib/card/Card";
import { PaneProps, Personalities } from "../utils/Consts";
import PageTitleBar from "./PageTitleBar";

export default function Personality() {
	let params = useParams();
	const personalityName = params?.id?.toLowerCase();
	const personalityDetails = Object.keys(Personalities)
		.map((trait) => Personalities[trait])
		.map((personality) => ({
			name: personality?.name?.toLowerCase(),
			definition: personality?.definition,
		}))
		.filter((personality) => personality?.name === personalityName)[0];
	return (
		<Pane {...PaneProps} display="flex" width="100%">
			<Breadcrumbs
				crumbs={[
					{ text: "Personalities", link: "/personalities" },
					{
						text: `${personalityDetails?.name[0].toUpperCase()}${personalityDetails?.name?.slice(
							1
						)}`,
						link: `/personality/${personalityDetails?.name}`,
					},
				]}
			/>
			<PageTitleBar
				title={`Personality Definition: ${personalityDetails?.name[0].toUpperCase()}${personalityDetails?.name?.slice(
					1
				)}`}
			/>
			<Pane>
				{!!personalityDetails ? (
					<Card
						title={personalityDetails?.name}
						link={`/personalities`}
						button={"View All Personalities"}
						body={personalityDetails?.definition}
					/>
				) : (
					<Paragraph>No Personality Found</Paragraph>
				)}
			</Pane>
		</Pane>
	);
}
