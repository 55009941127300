import React, { Component } from "react";
import { Pane, Table as EGTable } from "evergreen-ui";
import { NavLink } from "react-router-dom";

export default class Table extends Component {
	render() {
		if (!!this?.props?.data)
			return (
				<Pane maxWidth="100%">
					<EGTable>
						<EGTable.Head>
							{Object.keys(this?.props?.data[0]).map((item, i) => (
								<EGTable.TextHeaderCell
									textTransform="capitalize"
									key={`table-head-${i}`}
								>
									{item}
								</EGTable.TextHeaderCell>
							))}
						</EGTable.Head>
						<EGTable.Body height={500}>
							{this.props?.data?.map((item, i) => (
								<EGTable.Row background="#f2f2f2" key={i} height={60}>
									{Object.keys(this?.props?.data[0]).map((column, i) => {
										return (
											<EGTable.TextCell key={`row-data-${i}`}>
												{column === "Personality Type" ? (
													<NavLink
														to={`/personality/${item[column]?.toLowerCase()}`}
													>{`${item[column]} Personality`}</NavLink>
												) : (
													item[column]
												)}
											</EGTable.TextCell>
										);
									})}
								</EGTable.Row>
							))}
						</EGTable.Body>
					</EGTable>
				</Pane>
			);
	}
}
