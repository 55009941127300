import React from "react";
import { Alert as EGAlert } from "evergreen-ui";

function Alert(
	props = {
		title: String,
		intent: String,
		hasIcon: String,
		isRemovable: Boolean,
		body: String,
	}
) {
	return (
		<EGAlert
			{...props}
			intent={props?.intent}
			title={props.title}
			hasIcon={props?.hasIcon}
			isRemoveable={props?.isRemoveable}
		>
			{props?.body}
			{props?.children}
		</EGAlert>
	);
}

Alert.defaultProps = {
	intent: "none",
	isRemoveable: false,
	hasIcon: true,
};
export default Alert;
