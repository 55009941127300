import React, { useContext } from "react";
import DismissableCard from "../../lib/dismissableCard/DismissableCard";
import { Heading, majorScale, Pane, Paragraph } from "evergreen-ui";
import { UpdateDB, White } from "../../utils/Consts";
import Context from "../../utils/Context";
import firebase from "../../utils/firebase";
import FullStory from "../../utils/fullstory";

export default function WelcomeUpgradeNudge() {
	const context = useContext(Context);
	const closeNudge = async () => {
		try {
			await UpdateDB(`users/${context?.auth?.uid}`, {
				nudges: { welcomeUpgrade: firebase.firestore.FieldValue.delete() },
			});
		} catch (error) {
			FullStory.log("error", error);
			context.toaster.danger(
				"There was an error closing the dialog. Please try again in a short while."
			);
		}
	};
	return (
		<DismissableCard
			closeClickHandler={closeNudge}
			style={{ alignItems: "flex-start" }}
		>
			<Pane>
				<Pane marginBottom={majorScale(5)}>
					<Heading color={White} size={700}>
						Thank you for joining our community!
					</Heading>
					{/* <Paragraph color={White}>
							Thank you for taking the free Personality DNA Assessment. Your
							personal results are now ready and can be found below. To get more
							indepth insights about your personality, purchase the Personality
							DNA book or upgrade to a Premium membership today.
						</Paragraph> */}
				</Pane>
				<Pane>
					<Paragraph color={White}>
						Welcome to your premium membership, you're now a premium member!
						Your unlocked reports can be found below, and can also be accessed
						on the menu on the left hand side.
					</Paragraph>
				</Pane>
			</Pane>
		</DismissableCard>
	);
}
