import { Pane, Paragraph, Card } from "evergreen-ui";
import React from "react";
import {
	CardProps,
	PaletteBlueOpaque,
	PaletteGoldOpaque,
	PaletteGreenOpaque,
	PaletteOrangeOpaque,
} from "../../utils/Consts";
import "./legendtable.css";

const Palette = [
	PaletteGreenOpaque,
	PaletteBlueOpaque,
	PaletteGoldOpaque,
	PaletteOrangeOpaque,
];

export default function LegendTable(props) {
	return (
		<Pane display="flex" flexDirection="row" flexWrap="wrap">
			<Card
				{...CardProps}
				width="100%"
				display="flex"
				maxWidth="100%"
				flexDirection="column"
			>
				<Paragraph marginBottom="20px">{props?.details}</Paragraph>
				<Pane
					className="legend-table--table-blocks"
					display="flex"
					flexDirection="row"
					flexWrap="wrap"
					justifyContent="space-between"
				>
					{props?.legend?.map((item, i) => (
						<Pane
							key={`legend-table-block-${i}`}
							backgroundColor={Palette[i]}
							className="legend-table--table-blocks--table-blocks-block"
							maxWidth="320px"
							width="22%"
							padding="25px"
							borderRadius="5px"
							alignItems="center"
						>
							<Pane marginBottom="13px">
								<Paragraph fontSize="20px" textAlign="center" fontWeight="500">
									{item?.title}
								</Paragraph>
							</Pane>
							<Pane marginBottom="13px">
								<Paragraph fontSize="16px" textAlign="center" fontWeight="500">
									{item?.subtitle}
								</Paragraph>
							</Pane>
							<Paragraph>{item?.body}</Paragraph>
						</Pane>
					))}
				</Pane>
			</Card>
		</Pane>
	);
}
