import React, { Component } from "react";
import { Label, Pane, Text, TextInput } from "evergreen-ui";
import { PrimaryContrast, SecondaryDark } from "../../utils/Consts";
import Button from "../button/Button";
import "./fieldInput.css";

const initState = { value: "", edit: false };

export default class FieldInput extends Component {
	state = { ...initState };
	componentDidMount() {
		this.setState({ edit: this.props?.edit });
	}

	clickHandler = async (e) => {
		try {
			await this.props?.submitHandler(e.target.name, this.state.value);
			this.setState({ ...initState });
		} catch (error) {
			return error;
		}
	};

	render() {
		return (
			<Pane maxWidth="544px" display="flex" width="100%" flexDirection="column">
				<Pane
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Label marginBottom="8px" color={SecondaryDark}>
						{this.props.label}
					</Label>
					{!this.props.uneditable && (
						<Text
							cursor="pointer"
							color={PrimaryContrast}
							onClick={() => this.setState({ edit: !this.state.edit })}
							fontWeight="600"
						>
							{this.state.edit ? "Cancel" : "Edit"}
						</Text>
					)}
				</Pane>
				<Pane>
					<Text>
						{this.state.edit ? this.props.editMessage : this.props.value}
					</Text>
				</Pane>
				{this.state.edit && (
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<TextInput
							className="field-input"
							onChange={(e) => this.setState({ value: e.target.value })}
							value={this.state.value}
							height="100%"
							padding="11px"
							minWidth="70%"
							border="none"
							outline="transparent"
							boxShadow="none"
							fontSize="14px"
							placeholder={this.props.placeholder}
						/>
					</Pane>
				)}
				{this.state.edit && (
					<Pane>
						<Button
							isLoading={this.props.loading}
							onClick={this.clickHandler}
							primary={+true}
							name={this.props.fieldName}
						>
							Save
						</Button>
					</Pane>
				)}
			</Pane>
		);
	}
}
