import React, { Component } from "react";
import wordmark from "../wordmark.svg";
import wordmarkw from "../wordmarkw.svg";
import wordmarkb from "../wordmarkb.svg";
import { Title } from "../utils/Consts";

const brand = {
	white: wordmarkw,
	black: wordmarkb,
};

export default class Wordmark extends Component {
	render() {
		return (
			<img
				alt={`${Title}-wordmark`}
				width={this.props.width}
				src={brand[this.props.brand] || wordmark}
				// src={this.props.alt ? wordmarkw : wordmark}
			/>
		);
	}
}
