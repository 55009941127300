import {
	Heading,
	majorScale,
	Pane,
	SideSheet,
	Spinner,
	Text,
} from "evergreen-ui";
import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import { PaneProps } from "../utils/Consts";
import Context from "../utils/Context";
import firebase from "../utils/firebase";
import PageTitleBar from "./PageTitleBar";
import Button from "../lib/button/Button";
import ReactJson from "react-json-view";
import FullStory from "../utils/fullstory";

export default function AdminUser() {
	const params = useParams();
	const context = useContext(Context);
	useEffect(
		() => {
			getUser();
		},
		// eslint-disable-next-line
		[]
	);
	const [user, setUser] = useState(null);
	const [report, setReport] = useState(null);
	const [sidesheet, setSidesheet] = useState(false);
	const [userLoading, setUserLoading] = useState(false);
	const [reportLoading, setReportLoading] = useState(false);

	const getUser = async () => {
		try {
			setUserLoading(true);
			const { uid } = params;
			const userDoc = await firebase
				.firestore()
				.collection("users")
				.doc(uid)
				.get();
			const user = userDoc.data();
			setUser(user);
			setUserLoading(false);
		} catch (error) {
			FullStory.log("error", error);
			setUserLoading(false);
			return context.toaster.danger("There was an error getting the user.");
		}
	};

	const viewReport = async (uid) => {
		try {
			setReportLoading(true);
			setSidesheet(true);
			const reportDoc = await firebase
				.firestore()
				.collection("reports")
				.doc(uid)
				.get();
			const report = reportDoc.data();
			setReport(report);
			setReportLoading(false);
		} catch (error) {
			setReportLoading(false);
			FullStory.log("error", error);
			return context.toaster.danger("There was an error getting the user.");
		}
	};

	return (
		<Pane {...PaneProps} display="flex" width="100%">
			<SideSheet
				isShown={sidesheet}
				onCloseComplete={() => {
					setSidesheet(false);
					setReport({});
				}}
			>
				<Pane padding={majorScale(2)} zIndex={1}>
					<Heading>Report</Heading>

					{reportLoading ? (
						<Pane
							display="flex"
							height="100%"
							width="100%"
							justifyContent="center"
							alignItems="center"
						>
							<Spinner />
						</Pane>
					) : (
						<ReactJson
							name="report"
							enableClipboard={false}
							displayDataTypes={false}
							displayObjectSize={false}
							collapseStringsAfterLength={50}
							indentWidth="2"
							src={report}
						/>
					)}
				</Pane>
			</SideSheet>
			<Breadcrumbs
				crumbs={[
					{ text: "Admin", link: "/admin" },
					{ text: "Events", link: "/admin/events" },
				]}
			/>
			<PageTitleBar title={`User`} />
			<Pane maxWidth="544px" display="flex" width="100%" flexDirection="column">
				<Pane width="100%" display="flex" flexDirection="column">
					{userLoading ? (
						<Pane
							display="flex"
							height="100%"
							width="100%"
							justifyContent="center"
							alignItems="center"
						>
							<Spinner />
						</Pane>
					) : !user ? (
						<Pane
							display="flex"
							height="100%"
							width="100%"
							justifyContent="start"
							flexDirection="column"
							// alignItems="center"
						>
							<Text>User not found</Text>
						</Pane>
					) : (
						<Pane
							display="flex"
							height="100%"
							width="100%"
							justifyContent="start"
							flexDirection="column"
							// alignItems="center"
						>
							<Text>
								Created:{" "}
								<Moment format="YYYY/MM/DD">{user?.created?.toMillis()}</Moment>
							</Text>
							<Text>Email: {user?.email}</Text>
							<Text>Email Verified: {user?.emailVerified}</Text>
							<Text>Name: {user?.name}</Text>
							<Text>uid: {user?.uid}</Text>
							<Button onClick={() => viewReport(user?.uid)}>View Report</Button>
						</Pane>
					)}
				</Pane>
			</Pane>
		</Pane>
	);
}
