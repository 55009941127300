import { Label, Pane, SelectMenu, Textarea, TextInput } from "evergreen-ui";
import React, { useContext, useState } from "react";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Button from "../lib/button/Button";
import { PaneProps, SecondaryDark } from "../utils/Consts";
import FullStory from "../utils/fullstory";
import PageTitleBar from "./PageTitleBar";
import DatePicker from "react-datepicker";
import firebase from "../utils/firebase";
import "react-datepicker/dist/react-datepicker.css";
import Context from "../utils/Context";

export default function AddEvent() {
	const [description, setDescription] = useState("");
	const [title, setTitle] = useState("");
	const [start, setStart] = useState();
	const [end, setEnd] = useState();
	const [location, setLocation] = useState("");
	const [logo, setLogo] = useState("");
	const [level, setLevel] = useState("");
	const [loading, setLoading] = useState(false);
	const context = useContext(Context);
	const addEvent = async () => {
		try {
			context.update("overlay", true);
			setLoading(true);
			await firebase
				.firestore()
				.collection("events")
				.add({
					description,
					start,
					end,
					title,
					location,
					logo,
					level: Number(level),
					tags: ["free"],
				});
			context.toaster.success("The event was successfully added.");
			setDescription("");
			setTitle("");
			setStart();
			setEnd();
			setLocation("");
			setLogo("");
			setLevel("");
			context.update("overlay", false);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
			context.update("overlay", false);
			context.toaster.danger(
				"There was an error saving the event, please try again later."
			);
			FullStory.log("error", error);
		}
	};
	return (
		<Pane {...PaneProps} display="flex" width="100%">
			<Breadcrumbs
				crumbs={[
					{ text: "Admin", link: "/admin" },
					{ text: "Add Event", link: "/admin/addevent" },
				]}
			/>
			<PageTitleBar title={`Add Event`} />
			<Pane maxWidth="544px" display="flex" width="100%" flexDirection="column">
				<Pane className="text-field-input-and-label">
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Label marginBottom="8px" color={SecondaryDark}>
							Title
						</Label>
					</Pane>
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<TextInput
							className="field-input"
							onChange={(e) => setTitle(e.target.value)}
							value={title}
							height="100%"
							padding="11px"
							minWidth="70%"
							border="none"
							outline="transparent"
							boxShadow="none"
							fontSize="14px"
							placeholder={"Title..."}
						/>
					</Pane>
				</Pane>
				<Pane className="text-field-input-and-label">
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Label marginBottom="8px" color={SecondaryDark}>
							Location
						</Label>
					</Pane>
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<TextInput
							className="field-input"
							onChange={(e) => setLocation(e.target.value)}
							value={location}
							height="100%"
							padding="11px"
							minWidth="70%"
							border="none"
							outline="transparent"
							boxShadow="none"
							fontSize="14px"
							placeholder={"Location..."}
						/>
					</Pane>
				</Pane>
				<Pane className="text-field-input-and-label">
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Label marginBottom="8px" color={SecondaryDark}>
							Logo
						</Label>
					</Pane>
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<TextInput
							className="field-input"
							onChange={(e) => setLogo(e.target.value)}
							value={logo}
							height="100%"
							padding="11px"
							minWidth="70%"
							border="none"
							outline="transparent"
							boxShadow="none"
							fontSize="14px"
							placeholder={"Logo..."}
						/>
					</Pane>
				</Pane>
				<Pane className="text-field-input-and-label">
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Label marginBottom="8px" color={SecondaryDark}>
							Description
						</Label>
					</Pane>
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<Textarea
							className="field-input"
							onChange={(e) => setDescription(e.target.value)}
							value={description}
							height="100%"
							padding="11px"
							minWidth="70%"
							border="none"
							outline="transparent"
							boxShadow="none"
							fontSize="14px"
							placeholder={"Description..."}
						/>
					</Pane>
				</Pane>
				<Pane
					className="field-input-row"
					display="flex"
					alignItems="center"
					marginBottom="20px"
					minHeight="45px"
					marginTop="19px"
				>
					<SelectMenu
						className="single-select"
						title={"Level"}
						options={[
							{ value: "1", label: "Premium" },
							{ value: "0", label: "Free" },
						]}
						selected={level}
						onSelect={(selected) => setLevel(selected?.value)}
						padding="11px"
						border="none"
						outline="transparent"
						boxShadow="none"
						fontSize="14px"
						hasTitle={true}
					>
						<Button>{`Level ${level}` || "Level"}</Button>
					</SelectMenu>
				</Pane>
				<Pane className="text-field-input-and-label">
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Label marginBottom="8px" color={SecondaryDark}>
							Start Date
						</Label>
					</Pane>
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<DatePicker
							selected={start}
							onChange={(date) => setStart(date)}
							showTimeSelect
							dateFormat="Pp"
						/>
					</Pane>
				</Pane>
				<Pane className="text-field-input-and-label">
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Label marginBottom="8px" color={SecondaryDark}>
							End Date
						</Label>
					</Pane>
					<Pane
						className="field-input-row"
						display="flex"
						alignItems="center"
						marginBottom="20px"
						minHeight="45px"
						marginTop="19px"
					>
						<DatePicker
							selected={end}
							onChange={(date) => setEnd(date)}
							showTimeSelect
							dateFormat="Pp"
						/>
					</Pane>
				</Pane>

				<Pane>
					<Button isLoading={loading} primary={+true} onClick={addEvent}>
						Add Event
					</Button>
				</Pane>
			</Pane>
		</Pane>
	);
}
