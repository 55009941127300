import { Button, Pane, Spinner, Table } from "evergreen-ui";
import React, { Component } from "react";
import Context from "../utils/Context";
import firebase from "../utils/firebase";
import FullStory from "../utils/fullstory";
import PageTitleBar from "./PageTitleBar";
// import Table from "./Table";

export default class Rooms extends Component {
	state = { rooms: [], roomsLoading: true };
	componentDidMount() {
		this.getRooms();
	}

	getRooms = async () => {
		try {
			const uid = this.props.context.uid;
			const roomSnapshot = await firebase
				.firestore()
				.collection("videorooms")
				.where("uid", "==", uid)
				.where("status", "not-in", ["completed"])
				.orderBy("status", "desc")
				.orderBy("created", "desc")
				.limit(20)
				.get();
			let rooms = [];
			if (roomSnapshot.empty) {
				return this.setState({ rooms, roomsLoading: false });
			}
			let docs = roomSnapshot.docs;
			rooms = docs.map((doc) => doc.data());
			this.setState({ rooms, roomsLoading: false });
			// rooms = rooms.map((room) => ({
			// 	name: room?.name,
			// 	status: room?.status,
			// 	// type: room?.type,
			// 	uniqueName: room?.uniqueName,
			// 	created: room?.created?.toDate()?.toDateString(),
			// 	uids: room?.uids?.filter((uid) => uid !== this.props.context.uid)[0],
			// }));
			// this.setState({ rooms, roomsLoading: false });
			// roomSnapshot.forEach((doc) => rooms.push(doc.data()));
			// this.setState({ rooms });
		} catch (error) {
			FullStory.log("error", error);
			this.props.context.toaster.danger(
				"There was an error retrieving the rooms."
			);
		}
	};

	createRoom = async (e) => {
		try {
			this.setState({ createRoomLoading: true });
			const dataset = e?.target?.dataset;
			const uniqueName = dataset?.uniquename;

			if (!uniqueName || uniqueName.length === 0) {
				return this.props.context.toaster.danger(
					"Missing information. Please try again later."
				);
			}
			const roomRef = firebase.functions().httpsCallable("createVideoRoom");
			const roomData = await roomRef({ uniqueName });
			const data = roomData?.data;
			const returnedUniqueName = data?.uniqueName;
			if (!returnedUniqueName) {
				return this.props.context.toaster.danger(
					"Error starting the room. Please try again later"
				);
			}
			this.props.context.toaster.success("Room successfully started");
			return this.props.history.push(`/video/${returnedUniqueName}`);
		} catch (error) {
			FullStory.log("error", error);
			this.props.context.toaster.success(
				"There was an error creating the room. Please try again later."
			);
		}
	};
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane width="100%" display="flex" flexDirection="column">
						<PageTitleBar title="Rooms" />
						<Pane width="100%" display="flex">
							{this.state.roomsLoading ? (
								<Spinner />
							) : (
								<Table width="100%">
									<Table.Head>
										<Table.TextHeaderCell>Name</Table.TextHeaderCell>
										<Table.TextHeaderCell>Unique Name</Table.TextHeaderCell>
										<Table.TextHeaderCell>Created</Table.TextHeaderCell>
										{/* <Table.TextHeaderCell>UID</Table.TextHeaderCell> */}
										<Table.TextHeaderCell>Status</Table.TextHeaderCell>
										<Table.TextHeaderCell>Start</Table.TextHeaderCell>
									</Table.Head>
									<Table.Body height={240}>
										{this.state.rooms?.map((room, i) => (
											<Table.Row key={`room-${i}`}>
												<Table.TextCell>{room?.name || "-"}</Table.TextCell>
												<Table.TextCell>
													{room?.uniqueName || "-"}
												</Table.TextCell>
												<Table.TextCell>
													{room?.created?.toDate()?.toDateString() || "-"}
												</Table.TextCell>
												{/* <Table.TextCell>
													{room?.uids?.filter(
														(uid) => uid !== this.props.context.uid
													)[0] || "-"}
												</Table.TextCell> */}
												<Table.TextCell>{room?.status || "-"}</Table.TextCell>
												<Table.TextCell>
													<Button
														data-uniquename={room?.uniqueName}
														isLoading={this.state.createRoomLoading}
														onClick={this.createRoom}
													>
														Start Room
													</Button>
												</Table.TextCell>
											</Table.Row>
										))}
									</Table.Body>
								</Table>
							)}
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
