import {
	Heading,
	majorScale,
	Pane,
	SideSheet,
	Spinner,
	Table,
	Text,
} from "evergreen-ui";
import React, { useState } from "react";
import Button from "../lib/button/Button";
import PageTitleBar from "./PageTitleBar";
import firebase from "../utils/firebase";
import ReactJson from "react-json-view";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import FullStory from "../utils/fullstory";

export default function AdminUsers() {
	const [users, setUsers] = useState([]);
	const [user, setUser] = useState({});
	const [usersLoading, setUsersLoading] = useState(false);
	const [sidesheet, setSidesheet] = useState(false);
	const [reportLoading] = useState(false);
	const [report, setReport] = useState(null);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());

	const search = async () => {
		try {
			setUsers(null);
			setUsersLoading(true);

			const resultsQuery = await firebase
				.firestore()
				.collection("users")
				.where("created", ">", startDate)
				.where("created", "<=", endDate)
				.get();
			const users = resultsQuery.docs.map((doc) => doc.data());

			const usersWithReport = await Promise.all(
				users.map((user) =>
					firebase
						.firestore()
						.collection("reports")
						.doc(user?.uid)
						.get()
						.then((doc) => doc.data())
						.then((report) => ({ ...user, report }))
				)
			);

			setUsers(usersWithReport);
			setUsersLoading(false);
		} catch (error) {
			FullStory.log("error", error);
			setUsersLoading(false);
		}
	};

	const viewUser = async (user) => {
		try {
			setUser(user);
			setReport(user?.report);
			setSidesheet(true);
		} catch (error) {
			FullStory.log("error", error);
		}
	};

	return (
		<Pane width="100%" display="flex" flexDirection="column">
			<PageTitleBar title="Users" />
			<SideSheet
				isShown={sidesheet}
				onCloseComplete={() => {
					setSidesheet(false);
					setUser(null);
				}}
			>
				<Pane padding={majorScale(2)} zIndex={1} x>
					<Heading>User</Heading>
					<ReactJson
						name="user"
						enableClipboard={false}
						displayDataTypes={false}
						displayObjectSize={false}
						collapseStringsAfterLength={50}
						indentWidth="2"
						src={user}
					/>
					<Heading>Report</Heading>
					{reportLoading ? (
						<Pane
							display="flex"
							height="100%"
							width="100%"
							justifyContent="center"
							alignItems="center"
						>
							<Spinner />
						</Pane>
					) : (
						<ReactJson
							name="report"
							enableClipboard={false}
							displayDataTypes={false}
							displayObjectSize={false}
							collapseStringsAfterLength={50}
							indentWidth="2"
							src={report}
						/>
					)}
				</Pane>
			</SideSheet>
			<Pane width="100%" display="flex" flexDirection="column">
				<Pane>
					<Pane
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
						alignItems="flex-end"
						marginBottom={majorScale(3)}
					>
						<Pane display="flex" flexDirection="row">
							<Pane>
								<Pane>Date From</Pane>
								<DatePicker
									selected={startDate}
									onChange={(date) => setStartDate(date)}
								/>
							</Pane>
							<Pane>
								<Pane>Date To</Pane>
								<DatePicker
									selected={endDate}
									onChange={(date) => setEndDate(date)}
								/>
							</Pane>
						</Pane>
						<Button onClick={search}>Search</Button>
					</Pane>
				</Pane>
				<Pane display="flex" flexDirection="row" justifyContent="space-between">
					<Text>Results Table</Text>
					{!!users && (
						<CSVLink
							filename={"users.csv"}
							target="_blank"
							data={users.map((user) => ({
								created: moment(user.created?.toMillis()).format(
									"MMM DD, YYYY hh:mm:ss a"
								),
								name: user.name,
								email: user.email,
								report: !user.report == null,
								level: user.report?.level,
								personality: user.report?.name,
								uid: user?.uid,
							}))}
						>
							Download
						</CSVLink>
					)}
				</Pane>
				<Table>
					<Table.Head>
						<Table.TextHeaderCell>Created</Table.TextHeaderCell>
						<Table.TextHeaderCell>Email</Table.TextHeaderCell>
						<Table.TextHeaderCell>Name</Table.TextHeaderCell>
						<Table.TextHeaderCell>Report</Table.TextHeaderCell>

						<Table.TextHeaderCell>Personality</Table.TextHeaderCell>
						<Table.TextHeaderCell>Premium</Table.TextHeaderCell>

						<Table.TextHeaderCell>View</Table.TextHeaderCell>
					</Table.Head>
					{usersLoading ? (
						<Pane
							display="flex"
							height="100%"
							width="100%"
							justifyContent="center"
							alignItems="center"
						>
							<Spinner />
						</Pane>
					) : (
						<Table.Body height={700}>
							{users.map((user, i) => {
								return (
									<Table.Row key={`user-search-result-user-${i}`}>
										<Table.TextCell>
											<Moment format="YYYY/MM/DD">
												{user.created?.toMillis()}
											</Moment>
										</Table.TextCell>
										<Table.TextCell>{user.email}</Table.TextCell>
										<Table.TextCell>{user.name}</Table.TextCell>
										<Table.TextCell>
											{user.report == null ? "No" : "Yes"}
										</Table.TextCell>
										<Table.TextCell>{user?.report?.name}</Table.TextCell>
										<Table.TextCell>
											{user?.report?.level > 0 ? "Yes" : "No"}
										</Table.TextCell>

										<Table.TextCell>
											<Button onClick={() => viewUser(user)}>View</Button>
										</Table.TextCell>
									</Table.Row>
								);
							})}
						</Table.Body>
					)}
				</Table>
			</Pane>
		</Pane>
	);
}
