import {
	Heading,
	majorScale,
	Pane,
	SideSheet,
	Spinner,
	Table,
	Text,
} from "evergreen-ui";
import React, { useContext, useState } from "react";
import ReactJson from "react-json-view";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Button from "../lib/button/Button";
import PageTitleBar from "./PageTitleBar";
import DatePicker from "react-datepicker";
import Moment from "react-moment";
import { PaneProps } from "../utils/Consts";
import firebase from "../utils/firebase";
import { NavLink } from "react-router-dom";
import FullStory from "../utils/fullstory";
import Context from "../utils/Context";

export default function AdminEvents() {
	const [events, setEvents] = useState([]);
	const [event, setEvent] = useState({});
	const [eventsLoading, setEventsLoading] = useState(false);
	const [sidesheet, setSidesheet] = useState(false);
	const [eventUsersLoading, setEventUsersLoading] = useState(false);
	const [eventUsers, setEventUsers] = useState([]);
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const context = useContext(Context);

	const search = async () => {
		try {
			setEventsLoading(true);
			setEvents(null);

			const resultsQuery = await firebase
				.firestore()
				.collection("events")
				.where("start", ">", startDate)
				.where("start", "<=", endDate)
				.get();
			const docs = resultsQuery.docs.map((doc) => ({
				...doc.data(),
				id: doc.id,
			}));
			setEvents(docs);
			setEventsLoading(false);
		} catch (error) {
			FullStory.log("error", error);
			setEventsLoading(false);
		}
	};

	const viewEvent = async (event) => {
		try {
			setEvent(event);
			setSidesheet(true);
			setEventUsersLoading(true);
			const eventUsers = await firebase
				.firestore()
				.collectionGroup("userEvents")
				.where("registered", "==", true)
				.where("id", "==", event?.id)
				.get();
			const eventUsersDocs = eventUsers.docs?.map((doc) => doc.data());
			setEventUsers(eventUsersDocs);
			setEventUsersLoading(false);
		} catch (error) {
			FullStory.log("error", error);
		}
	};

	const publishEvent = async (event) => {
		try {
			const newStatus = !event.published;
			await firebase
				.firestore()
				.collection("events")
				.doc(event?.id)
				.set({ published: newStatus }, { merge: true });
			search();
			context.toaster.success("The event was successfully updated.");
		} catch (error) {
			context.toaster.danger("There was an error updating the event.");
			FullStory.log("error", error);
		}
	};

	return (
		<Pane {...PaneProps} display="flex" width="100%">
			<SideSheet
				isShown={sidesheet}
				onCloseComplete={() => {
					setSidesheet(false);
					setEvent(null);
				}}
				preventBodyScrolling={true}
			>
				<Pane padding={majorScale(2)} zIndex={1}>
					<Heading>Event</Heading>
					<ReactJson
						name="user"
						enableClipboard={false}
						displayDataTypes={false}
						displayObjectSize={false}
						collapseStringsAfterLength={50}
						indentWidth="2"
						src={event}
					/>
					<Heading>Users</Heading>
					{eventUsersLoading ? (
						<Pane
							display="flex"
							height="100%"
							width="100%"
							justifyContent="center"
							alignItems="center"
						>
							<Spinner />
						</Pane>
					) : (
						<Pane>
							{eventUsers.map((user, i) => (
								<Pane key={`user-${i}`}>
									<NavLink to={`/admin/user/${user.uid}`}>{user.uid}</NavLink>
								</Pane>
							))}
						</Pane>
						// <ReactJson
						// 	name="eventUsers"
						// 	enableClipboard={false}
						// 	displayDataTypes={false}
						// 	displayObjectSize={false}
						// 	collapseStringsAfterLength={50}
						// 	indentWidth="2"
						// 	src={eventUsers}
						// />
					)}
				</Pane>
			</SideSheet>
			<Breadcrumbs
				crumbs={[
					{ text: "Admin", link: "/admin" },
					{ text: "Events", link: "/admin/events" },
				]}
			/>
			<PageTitleBar title={`Events`} />
			<Pane maxWidth="544px" display="flex" width="100%" flexDirection="column">
				<Pane width="100%" display="flex" flexDirection="column">
					<Pane>
						<Pane
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="flex-end"
							marginBottom={majorScale(3)}
						>
							<Pane display="flex" flexDirection="row">
								<Pane>
									<Pane>Date From</Pane>
									<DatePicker
										selected={startDate}
										onChange={(date) => setStartDate(date)}
									/>
								</Pane>
								<Pane>
									<Pane>Date To</Pane>
									<DatePicker
										selected={endDate}
										onChange={(date) => setEndDate(date)}
									/>
								</Pane>
							</Pane>
							<Button onClick={search}>Search</Button>
						</Pane>
					</Pane>
					<Pane>Results Table</Pane>
					<Table>
						<Table.Head>
							<Table.TextHeaderCell>Start Date</Table.TextHeaderCell>
							<Table.TextHeaderCell>Title</Table.TextHeaderCell>
							<Table.TextHeaderCell>Level</Table.TextHeaderCell>
							<Table.TextHeaderCell>Level</Table.TextHeaderCell>
							<Table.TextHeaderCell>View</Table.TextHeaderCell>
						</Table.Head>
						{eventsLoading ? (
							<Pane
								display="flex"
								height="100%"
								width="100%"
								justifyContent="center"
								alignItems="center"
							>
								<Spinner />
							</Pane>
						) : (
							<Table.Body height={700}>
								{events.map((event, i) => (
									<Table.Row key={`event-search-result-event-${i}`}>
										<Table.TextCell>
											<Moment format="YYYY/MM/DD">
												{event.start?.toMillis()}
											</Moment>
										</Table.TextCell>
										<Table.TextCell>{event.title}</Table.TextCell>
										<Table.TextCell>{event.level}</Table.TextCell>
										<Table.TextCell>
											<Text
												cursor="pointer"
												onClick={() => publishEvent(event)}
											>
												{event.published ? "Unpublish" : "Publish"}
											</Text>
										</Table.TextCell>
										<Table.TextCell>
											<Text cursor="pointer" onClick={() => viewEvent(event)}>
												View
											</Text>
										</Table.TextCell>
									</Table.Row>
								))}
							</Table.Body>
						)}
					</Table>
				</Pane>
			</Pane>
		</Pane>
	);
}
