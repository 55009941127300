import { Button as Btn } from "evergreen-ui";
import React from "react";
import { PrimaryDark, White } from "../../utils/Consts";
import "./button.css";

export default class Button extends Btn {
	render() {
		return (
			<Btn
				className={this.props.primary ? "btn--primary" : "btn--standard"}
				style={{
					transition: "background-color, border-color, all 0.3s ease 0s",
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					paddingY: "11px",
					paddingX: this.props.mini ? "14px" : "31px",
					minWidth: this.props.mini ? "60px" : "107px",
					// width: "fit-content",
					width: "max-content",
					height: "41px",
					boxSizing: "border-box",
					textTransform: "uppercase",
					// padding: "11px 31px 11px 31px",
					letterSpacing: "0.14em",
					borderRadius: "2px",
					backgroundColor:
						this.props.backgroundColor ||
						`${this.props.primary ? PrimaryDark : White}`,
					color:
						this.props.color || `${this.props.primary ? White : PrimaryDark}`,
					borderColor: this.props.borderColor || PrimaryDark,
					borderWidth: "2px",
					borderStyle: "solid",
					// border: `2px solid ${PrimaryDark}`,
					backgroundImage: "none",
					cursor: "pointer",
					marginLeft: this.props.marginLeft || "0px",
					...this.props.style,
				}}
				disabled={this.props.loading}
				isLoading={this.props.isLoading}
				{...this.props}
			>
				{this.props.children}
			</Btn>
		);
	}
}
