import { ChevronRightIcon, Pane, Text } from "evergreen-ui";
import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumbs(props) {
	return (
		<Pane>
			{props?.crumbs?.map((crumb, i) => (
				<Text key={`crumb-${i}`}>
					<Link to={crumb.link}>{crumb.text}</Link>
					<Text>
						{i !== props.crumbs?.length - 1 && (
							<ChevronRightIcon marginX="10px" size={12} />
						)}
					</Text>
				</Text>
			))}
		</Pane>
	);
}
