import { majorScale, Pane } from "evergreen-ui";
import React, { Component } from "react";
import Context from "../utils/Context";
import {
	PaneProps,
	ProfessionalOpportunities,
	UpdateDB,
} from "../utils/Consts";
import PageTitleBar from "./PageTitleBar";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import ButtonSwitch from "../lib/buttonSwitch/ButtonSwitch";
import Button from "../lib/button/Button";
import MultiSelect from "../lib/multiselect/MultiSelect";

const careerInterests = [
	{
		name: "open_to_new_career_opportunities",
		title: "New Career Opportunities",
		details: "Are you currently open to new career opportunities?",
	},
	{
		name: "open_to_remote_work",
		title: "Remote Work",
		details: "If selected, we may share remote work opportunities",
	},
];

export default class ProfileCareer extends Component {
	state = {
		open_to_new_career_opportunities:
			this?.props?.context?.user?.careerInterests
				?.open_to_new_career_opportunities || false,
		open_to_remote_work:
			this?.props?.context?.user?.careerInterests
				?.open_to_new_career_opportunities || false,
		tags: this.props?.context?.user?.careerOpportunities || [],
	};

	updateField = async (fieldName, value) => {
		try {
			this.setState({ [`${fieldName}Loading`]: true });
			await UpdateDB(`users/${this.props?.context?.auth?.uid}`, {
				[`${fieldName}`]: value,
			});
			this.setState({ [`${fieldName}Loading`]: false });
		} catch (error) {
			this.props.context.toaster.danger(
				`There was an error saving the ${fieldName}. Please try again later.`
			);
			this.setState({ [`${fieldName}Loading`]: false });
		}
	};

	clickHandler = (e) =>
		this.setState({ [`${e.target.name}`]: e.target.value === "true" });

	save = async () => {
		try {
			const data = careerInterests
				.map((item) => item.name)
				.reduce((accumulator, name) => {
					accumulator[name] = this.state[name];
					return { ...accumulator };
				}, {});
			await UpdateDB(`users/${this.props?.context?.auth?.uid}`, {
				careerInterests: data,
			});
			if (!!this.state["open_to_new_career_opportunities"]) {
				await UpdateDB(`users/${this.props?.context?.auth?.uid}`, {
					careerOpportunities: this.state.tags,
				});
			}
			this.props.context.toaster.success(
				`Career interests successfully updated.`
			);
		} catch (error) {
			this.props.context.toaster.danger(
				`There was an error saving. Please try again later.`
			);
		}
	};

	cancel = () => {
		const fields = careerInterests.map((item) => item.name);
		fields.forEach((field) =>
			this.setState({
				[`${field}`]:
					this.props?.context?.user?.careerInterests?.[field] || false,
			})
		);
	};

	setTagsHandler = (tags) => this.setState({ tags });

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane width="100%" {...PaneProps} display="flex">
						<Breadcrumbs
							crumbs={[
								{ text: "Profile", link: "/profile" },
								{ text: "Career", link: "/profile/career" },
							]}
						/>
						<PageTitleBar title="Career" />
						<Pane width="100%">
							<ButtonSwitch
								// key={`interests-field-${i}`}
								click={this.clickHandler}
								name={"open_to_new_career_opportunities"}
								value={this.state["open_to_new_career_opportunities"]}
								title={"New Career Opportunities"}
								details={"If selected, we may share new career opportunities"}
								// hideDivider={true}
							>
								{!!this.state["open_to_new_career_opportunities"] && (
									<Pane marginTop={majorScale(3)}>
										<MultiSelect
											tags={this.state.tags}
											buttonTitle="Types of Opportunities"
											options={ProfessionalOpportunities.map((label) => ({
												label:
													label[0].toUpperCase() +
													label?.slice(1).toLowerCase(),
												value: label.toLowerCase(),
											}))}
											select={(selectedItemsState) =>
												this.setTagsHandler(selectedItemsState)
											}
										/>
									</Pane>
								)}
							</ButtonSwitch>
							<ButtonSwitch
								// key={`interests-field-${i}`}
								click={this.clickHandler}
								name={"open_to_remote_work"}
								value={this.state["open_to_remote_work"]}
								title={"Remote Work"}
								details={"If selected, we may share remote work opportunities"}
							/>

							<Pane display="flex">
								<Button onClick={this.cancel} marginRight="10px">
									Cancel
								</Button>
								<Button onClick={this.save} primary={+true} marginRight="10px">
									Save Changes
								</Button>
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
