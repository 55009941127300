import { Card, Heading, majorScale, Pane } from "evergreen-ui";
import React from "react";
import {
	MiniCardProps,
	PrimaryGray,
	PrimaryLight,
	SecondaryDark,
} from "../utils/Consts";

export default function ResultMiniCard(props) {
	return (
		<Card {...MiniCardProps} style={{ ...props.style }}>
			<Heading
				fontFamily='"Jost",sans-serif'
				textAlign="center"
				size={600}
				fontWeight="400"
				color={props.color}
				paddingBottom={majorScale(1)}
			>
				{props.title}
			</Heading>

			<Pane>
				<svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
					<circle
						className="donut-hole"
						cx="21"
						cy="21"
						r="15.91549430918954"
						fill="#fff"
					></circle>
					<circle
						className="donut-ring"
						cx="21"
						cy="21"
						r="15.91549430918954"
						fill="transparent"
						stroke={PrimaryLight}
						strokeWidth="4"
					></circle>

					<circle
						className="donut-segment"
						cx="21"
						cy="21"
						r="15.91549430918954"
						fill="transparent"
						stroke={PrimaryGray}
						strokeWidth="4"
						strokeDasharray={`${props?.rating} ${100 - props?.rating}`}
						strokeDashoffset="25"
					></circle>
					<g
						fill={SecondaryDark}
						// transform="translateY(2px,12px)"
						className="chart-text"
					>
						<text
							fontSize="11px"
							textAnchor="middle"
							// transform="translateY(2px,12px)"
							x="50%"
							y="58%"
							className="chart-number"
						>
							{props.rating}
						</text>
					</g>
				</svg>
			</Pane>
		</Card>
	);
}
