import { CrossIcon, Dialog, Heading, Pane, TextInputField } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import Button from "../lib/button/Button";
import queryString from "query-string";
// import Url from "url-parse";
import { PrimaryLight, ResetPasswordHandler } from "../utils/Consts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FullStory from "../utils/fullstory";

export default function Reset(props) {
	const [isShown, setIsShown] = useState(false);
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");
	const history = useHistory();

	useEffect(
		() => {
			urlParamHandler();
		},
		// eslint-disable-next-line
		[]
	);

	const urlParamHandler = async () => {
		try {
			const search = props?.location?.search || "";
			const params = queryString.parse(search) || {};
			const { mode } = params;
			if (mode !== "resetPassword") return;
			setIsShown(true);
		} catch (error) {
			FullStory.log("error", error);
			return props?.context?.toaster?.danger(
				`There was an error with resetting your password. Please try again later.`
			);
		}
	};

	const reset = async () => {
		try {
			setLoading(true);
			const search = props?.location?.search || "";
			const params = queryString.parse(search) || {};
			const { mode } = params;
			if (mode !== "resetPassword") return;
			await ResetPasswordHandler({
				...params,
				newPass: password,
			});
			props.context.toaster.success(`Your password has been reset.`);
			// const url = new Url(continueUrl) || {};
			// const pathname = url?.pathname || "/";
			setLoading(false);
			setIsShown(false);
			history.push("/");
			return;
		} catch (error) {
			setLoading(false);
			return props?.context?.toaster?.danger(
				`There was an error with password reset. Please try again later.`
			);
		}
	};

	const clear = () => {
		setIsShown(false);
		setPassword("");
	};

	const inputHandler = (e) => setPassword(e.target.value);

	return (
		<Pane className="auth" display="flex">
			<Dialog
				className="reset-dialog"
				containerProps={{
					borderRadius: 0,
					backgroundColor: PrimaryLight,
				}}
				isShown={isShown}
				title={"RESET PASSWORD"}
				header={
					<Pane
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						width="100%"
					>
						<Pane></Pane>
						<Pane>
							<Heading size={500}>RESET PASSWORD</Heading>
						</Pane>
						<Pane>
							<CrossIcon cursor="pointer" onClick={() => setIsShown(false)} />
						</Pane>
					</Pane>
				}
				onCloseComplete={() => setIsShown(false)}
				isConfirmLoading={loading}
				isConfirmDisabled={loading}
				onCancel={clear}
				// hasFooter={true}
				footer={
					<Pane width="100%" display="flex" justifyContent="flex-end">
						<Pane marginLeft="5px" marginRight="5px">
							<Button
								// marginLeft={majorScale(1)}
								onClick={clear}
								isLoading={loading}
							>
								Cancel
							</Button>
						</Pane>
						<Pane marginLeft="5px" marginRight="5px">
							<Button
								onClick={reset}
								appearance="primary"
								isLoading={loading}
								primary={+true}
							>
								Reset
							</Button>
						</Pane>
					</Pane>
				}
				confirmLabel={"Reset"}
				onConfirm={reset}
			>
				<Pane is="form">
					<TextInputField
						type="password"
						inputHeight={51}
						label="Password"
						name="password"
						onChange={inputHandler}
						placeholder="Password..."
						value={password}
					/>
				</Pane>
			</Dialog>
		</Pane>
	);
}
