import React from "react";
import { Card as EGCard, CrossIcon } from "evergreen-ui";
import { CardProps, PaletteTeal, White } from "../../utils/Consts";

export default function DismissableCard(props) {
	return (
		<EGCard
			{...CardProps}
			flexDirection={props.flexDirection || "column"}
			position="relative"
			alignItems={props.small === true ? "flex-start" : "center"}
			paddingx={props.small === true ? "24px" : "72px"}
			paddingY={props.small === true ? "24px" : "60px"}
			minHeight={props.small === true ? "190px" : "326px"}
			backgroundColor={PaletteTeal}
			// width="100%"
			maxWidth="100%"
			display="flex"
			// flexDirection="row"
			flexWrap="wrap"
			margin="0"
			{...props.style}
		>
			<CrossIcon
				onClick={props.closeClickHandler}
				position="absolute"
				color={White}
				top="24px"
				right="24px"
				cursor="pointer"
			/>
			{props.children}
		</EGCard>
	);
}
