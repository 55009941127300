import React from "react";
import {
	Radar,
	RadarChart,
	PolarGrid,
	PolarAngleAxis,
	PolarRadiusAxis,
	// Legend,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { Pane } from "evergreen-ui";
import { Palette, Personalities } from "../utils/Consts";
import Context from "../utils/Context";

const RadarChartCard = () => {
	return (
		<Pane display="flex" width="100%" justifyContent="center">
			<Context.Consumer>
				{(context) => {
					if (!!context?.report?.results) {
						const data = context?.report?.results
							?.filter((item) => item.trait !== "Primary Personality")
							?.sort((a, b) => a.trait - b.trait)
							?.map((result) => {
								return {
									subject: Personalities[result?.trait]?.name,
									You: result?.score,
									fullMark: 100,
								};
							});

						return (
							<ResponsiveContainer width="90%" aspect={1}>
								<RadarChart
									isAnimationActive={false}
									cx="50%"
									cy="50%"
									outerRadius="75%"
									height={500}
									width="90%"
									data={data}
								>
									<PolarGrid
									// gridType="circle"
									/>
									<PolarAngleAxis dataKey="subject" />
									<PolarRadiusAxis angle={90} domain={[0, 100]} />
									<Radar
										name="You"
										dataKey="You"
										strokeWidth={1}
										stroke={Palette[0]}
										fill={Palette[1]}
										fillOpacity={0.5}
									/>
									{/* <Legend /> */}
									<Tooltip />
								</RadarChart>
							</ResponsiveContainer>
						);
					}
				}}
			</Context.Consumer>
		</Pane>
	);
};

export default RadarChartCard;
