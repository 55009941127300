import React, { Component } from "react";
import { Label, Pane, TextInput } from "evergreen-ui";
import firebase from "../utils/firebase";
import Button from "../lib/button/Button";
import { SecondaryDark } from "../utils/Consts";
import Context from "../utils/Context";
import PageTitleBar from "./PageTitleBar";
import FullStory from "../utils/fullstory";

export default class Video extends Component {
	state = { name: "", uid: "", loading: false };

	scheduleRoom = async () => {
		try {
			this.setState({ loading: true });
			const { uid, name } = this.state;
			if (!uid || uid.length === 0 || !name || name.length === 0) {
				return this.props.context.toaster.danger(
					"Missing information. Please ensure that you have entered the required information."
				);
			}
			const roomRef = firebase.functions().httpsCallable("scheduleVideoRoom");
			const roomData = await roomRef({
				uid,
				name,
			});
			const data = roomData?.data;
			const uniqueName = data?.uniqueName;
			if (!uniqueName) {
				return this.props.context.toaster.danger(
					"Error creating room. Please try again later"
				);
			}
			this.props.context.toaster.success("Room successfully created");
			this.setState({ loading: false });
			return this.props.history.push(`/admin/rooms`);
		} catch (error) {
			FullStory.log("error", error);
			this.setState({ loading: false });
			this.props.context.toaster.success(
				"There was an error creating the room. Please try again later."
			);
		}
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane>
						<PageTitleBar title="Schedule Video Room" />
						<Pane
							maxWidth="544px"
							display="flex"
							width="100%"
							flexDirection="column"
						>
							<Pane className="text-field-input-and-label">
								<Pane
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Label marginBottom="8px" color={SecondaryDark}>
										Room Name
									</Label>
								</Pane>
								<Pane
									className="field-input-row"
									display="flex"
									alignItems="center"
									marginBottom="20px"
									minHeight="45px"
									marginTop="19px"
								>
									<TextInput
										className="field-input"
										onChange={(e) => this.setState({ name: e.target.value })}
										value={this.state.room}
										height="100%"
										padding="11px"
										minWidth="70%"
										border="none"
										outline="transparent"
										boxShadow="none"
										fontSize="14px"
										placeholder={"Room Name..."}
									/>
								</Pane>
							</Pane>
							<Pane className="text-field-input-and-label">
								<Pane
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									alignItems="center"
								>
									<Label marginBottom="8px" color={SecondaryDark}>
										UID
									</Label>
								</Pane>
								<Pane
									className="field-input-row"
									display="flex"
									alignItems="center"
									marginBottom="20px"
									minHeight="45px"
									marginTop="19px"
								>
									{/* TODO: make this an array field for an array of UID */}
									<TextInput
										className="field-input"
										onChange={(e) => this.setState({ uid: e.target.value })}
										value={this.state.uid}
										height="100%"
										padding="11px"
										minWidth="70%"
										border="none"
										outline="transparent"
										boxShadow="none"
										fontSize="14px"
										placeholder={"UID..."}
									/>
								</Pane>
							</Pane>
							<Pane>
								<Button
									isLoading={this.state.loading}
									onClick={this.scheduleRoom}
									primary={+true}
								>
									Schedule Room
								</Button>
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
