import React, { Component } from "react";
import { Pane } from "evergreen-ui";
import PageTitleBar from "./PageTitleBar";
import Context from "../utils/Context";
import ButtonSwitch from "../lib/buttonSwitch/ButtonSwitch";
import Button from "../lib/button/Button";
import { UpdateDB } from "../utils/Consts";

const settings = [
	{
		name: "open_to_contact",
		title: "Open to being contacted?",
		details: "If selected, we may contact you about opportunities for you.",
	},
	{
		name: "open_to_learning",
		title: "Interested in learning, networking, mentor events?",
		details: "If selected, we may share learning opportunities with you.",
	},
];

export default class Settings extends Component {
	state = {
		open_to_contact:
			this?.props?.context?.user?.settings?.open_to_contact == null
				? null
				: this?.props?.context?.user?.settings?.open_to_contact,
		open_to_learning:
			this?.props?.context?.user?.settings?.open_to_learning == null
				? null
				: this?.props?.context?.user?.settings?.open_to_learning,
		loading: false,
	};

	clickHandler = (e) =>
		this.setState({ [`${e.target.name}`]: e.target.value === "true" });

	save = async () => {
		try {
			this.setState({ loading: true });
			const data = settings
				.map((item) => item.name)
				.reduce((accumulator, name) => {
					accumulator[name] = this.state[name];
					return { ...accumulator };
				}, {});
			await UpdateDB(`users/${this.props?.context?.auth?.uid}`, {
				settings: data,
			});
			this.setState({ loading: false });
			this.props.context.toaster.success(`Settings successfully updated.`);
		} catch (error) {
			this.setState({ loading: false });
			this.props.context.toaster.danger(
				`There was an error saving. Please try again later.`
			);
		}
	};

	cancel = () => {
		const fields = settings.map((item) => item.name);
		fields.forEach((field) =>
			this.setState({
				[`${field}`]:
					this.props?.context?.user?.settings?.[field] == null
						? null
						: this.props?.context?.user?.settings?.[field],
			})
		);
	};
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane>
						<PageTitleBar title="Settings" />
						<Pane>
							{settings.map((item, i) => (
								<ButtonSwitch
									key={`settings-field-${i}`}
									click={this.clickHandler}
									name={item.name}
									value={this.state[item.name]}
									title={item.title}
									details={item.details}
								/>
							))}

							<Pane display="flex">
								<Button onClick={this.cancel} marginRight="10px">
									Cancel
								</Button>
								<Button
									isLoading={this.state.loading}
									onClick={this.save}
									primary={+true}
									marginRight="10px"
								>
									Save Changes
								</Button>
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
