import React, { Component } from "react";
import Context from "../utils/Context";
import { Logout, PaneProps, PrimaryContrast, White } from "../utils/Consts";
import {
	Pane,
	majorScale,
	TabNavigation,
	SidebarTab,
	Badge,
	minorScale,
	Heading,
	Tooltip,
	InfoSignIcon,
	Paragraph,
	KeyIcon,
} from "evergreen-ui";
import { NavLink, withRouter } from "react-router-dom";
import Logo from "./Logo";
import Divider from "../lib/divider/Divider";

class Sidebar extends Component {
	logout = async () => {
		try {
			await Logout();
			this.props.history.push("/");
		} catch (error) {
			this.props.context.update("authLoading", false);
		}
	};
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane
						{...PaneProps}
						height={"100%"}
						background={PrimaryContrast}
						color={White}
						// marginRight={majorScale(2)}
						paddingRight={majorScale(2)}
						minWidth={majorScale(10)}
						borderTopLeftRadius={0}
						borderBottomLeftRadius={0}
						lineHeight={majorScale(2)}
						zIndex="1"
					>
						<Pane
							// flex={1}
							marginBottom={majorScale(2)}
							alignItems="center"
							display="flex"
							flexDirection="column"
							height="54px"
							justifyContent="center"
							lineHeight={majorScale(2)}
						>
							<NavLink to="/">
								<Pane
									display="flex"
									justifyContent="center"
									alignItems="center"
								>
									<Logo width="35px" brand={"white"} />
								</Pane>
							</NavLink>
						</Pane>
						<Pane>
							<Pane width="100%">
								<Heading
									textAlign="center"
									marginBottom={majorScale(3)}
									marginTop={majorScale(3)}
									textTransform="uppercase"
									color="rgba(255,255,255,0.5)"
									fontWeight="700"
									fontFamily='"Jost",san-serif'
								>
									LeadersEd
								</Heading>
							</Pane>
						</Pane>
						<Pane
							// flex={1}
							alignItems="flex-start"
							display="flex"
							flexDirection="column"
							lineHeight={majorScale(2)}
						>
							<Pane width="100%">
								<TabNavigation>
									<NavLink
										hidden={false}
										exact
										activeClassName="nav-item__active"
										to="/assessment"
									>
										<SidebarTab color={White} size={300}>
											Personality DNA
										</SidebarTab>
									</NavLink>
								</TabNavigation>
								<TabNavigation>
									<NavLink
										hidden={false}
										exact
										activeClassName="nav-item__active"
										to="/events"
									>
										<SidebarTab color={White} size={300}>
											Events
										</SidebarTab>
									</NavLink>
								</TabNavigation>
								<TabNavigation>
									<NavLink
										hidden={false}
										exact
										activeClassName="nav-item__active"
										to="/personalities"
									>
										<SidebarTab color={White} size={300}>
											Personalities
										</SidebarTab>
									</NavLink>
								</TabNavigation>
							</Pane>
						</Pane>
						<Divider
							style={{
								marginTop: majorScale(3),
								marginBottom: majorScale(3),
								marginRight: majorScale(2),
							}}
						/>
						<Pane>
							<Pane>
								<TabNavigation>
									<NavLink
										hidden={false}
										exact
										activeClassName="nav-item__active"
										to="/premium"
										// marginBottom={majorScale(2)}
									>
										<SidebarTab color={White} size={300}>
											PREMIUM
											<Tooltip
												position="top-left"
												content={
													<Paragraph color={White} margin={10}>
														Premium
													</Paragraph>
												}
											>
												<InfoSignIcon marginLeft={majorScale(1)} size={12} />
											</Tooltip>
										</SidebarTab>
									</NavLink>
									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={false}
											exact
											activeClassName="nav-item__active"
											to="/premium/leadership"
											// marginBottom={majorScale(2)}
										>
											<SidebarTab color={White} size={300}>
												Leadership Report
												{context?.report?.level === 0 && (
													<KeyIcon marginLeft={majorScale(1)} size={12} />
												)}
											</SidebarTab>
										</NavLink>
									</Pane>

									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={false}
											exact
											activeClassName="nav-item__active"
											to="/premium/managerdirector"
											// marginBottom={majorScale(2)}
										>
											<SidebarTab color={White} size={300}>
												Manager/Director
												{context?.report?.level === 0 && (
													<KeyIcon marginLeft={majorScale(1)} size={12} />
												)}
											</SidebarTab>
										</NavLink>
									</Pane>
									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={false}
											exact
											activeClassName="nav-item__active"
											to="/premium/executivevp"
											// marginBottom={majorScale(2)}
										>
											<SidebarTab color={White} size={300}>
												Executive/VP
												{context?.report?.level === 0 && (
													<KeyIcon marginLeft={majorScale(1)} size={12} />
												)}
											</SidebarTab>
										</NavLink>
									</Pane>
									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={false}
											exact
											activeClassName="nav-item__active"
											to="/premium/csuite"
											// marginBottom={majorScale(2)}
										>
											<SidebarTab color={White} size={300}>
												C-Suite
												{context?.report?.level === 0 && (
													<KeyIcon marginLeft={majorScale(1)} size={12} />
												)}
											</SidebarTab>
										</NavLink>
									</Pane>
									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={false}
											exact
											activeClassName="nav-item__active"
											to="/premium/roles"
											// marginBottom={majorScale(2)}
										>
											<SidebarTab color={White} size={300}>
												Roles
											</SidebarTab>
										</NavLink>
									</Pane>
									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={false}
											exact
											activeClassName="nav-item__active"
											to="/premium/events"
											// marginBottom={majorScale(2)}
										>
											<SidebarTab color={White} size={300}>
												Premium Events
												{context?.report?.level === 0 && (
													<KeyIcon marginLeft={majorScale(1)} size={12} />
												)}
											</SidebarTab>
										</NavLink>
									</Pane>
									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={true}
											exact
											activeClassName="nav-item__active"
											to="/definitions"
											// marginBottom={majorScale(2)}
										>
											<SidebarTab color={White} size={300}>
												Definitions
											</SidebarTab>
										</NavLink>
									</Pane>
									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={true}
											exact
											activeClassName="nav-item__active"
											to="/search"
										>
											<SidebarTab color={White} size={300}>
												Search
											</SidebarTab>
										</NavLink>
									</Pane>
									<Pane marginBottom={majorScale(2)}>
										<NavLink
											hidden={true}
											exact
											activeClassName="nav-item__active"
											to="/networking"
										>
											<SidebarTab color={White} size={300}>
												Networking
											</SidebarTab>
										</NavLink>
										<Pane marginBottom={majorScale(2)}></Pane>
										<NavLink
											hidden={true}
											exact
											activeClassName="nav-item__active"
											to="/purchases"
										>
											<SidebarTab color={White} size={300}>
												Purchases
											</SidebarTab>
										</NavLink>
										<Pane marginBottom={majorScale(2)}></Pane>
										<NavLink
											exact
											hidden={true}
											activeClassName="nav-item__active"
											to="/results"
										>
											<SidebarTab size={300} color={White}>
												Results
											</SidebarTab>
										</NavLink>
									</Pane>
								</TabNavigation>
							</Pane>
						</Pane>
						<Divider
							style={{
								marginTop: majorScale(3),
								marginBottom: majorScale(3),
								marginRight: majorScale(2),
							}}
						/>
						<Pane>
							{context.user && (
								<NavLink hidden={true} to="/logout">
									<SidebarTab size={300} color={White}>
										LogoutLink
										{!!context?.customClaims?.admin && (
											<Badge margin={minorScale(2)} color="neutral">
												Admin
											</Badge>
										)}
									</SidebarTab>
								</NavLink>
							)}
						</Pane>
						{context.user && !!context?.customClaims?.admin && (
							<>
								<SidebarTab color={White} size={300}>
									ADMIN
									<Tooltip
										position="top-left"
										content={
											<Paragraph color={White} margin={10}>
												Admin
											</Paragraph>
										}
									>
										<InfoSignIcon marginLeft={majorScale(1)} size={12} />
									</Tooltip>
								</SidebarTab>
								<NavLink
									exact
									activeClassName="nav-item__active"
									to="/admin/createroom"
								>
									<SidebarTab size={300} color={White}>
										Create Room
									</SidebarTab>
								</NavLink>
								<NavLink
									exact
									activeClassName="nav-item__active"
									to="/admin/rooms"
								>
									<SidebarTab size={300} color={White}>
										Rooms
									</SidebarTab>
								</NavLink>
								<NavLink
									exact
									activeClassName="nav-item__active"
									to="/admin/upgradeuser"
								>
									<SidebarTab size={300} color={White}>
										Upgrade User
									</SidebarTab>
								</NavLink>
								<NavLink
									exact
									activeClassName="nav-item__active"
									to="/admin/users"
								>
									<SidebarTab size={300} color={White}>
										Users
									</SidebarTab>
								</NavLink>
								<NavLink
									exact
									activeClassName="nav-item__active"
									to="/admin/events"
								>
									<SidebarTab size={300} color={White}>
										Events
									</SidebarTab>
								</NavLink>
								<NavLink
									exact
									activeClassName="nav-item__active"
									to="/admin/addevent"
								>
									<SidebarTab size={300} color={White}>
										Add Event
									</SidebarTab>
								</NavLink>
								<Divider
									style={{
										marginTop: majorScale(3),
										marginBottom: majorScale(3),
										marginRight: majorScale(2),
									}}
								/>
							</>
						)}
						<Pane>
							{context.user && (
								<SidebarTab size={300} color={White} onSelect={this.logout}>
									Logout
								</SidebarTab>
							)}
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}

export default withRouter(Sidebar);
