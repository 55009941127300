import React, { Component } from "react";
import Context from "../utils/Context";
import { Pane, Spinner } from "evergreen-ui";
import PersonalityDefinition from "./PersonalityDefinition";

import BeginAssessmentCard from "./BeginAssessmentCard";

export default class PersonalityDefinitionsContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane>
						{!!context?.reportLoading && (
							<Pane
								display="flex"
								width="100%"
								justifyContent="center"
								alignItems="center"
								height="100%"
							>
								<Spinner />
							</Pane>
						)}

						{!context?.report ? (
							<BeginAssessmentCard />
						) : (
							<PersonalityDefinition />
						)}
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
