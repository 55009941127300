import React, { Component } from "react";
import {
	Pane,
	Card,
	majorScale,
	minorScale,
	TextInputField,
} from "evergreen-ui";
import Button from "../lib/button/Button";
import firebase from "../utils/firebase";
import { CardProps } from "../utils/Consts";

export default class Forgot extends Component {
	state = { email: "" };

	inputHandler = (e) => this.setState({ [`${e.target.name}`]: e.target.value });

	reset = async () => {
		try {
			await firebase.auth().sendPasswordResetEmail(this.state.email, {
				url: `https://app.leadersed.com/?login=true`,
			});
			this.props.context.toaster.success(
				`A password reset email has now been sent to ${this.state.email}.`
			);
		} catch (error) {
			this.props.context.toaster.danger(error.message);
		}
	};

	render() {
		return (
			<Pane
				display="flex"
				justifyContent="center"
				alignItems="center"
				width="100%"
				minWidth="320px"
			>
				<Card
					{...CardProps}
					// background="tint2"
					padding={majorScale(2)}
					display="flex"
					borderRadius={minorScale(1)}
					flexDirection="column"
					width="100%"
					minWidth="320px"
					maxWidth="640px"
				>
					<Pane>
						<TextInputField
							label="Email"
							inputHeight={51}
							name="email"
							onChange={this.inputHandler}
							placeholder="Email..."
							value={this.state.email}
						/>
						<Button primary={+true} onClick={this.reset}>
							Reset
						</Button>
					</Pane>
				</Card>
			</Pane>
		);
	}
}
