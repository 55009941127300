import React from "react";
import whitenotebook from "../assets/whitenotebook.png";
import yourpersonalitydna from "../assets/yourpersonalitydna.png";
export default function WhiteNotebook(props) {
	// return <div style={{backgroundImage:src}}></div>
	return (
		<img
			alt="White Notebook"
			style={{ maxWidth: "100%" }}
			src={props?.book ? yourpersonalitydna : whitenotebook}
		/>
	);
}
