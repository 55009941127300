import { majorScale, Pane } from "evergreen-ui";
import React from "react";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Card from "../lib/card/Card";
import { PaneProps, Personalities } from "../utils/Consts";
import PageTitleBar from "./PageTitleBar";

export default function Personality() {
	return (
		<Pane {...PaneProps} display="flex" width="100%">
			<Breadcrumbs
				crumbs={[{ text: "Personalities", link: "/personalities" }]}
			/>
			<PageTitleBar title={`Personality Definitions`} />
			<Pane
				{...PaneProps}
				display="flex"
				width="100%"
				paddingLeft={0}
				flexWrap="wrap"
				flexDirection="row"
				justifyContent="space-between"
			>
				{Object.keys(Personalities)
					.map((trait) => Personalities[trait])
					.map((personality, i) => (
						<Pane key={`personality-card-${i}`} margin={majorScale(6)}>
							<Card
								small={true}
								title={personality?.name}
								button={`View ${personality?.name}`}
								link={`/personality/${personality?.name}`}
								body={`${personality?.definition?.substring(0, 80)}...`}
							/>
						</Pane>
					))}
			</Pane>
		</Pane>
	);
}
