import { Pane } from "evergreen-ui";
import React, { Component } from "react";
import Context from "../utils/Context";
import { PaneProps, UpdateDB } from "../utils/Consts";
import PageTitleBar from "./PageTitleBar";
import Breadcrumbs from "../lib/breadcrumbs/Breadcrumbs";
import Button from "../lib/button/Button";
import ButtonSwitch from "../lib/buttonSwitch/ButtonSwitch";

const interests = [
	{
		name: "personality_dna_webinars",
		title: "Personality DNA Webinars",
		details:
			"If selected, we will keep you informed about Personality DNA Webinars",
	},
	{
		name: "leadership_training",
		title: "Leadership Training",
		details:
			"If selected, we may share leadership training opportunities with you.",
	},
	{
		name: "management_training",
		title: "Management Training",
		details:
			"If selected, we may share management training opportunities with you.",
	},
	{
		name: "emotional_intelligence_training",
		title: "Emotional Intelligence Training",
		details:
			"If selected, we may share emotional intelligence training opportunities with you.",
	},
	{
		name: "interview_coaching",
		title: "Interview Coaching",
		details:
			"If selected, we may share interview coaching opportunities with you.",
	},
	{
		name: "industry_networking",
		title: "Industry Networking",
		details:
			"If selected, we may share industry networking opportunities with you.",
	},
	{
		name: "roundtable_discussions",
		title: "Roundtable Discussions",
		details:
			"If selected, we may share roundtable discussion opportunities with you.",
	},
	{
		name: "mentoring",
		title: "Mentoring",
		details: "If selected, we may share mentoring opportunities with you.",
	},
	{
		name: "being_mentored",
		title: "Being Mentored",
		details:
			"If selected, we may share opportunities to being mentored with you.",
	},
	{
		name: "community_networking",
		title: "Community Networking",
		details:
			"If selected, we may share community networking opportunities with you.",
	},
];

export default class ProfileProfessional extends Component {
	state = {
		personality_dna_webinars:
			this?.props?.context?.user?.interests?.personality_dna_webinars || false,
		leadership_training:
			this?.props?.context?.user?.interests?.leadership_training || false,
		management_training:
			this?.props?.context?.user?.interests?.management_training || false,
		emotional_intelligence_training:
			this?.props?.context?.user?.interests?.emotional_intelligence_training ||
			false,
		interview_coaching:
			this?.props?.context?.user?.interests?.interview_coaching || false,
		industry_networking:
			this?.props?.context?.user?.interests?.industry_networking || false,
		roundtable_discussions:
			this?.props?.context?.user?.interests?.roundtable_discussions || false,
		mentoring: this?.props?.context?.user?.interests?.mentoring || false,
		being_mentored:
			this?.props?.context?.user?.interests?.being_mentored || false,
		community_networking:
			this?.props?.context?.user?.interests?.community_networking || false,
	};

	clickHandler = (e) =>
		this.setState({ [`${e.target.name}`]: e.target.value === "true" });

	save = async () => {
		try {
			const data = interests
				.map((item) => item.name)
				.reduce((accumulator, name) => {
					accumulator[name] = this.state[name];
					return { ...accumulator };
				}, {});
			await UpdateDB(`users/${this.props?.context?.auth?.uid}`, {
				interests: data,
			});
			this.props.context.toaster.success(`Interests successfully updated.`);
		} catch (error) {
			this.props.context.toaster.danger(
				`There was an error saving. Please try again later.`
			);
		}
	};

	cancel = () => {
		const fields = interests.map((item) => item.name);
		fields.forEach((field) =>
			this.setState({
				[`${field}`]: this.props?.context?.user?.interests?.[field] || false,
			})
		);
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane width="100%" {...PaneProps} display="flex">
						<Breadcrumbs
							crumbs={[
								{ text: "Profile", link: "/profile" },
								{ text: "Interests", link: "/profile/interests" },
							]}
						/>
						<PageTitleBar title="Interests" />
						<Pane width="100%">
							{interests.map((item, i) => (
								<ButtonSwitch
									key={`interests-field-${i}`}
									click={this.clickHandler}
									name={item.name}
									value={this.state[item.name]}
									title={item.title}
									details={item.details}
								/>
							))}

							<Pane display="flex">
								<Button onClick={this.cancel} marginRight="10px">
									Cancel
								</Button>
								<Button onClick={this.save} primary={+true} marginRight="10px">
									Save Changes
								</Button>
							</Pane>
						</Pane>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
