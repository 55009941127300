import { majorScale, Pane, Text } from "evergreen-ui";
import React from "react";
import Card from "../lib/card/Card";
import { PaneProps } from "../utils/Consts";
import AuthContainer from "./AuthContainer";

export default function AuthToContinue() {
	return (
		<Pane
			{...PaneProps}
			justifyContent="center"
			alignItems="center"
			marginTop={majorScale(5)}
		>
			<Card>
				<Text textAlign="center" marginBottom={majorScale(3)}>
					To access this content, please login or register to continue.
				</Text>
				<AuthContainer />
			</Card>
		</Pane>
	);
}
