import { Pane } from "evergreen-ui";
import firebase from "../utils/firebase";
import React, { Component } from "react";
import Card from "../lib/card/Card";
import Context from "../utils/Context";
import queryString from "query-string";
import Url from "url-parse";
import { UpdateDB, VerifyEmailHandler } from "../utils/Consts";
import FieldInput from "../lib/fieldInput/FieldInput";
import FullStory from "../utils/fullstory";

export default class VerifyEmail extends Component {
	componentDidMount() {
		this.urlParamHandler();
	}
	state = { loading: false };
	verifyEmail = async () => {
		try {
			this.setState({ loading: true });
			await firebase.auth().currentUser.sendEmailVerification({
				url: `${window.location.href}`,
			});
			this.props.context.toaster.success(
				`A verification email has now been sent.`
			);
			this.setState({ loading: false });
		} catch (error) {
			FullStory.log("error", error);
			this.setState({ loading: false });
			this.props.context.toaster.danger(
				`There was an error sending the verification email. Please try again later.`
			);
		}
	};

	urlParamHandler = async () => {
		try {
			const search = this.props?.location?.search || "";
			const params = queryString.parse(search) || {};
			const { mode } = params;
			if (mode !== "verifyEmail") return;
			const continueUrl = await VerifyEmailHandler(params);
			this.props.context.toaster.success(
				`Your email address has now been verified.`
			);
			const uid = this.props?.context?.auth?.uid;
			await UpdateDB(`users/${uid}`, { emailVerified: true });
			const url = new Url(continueUrl) || {};
			const pathname = url?.pathname || "";
			firebase.auth().currentUser.reload();
			this.props.history.push(pathname);
			window.location.reload(false);
			return;
		} catch (error) {
			return this.props?.context?.toaster?.danger(
				`There was an error with verification. Please try again later.`
			);
		}
	};

	updateField = async (fieldName, value) => {
		try {
			this.setState({ [`${fieldName}Loading`]: true });
			await UpdateDB(`users/${this.props?.context?.auth?.uid}`, {
				[`${fieldName}`]: value,
			});
			this.setState({ [`${fieldName}Loading`]: false });
		} catch (error) {
			this.props.context.toaster.danger(
				`There was an error saving the ${fieldName}. Please try again later.`
			);
			this.setState({ [`${fieldName}Loading`]: false });
		}
	};

	render() {
		return (
			<Context.Consumer>
				{(context) => (
					<Pane>
						<Card
							title={
								!context?.user?.name
									? `1/2: What's your name? `
									: `2/2: Verify Your Email to Get Started`
							}
							body={
								!context?.user?.name
									? `Your Personality DNA is unique to you. Let's get started by saving your name. We'll then be able to create your cusutomized Personality DNA report.`
									: `Your Personality DNA is unique to you. At LeadersEd, we want to make sure that we're providing the most personalized results that we can. So, we require users to verify their email first. Please click the button below to get an email in your inbox. An email will be sent to ${context?.auth?.email}.`
							}
							button={context?.user?.name && "Verify Email"}
							primary={context?.user?.name && +true}
							buttonClick={context?.user?.name && this.verifyEmail}
							isLoading={this.state.loading}
						>
							{!context?.user?.name && (
								<Pane width="100%">
									<FieldInput
										loading={this.state.nameLoading}
										editMessage="Please enter your full name below."
										label="Name"
										value={context.user?.name}
										fieldName="name"
										submitHandler={this.updateField}
										placeholder={context.user?.name || "Full Name..."}
										edit={true}
										uneditable={true}
									/>
								</Pane>
							)}
						</Card>
					</Pane>
				)}
			</Context.Consumer>
		);
	}
}
