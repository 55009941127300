import React, { Component } from "react";
import Context from "../utils/Context";
import { withRouter } from "react-router-dom";
import Table from "./Table";
import { Personalities } from "../utils/Consts";

class TableContainer extends Component {
	render() {
		return (
			<Context.Consumer>
				{(context) => {
					const data = context?.report?.results
						?.filter((item) => item?.trait !== "Primary Personality")
						.sort((a, b) => b?.score - a?.score)
						.map((item) => {
							return {
								// Trait: item?.trait,
								"Personality Type": `${Personalities[item?.trait]?.name}`,
								Percentile: item?.score,
								// Definition: Personalities[item?.trait]?.definition,
								"Read More": `Read more in Chapter ${
									Personalities[item?.trait]?.chapter
								}`,
							};
						});
					return <Table context={context} {...this.props} data={data} />;
				}}
			</Context.Consumer>
		);
	}
}
export default withRouter(TableContainer);
