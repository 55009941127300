import React, { Component } from "react";
import { Pane, majorScale } from "evergreen-ui";
import Logo from "./Logo";
import Wordmark from "./Wordmark";

export default class Brandmark extends Component {
	render() {
		return (
			<Pane display="flex" justifyContent="center">
				<Pane marginRight={majorScale(1)}>
					<Logo width="40px" alt={this.props.alt} />
				</Pane>
				<Wordmark brand={this.props.brand} width={this.props.width || "70px"} />
			</Pane>
		);
	}
}
